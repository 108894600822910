import * as React from "react";

const HyperscaleSVG = (props) => (

	<svg width={38} height={37} viewBox="0 0 320 320" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M174.402 14.1257C166.626 6.31686 153.999 6.26975 146.164 14.0204L67.4038 91.9411C137.092 63.7758 237.74 153.909 278.124 202.27L305.907 174.449C313.697 166.648 313.706 154.016 305.927 146.204L174.402 14.1257Z" fill="url(#paint0_linear_1099_5180)"/>
		<path d="M195.032 186.187C168.984 211.191 135.336 228.086 99.2023 232.768C94.0071 233.441 79.4201 233.05 73.0022 232.768L145.552 305.795C153.343 313.637 166.019 313.672 173.853 305.871L305.808 174.468C313.643 166.667 313.689 154.016 305.876 146.192C282.382 122.666 237.827 78.0488 237.817 78.0422C245.874 98.6864 234.014 132.507 225.012 148.057C216.848 162.148 206.703 174.984 195.032 186.187Z" fill="url(#paint1_linear_1099_5180)"/>
		<path d="M145.538 305.834C153.339 313.657 165.999 313.678 173.82 305.875C192.12 287.616 221.669 258.132 221.693 258.108C219.221 260.583 202.287 256.569 198.655 255.915C190.504 254.439 182.483 252.228 174.748 249.254C159.74 243.482 145.726 234.817 134.49 223.237C85.4258 172.683 110.511 87.2137 136.962 31.7485C139.685 26.0347 142.609 17.0962 147.124 12.5575L14.1025 145.765C6.30914 153.57 6.30471 166.21 14.0926 174.02L145.538 305.834Z" fill="url(#paint2_linear_1099_5180)"/>
		<defs>
			<linearGradient id="paint0_linear_1099_5180" x1="67.4038" y1="101.135" x2="313.835" y2="127.02" gradientUnits="userSpaceOnUse">
				<stop offset="0.244792" stopColor="#1DA89C"/>
				<stop offset="0.864583" stopColor="#041929"/>
			</linearGradient>
			<linearGradient id="paint1_linear_1099_5180" x1="341.728" y1="40.056" x2="81.5833" y2="245.767" gradientUnits="userSpaceOnUse">
				<stop offset="0.06" stopColor="#03FFB3"/>
				<stop offset="0.63" stopColor="#0A7CCE"/>
				<stop offset="0.95" stopColor="#402F7E"/>
			</linearGradient>
			<linearGradient id="paint2_linear_1099_5180" x1="231.546" y1="261.92" x2="75.3395" y2="-50.0565" gradientUnits="userSpaceOnUse">
				<stop offset="0.06" stopColor="#03FFB3"/>
				<stop offset="0.63" stopColor="#0A7CCE"/>
				<stop offset="0.95" stopColor="#402F7E"/>
			</linearGradient>
		</defs>
	</svg>

);

export default HyperscaleSVG;
