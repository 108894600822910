import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getMessageFromError from "../../../../../utils/API-calls";
import styles from './css/noActiveUsers.module.css';
import { useUserInfo } from '../../../../../auth/UserInfoProvider';
import { useMainContainerStore, useUsersStore } from '../../../../../state-management';
import { Button, EmptyState } from '@ip-synamedia/common-ui-library';
import shallow from 'zustand/shallow';
import Loader from '../../../../../components/Loader';

export default function NoActiveUsers() {
	const { accountId,scope } = useUserInfo();
	const [{
		isError: inviteesIsError,
		isLoading: inviteesIsLoading,
	}] = useMainContainerStore(state => [state.inviteesPromise], shallow);
	const [{
		isError: usersIsError,
		isLoading: usersIsLoading,
		error: usersError
	}] =  useMainContainerStore(state => [state.usersPromise], shallow);
	const [isUsersSuccess,isInviteesSuccess] = [!usersIsError && !usersIsLoading, !inviteesIsLoading && !inviteesIsError];

	const [setIsUserInviteActive] = useUsersStore(state => [state.setIsUserInviteActive]);

	const hasInvitePermission = scope?.includes('account:user:create') || scope?.includes('account-admin:user:create');
	const NO_ACTIVE_USERS_MESSAGE = "You don't have any active users yet";

	return (
		<div className={styles.rootContainer} id={'NoActiveUsers_root'}>
			<EmptyState
				data-testid="emptyState_container"
				id="ActiveUsers_EmptyState"
				showImage
				image={<div className={styles.svgImage} id={'NoActiveUsers_icon'}><FontAwesomeIcon className={styles.icon}
					icon='users'
					color={'#2966CC'}/>
				</div>}
			>
				<div className={styles.col}>
					<div className={styles.colContent}>
						{!usersIsError ? <div className={styles.typography} id={'NoActiveUsers_content'}>
							{NO_ACTIVE_USERS_MESSAGE}
						</div>
							:
							<div className={styles.errorMessage} id={'NoActiveUsers_content_error'}>
								{getMessageFromError(usersError)}
							</div>}
					</div>
					{!usersIsError && (isUsersSuccess || isInviteesSuccess ) ? <div className={styles.colContent}>
						<Button
							disabled={!accountId || !hasInvitePermission}
							id={'accountUsers_Header_inviteBtn'}
							onClick={hasInvitePermission && (() => setIsUserInviteActive(true))}
						>
								Invite User
						</Button>
					</div>
						: 
						<div className={styles.colContent}><Loader type="Rings"/></div>}
				</div>
			</EmptyState>
		</div>
	);
}
