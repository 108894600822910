import React, { useEffect, useMemo, useState } from 'react';
import styles from './HomePage.module.css';
import { useUserInfo } from "../../auth/UserInfoProvider";
import { useMainContainerStore } from "../../state-management";
import shallow from 'zustand/shallow';
import BookSvg from '../../assets/icons/BookSvg';
import RecentlyUseSvg from '../../assets/icons/RecentlyUseSvg';
import RecentBlogsSVG from '../../assets/icons/RecentBlogsSVG';
import UsersGraphicsSVG from "../../assets/icons/graphics/UsersGraphicsSVG";
import ProductsGraphicsSVG from "../../assets/icons/graphics/ProductsGraphicsSVG";
import Header from './Header';
import { BottomCards, MiddleCards, TopCards } from "./components";
import MainContainer from "../../components/MainContainer";
import cookiesNames from "../../config/cookies";
import { useCookies } from "react-cookie";
import Footer from "../WelcomePage/Footer";

function HomePage() {
	const { accountAlias, orgId, appRedirectUrl } = useUserInfo();
	const accountPreferences = cookiesNames.preferences + "." + orgId;
	const [cookies] = useCookies([accountPreferences]);
	const [selectedSpaceId, setSelectedSpaceId] = useState(cookies?.[accountPreferences]?.activeSpace);
	const [recentlyUsedProducts, setRecentlyUsedProducts] = useState([]);
	const { scope } = useUserInfo();

	// call the projects api and get the spaces based on accountId

	const [{
		data: spaceProducts = [],
		isError: spaceProductsIsError,
		isLoading: spaceProductsIsLoading,
		error: spaceProductsError
	}] = useMainContainerStore(state => [state.tenantsPromise], shallow);

	const [{
		data: productsList = [],
		isError: productsListIsError,
		isLoading: productsListIsLoading,
		error: productsListError
	}] = useMainContainerStore(state => [state.productsPromise], shallow);

	const [{
		data: users = [],
		isError: usersIsError,
		isLoading: usersIsLoading,
		error: usersError
	}] = useMainContainerStore(state => [state.usersPromise], shallow);

	const [{
		data: invitedUsers = [],
		isError: invitedUsersIsError,
		isLoading: invitedUsersIsLoading,
		error: invitedUsersError
	}] = useMainContainerStore(state => [state.inviteesPromise], shallow);

	useEffect(() => {
		const _selectedSpace = cookies?.[accountPreferences]?.activeSpace;

		if (_selectedSpace?.id && _selectedSpace.id !== selectedSpaceId) {
			setSelectedSpaceId(_selectedSpace.id);
			setRecentlyUsedProducts([]);
		}
	}, [cookies, accountPreferences, selectedSpaceId]);

	useEffect(() => {
		spaceProducts.length && !spaceProductsIsLoading && !spaceProductsIsError && setRecentlyUsedProducts(spaceProducts.map(tenant => {
			const productObj = {
				productId: tenant.productId,
				icon: true,
				customer: false,
				desc: '',
				tenantId: tenant.tenantId
			};

			if (productsList.length && !productsListIsLoading && !productsListIsError && !productsListError) {
				productsList.forEach(product => {
					if (product.id === tenant.productId) {
						productObj["displayName"] = product.displayName;
					}
				});
			}

			return productObj;
		}));
	}, [spaceProducts, spaceProductsIsLoading, spaceProductsIsError, productsList, productsListIsLoading, productsListIsError, productsListError]);

	let topCards = useMemo(() => {
		return [{
			title: users.length,
			subTitle: "Users",
			isLoading: usersIsLoading || invitedUsersIsLoading,
			isError: usersIsError || invitedUsersIsError,
			error: usersError || invitedUsersError,
			desc: [{ type: "list", value: { one: `${users.length} Active`, two: `${invitedUsers.length} Invited` } }],
			link: `${accountAlias}/account`,
			id: "manage-users",
			graphics: <UsersGraphicsSVG/>
		},
		{
			title: spaceProducts?.length,
			subTitle: "Products Installed",
			isLoading: spaceProductsIsLoading,
			isError: spaceProductsIsError,
			error: spaceProductsError,
			desc: [{
				type: "list",
				value: {
					one: `${spaceProducts && spaceProducts.filter(tenant => tenant.subscriptionStatus === 'active').length} Active`,
					two: `${spaceProducts && spaceProducts.filter(tenant => tenant.subscriptionStatus === 'trial').length} In Trial`
				}
			}],
			link: "/products",
			id: "manage-products",
			graphics: <ProductsGraphicsSVG/>
		}, {
			title: 'Recent Blogs',
			id: 'recent-blogs',
			icon: <RecentBlogsSVG/>,
			desc: [{
				type: "list",
				value: {
					one: {
						label: "OTT Service Theft: Why DRM Isn’t Enough to Protect Your Streaming Service",
						url: "https://www.synamedia.com/ott-service-theft-why-drm-isnt-enough-to-protect-your-streaming-service/"
					},
					two: {
						label: "Increasing video encoding performance while improving sustainability; can it really be done?",
						url: "https://www.synamedia.com/content-aggregation-when-less-is-more/"
					}
				}
			}]
		}];
	}, [users, usersIsLoading, usersIsError, usersError, invitedUsers, invitedUsersIsLoading, invitedUsersIsError, invitedUsersError, spaceProducts, spaceProductsIsLoading, spaceProductsIsError, spaceProductsError, accountAlias]);

	if (!scope?.includes("account:user:list")) {
		topCards = topCards.filter(x => x.id !== 'manage-users');
	}

	const middleCards = [
		{
			title: "Recently Used",
			icon: <RecentlyUseSvg/>,
			data: recentlyUsedProducts ? recentlyUsedProducts.slice(0, 3) : [],
			error: spaceProductsError,
			isLoading: spaceProductsIsLoading,
			isError: false,
			id: "RecentlyUsed"
		},
		{
			title: "Discover & Learn",
			icon: <BookSvg/>,
			data: [
				{
					icon: false,
					customer: false,
					text: "How can Inventory targeting through Iris maximise your revenue",
					link: "https://developer.synamedia.com/iris/docs/inventory-targeting"
				},
				{
					icon: false,
					customer: false,
					text: "Get your clients Insight-ready through Clarissa",
					link: "https://developer.synamedia.com/clarissa/docs/client-implementation-guide"
				},
				{
					icon: false,
					customer: false,
					text: "Learn how Everguard protects your content",
					link: "https://www.synamedia.com/product/everguard/"
				},
				{
					icon: false,
					customer: false,
					text: "Get OTT delivery with the same latency and quality as broadcast",
					link: "https://www.synamedia.com/deliver/ott-media-streaming/"
				}
			],
			id: "Discover&Learn"
		},
		{
			id: "CaseStudy"
		},
	];

	document.title = 'Synamedia Cloud Portal | Home';

	// Navigate out to the application redirect url
	if (appRedirectUrl) {
		window.location.assign(appRedirectUrl);
		// Return empty div

		return (<div></div>);
	}

	return (
		<MainContainer>
			<div className={styles.homeContainer}>
				<Header isHomePage={true} title="Home"/>
				<div className={styles.contentContainer} id={'HomePage_content_container'}>
					<div className={styles.contentCenter} id={'HomePage_rows_container_center'}>
						<div className={styles.contentStart} id={'HomePage_rows_container_start'}>
							<TopCards topCards={topCards}/>
							<MiddleCards middleCards={middleCards} recentlyUsedProducts={recentlyUsedProducts}/>
							<BottomCards/>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</MainContainer>
	);
}

export default HomePage;
