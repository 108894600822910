import { toast } from "react-toastify";

const toastOptions = {
	position: "top-center",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: false,
	toastId: ""
};

export const secretCopyCb = (isCopied, result) => {
	if(isCopied && result) 
		toast.info("Secret copied to the clipboard", { ...toastOptions, toastId: "secret_copy_success" });
	else 
		toast.warning(`Unable to copy secret: ${result}`, { ...toastOptions, toastId: "secret_copy_failed" });
};