import ReactLoader from "react-loader-spinner";
import styles from "./Loader.module.css";

export default function Loader(props) {
	const { type = "ThreeDots", width = 100, height = 100, color = '#004ecc' } = props;

	return (
		<div className={styles.container}>
			<div className={styles.loader} style={{ marginLeft: -width / 2, marginTop: -height / 2 }}>
				<ReactLoader
					type={type}
					color={color}
					height={height}
					width={width}
				/>
			</div>
		</div>
	);
}
