import { postRecoverAccount } from "../../external-apis";
import axios from "axios";

export default async function RecoverAccountCall({ email, token }) {
	const  { url, method, headers, body } =  postRecoverAccount({ email, token });

	await axios({
		method,
		url,
		data: body,
		headers,
		validateStatus: false
	});
}