import React, { useState, useEffect } from 'react';
import styles from "./EditUser.module.css";
import shallow from 'zustand/shallow';
import { useUsersInvitationsStore, useUsersStore } from '../../../../../state-management';
import Header from '../InviteUsers/Header';
import {
	getAccountRoles,
	getAllProductRoles,
	getProjectsByAccountId,
	getUserRolesByAccount,
	getUserRolesByProject
} from "../../../../../external-apis";

import {
	useQueriesWithAuthorization,
	useQueryWithAuthorization
} from "../../../../../custom-hooks";
import Loader from "../../../../../components/Loader";
import EditUserContainer from './EditUserContainer';
import { useUserInfo } from '../../../../../auth/UserInfoProvider';
import { useQueryClient } from 'react-query';
import { mapProjectsToUiElement, mapAccountRoles } from "../useUsers";
import getMessageFromError from "../../../../../utils/API-calls";

const breadCrumbItems= [{
	id: "users",
	label: "Users"
},{
	id: "editUser",
	label: "Edit Users"
}];

const checkLoadError = (queries) => {

	if (!queries.every(key => !key.isLoading)) return<Loader width={30} height={30} type={'Rings'}/>;

	if (queries.length>0 && queries.every(key => key.isError)) {
		const error = queries.map(key => key.error).join(',');

		return <div>Error fetching data: {error}</div>;
	}

	return false;
};

const getQueries = ( accountId, projects, userId, staleTime ) => {
	return projects.map((project) => {
		return {
			queryKey: ['get-user-roles-by-project', project.id, userId],
			query: getUserRolesByProject(accountId, project.id, userId),
			staleTime: staleTime,
			enabled: userId !== undefined && accountId !== undefined
		};
	});
};

const EditUser = ({ setIsUserEditActive }) => {
	useEffect(() => {
		return () => {
			returnToAccountManagement();
		};
		// eslint-disable-next-line
	}, []);
	const staleTime = 120000;
	const currentUserData  = useUsersStore(state => state.currentUserData, shallow);
	const { accountId } = useUserInfo();
	const queryClient = useQueryClient();
	const resetState = useUsersInvitationsStore(state => state.reset, shallow);
	const [firstRender, setFirstRender] = useState(true);
	const [selectedCrumb,setSelectedCrumb] = useState(breadCrumbItems[1]);
	const handleBreadCrumbClick = (event) => {
		if(event.target.id === "users") {
			setSelectedCrumb(breadCrumbItems[0]);
			returnToAccountManagement(); 
		}
	};

	const returnToAccountManagement = () => {
		resetState();
		queryClient.invalidateQueries(['users-active', accountId]);
		setIsUserEditActive(false);
	};

	const {
		data:projects = [],
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null && currentUserData !== undefined,
		staleTime: staleTime
	});

	const {
		data:userAccountRoles = [],
		isError: userAccountRolesIsError,
		isLoading: userAccountRolesIsLoading,
		error: userAccountRolesError
	} = useQueryWithAuthorization(['userRoles', accountId, currentUserData?.id], getUserRolesByAccount(accountId, currentUserData?.id), {
		select: mapAccountRoles,
		enabled: currentUserData?.id && accountId !== undefined,
		staleTime: staleTime
	});

	const {
		isLoading: isAccountRolesLoading,
		isError: isAccountRolesError,
		error: accountRolesError,
		data: accountRoles = [],
	} = useQueryWithAuthorization("roles-account", getAccountRoles("account"), {
		select: mapAccountRoles,
		staleTime: staleTime,
	});

	const {
		isLoading: productsIsLoading,
		isError: productsIsError,
		error: productsError,
		data: allRoles = []
	} = useQueryWithAuthorization('all-roles', getAllProductRoles(), {
		staleTime
	});

	const queries = getQueries(accountId, Object.values(projects), currentUserData?.id, staleTime);
	const rolesQueries = useQueriesWithAuthorization(queries);

	if(userAccountRolesIsLoading || projectsIsLoading || isAccountRolesLoading || productsIsLoading) return <Loader width={60} height={60}/>;
	if(checkLoadError(rolesQueries)) return <div id={'editUser_projectsQueries_error'}>{checkLoadError(rolesQueries)}</div>;
	if(projectsIsError) return <div id={'editUser_projects_error'}>{getMessageFromError(projectError)}</div>;
	if(productsIsError) return <div id={'editUser_products_error'}>{getMessageFromError(productsError)}</div>;
	if(userAccountRolesIsError) return <div id={'editUser_userAccountRoles_error'}>{getMessageFromError(userAccountRolesError)}</div>;
	if(isAccountRolesError) return <div id={'editUser_accountRoles_error'}>{getMessageFromError(accountRolesError)}</div>;

	return (
		<div className={styles.editUserRoot}>
			<Header
				selectedCrumb={selectedCrumb}
				setSelectedCrumb={setSelectedCrumb}
				breadCrumbItems={breadCrumbItems}
				handleBreadCrumbClick={handleBreadCrumbClick}
				title="Edit User"
			/>
			<EditUserContainer
				currentUserData={currentUserData}
				projects={projects}
				allRoles={allRoles}
				accountRoles={accountRoles}
				userAccountRoles={userAccountRoles}
				rolesQueries={rolesQueries}
				firstRender={firstRender}
				setFirstRender={setFirstRender}
				returnToAccountManagement={returnToAccountManagement}
			/>
		</div>
	);
};

export default EditUser;