import { Button } from "@ip-synamedia/common-ui-library";
import { makeStyles } from '@material-ui/core/styles';
// import SelectAccount from "../../SelectAccount";
import UsersTabs from "../UsersTabs";
import shallow from "zustand/shallow";
import { useUserInfo } from "../../../../../auth/UserInfoProvider";
import Loader from "../../../../../components/Loader";
import { useMainContainerStore } from "../../../../../state-management";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: "flex",
		width: '100%',
		height: '100%',
		background: '#FFFFFF',
		boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
	},
	secondaryContainer: {
		position: 'relative',
		width: '100%',
		height: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 20,
		paddingBottom: 0
	},
	titleContainer: {
		display: 'flex',
		height: 30,
		justifyContent: 'center',
		alignItems: 'center',
		width: 'fit-content'
	},
	mainTitleContainer: {
		display: 'flex',
		width: '100%',
		height: 70,
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row-reverse',
		minWidth: '30%'
	},
	title: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: "18px",
		color: '#11161D',
	},
	synopsis: {
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: 12,
		color: '#11161D',
	},
	separator: {
		border: '1px solid #B8C3D6',
		margin: '0px 12px',
		height: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectAccountDropdown: {
		width: "200px",
		marginLeft: 30,
		marginTop: -10
	},
}));

export default function Header({ hasInvitePermission, setIsUserInviteActive }) {
	const styles = useStyles();

	const { accountId } = useUserInfo();

	const [{
		isError: usersIsError,
		isLoading: usersIsLoading,
	}] =  useMainContainerStore(state => [state.usersPromise], shallow);

	const [{
		isError: inviteesIsError,
		isLoading: inviteesIsLoading,
	}] =  useMainContainerStore(state => [state.inviteesPromise], shallow);

	const [isUsersSuccess, isInviteesSuccess] = [!usersIsLoading && !usersIsError, !inviteesIsLoading && !inviteesIsError];

	const handleInviteUserClick = () => {
		setIsUserInviteActive(true);
	};

	return (
		<div className={styles.rootContainer}>
			<div className={styles.secondaryContainer}>
				<div className={styles.mainTitleContainer}>
					<div className={styles.titleContainer}>
						<div id={'accountUsers_Header_title'} className={styles.title}>Your Users</div>
						<div className={styles.separator}/>
						<div className={styles.synopsis}>Invite and manage users permissions</div>
					</div>
					{/* <div className={styles.selectAccountDropdown}>
								<SelectAccount val={accountId} onChange={handleAccountChange} isValid={accountId != null}/>
							</div> */}
					<div style={{ flex: "1 1 0%" }}/>
					{
						isUsersSuccess || isInviteesSuccess ? 
							<div className={styles.buttonsContainer}>
								<Button
									disabled={!accountId || !hasInvitePermission}
									data-cy={(!accountId || !hasInvitePermission) ? "disabled" : "enabled"}
									id={'accountUsers_Header_inviteBtn'}
									data-testid="accountUsers_Header_inviteBtn"
									onClick={hasInvitePermission && handleInviteUserClick}
								>
								Invite User
								</Button>
							</div>
							: 
							<div className={styles.buttonsContainer}>
								<Loader width={30} height={30} type="Rings"/>
							</div>
					}
				</div>
				<div>
					<UsersTabs accountId={accountId}/>
				</div>
			</div>
		</div>
	);
}
