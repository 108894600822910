import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "react-query";
import { fetchFn } from "../../../../../../../external-apis";

const useEditPostCall = (handleSuccessPostApiCall,handleFailedPostApiCall) => {
	const { getAccessTokenSilently } = useAuth0();

	return useMutation(async apiObj => {
		let accessToken;

		try {
			accessToken = await getAccessTokenSilently();
		} catch (e) {
			console.error('An error occurred in useMutationWithAuthorization', e);

			return;
		}

		apiObj.headers = apiObj.headers ?? {};
		apiObj.headers.Authorization = `Bearer ${accessToken}`;

		return fetchFn(apiObj)({ retry: (count, { message }) => {
			try {

				const { status } = JSON.parse(message);

				return status < 500 ? false : count < 4;
			}catch (err) {
				return false;
			}
		} });
	}, {
		onSettled: async(data,error,variables) => {

			if(error) {
				if(typeof handleFailedPostApiCall === 'function')
					handleFailedPostApiCall(error, variables);
			} else {
				if(typeof handleSuccessPostApiCall === 'function')
					handleSuccessPostApiCall(data, variables);
			}
		}
	});
};

export default useEditPostCall;
