import React, { useState } from 'react';
import SynamediaAuthContainer from "../../components/SynamediaAuthContainer";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { Navigate } from "react-router-dom";
import { isValidEmail } from "../../utils/validation";
import useStateWithValidation from "../../custom-hooks/useStateWithValidation";
import styles from './forgotAccount.module.css';
import StepTwo from "./messageSent";

const RecoverAccountPage = () => {
	const [email, setEmail, isValidEmailAddress] = useStateWithValidation('', isValidEmail);
	const [step, setStep] = useState(1);
	const [backToSignIn, setBackToSignIn] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const handleSendEmail = async () => {
		setStep(2);
	};

	const backToStep1 = () => {
		setEmail('');
		setFirstRender(true);
		setStep(1);
	};

	const handleBackToSignIn = () => {
		setBackToSignIn(true);
	};

	const form = (
		<div className={styles.formContainer}>
			<div className={styles.userInputContainer}>
				<AppInput
					autoFocus
					value={email}
					id={'ForgotAccount_emailInput'}
					name={'email-input'}
					label={'Email address'}
					onChange={(data) => {firstRender && setFirstRender(false); setEmail(data);}}
					placeholder={'you@company.com'}
					error={!firstRender && !isValidEmailAddress}
					errorMessage={'Invalid email address'}
					overrideClasses={{ input: styles.input, label: styles.inputLabel }}
				/>
			</div>
			<div>
				<AppButton
					variant={'contained'}
					disabled={!isValidEmailAddress}
					type={'submit'}
					overrideClasses={{ button: styles.setupAccountButton }}
					id={'forgotAccount_btn_send'}
					onClick={handleSendEmail}
				>
					{'Send Email'}
				</AppButton>
			</div>
			<div className={styles.backToCol}>
				<div className={styles.backToRow}>
                    Back to <div id={'forgotAccount_btn_signIn'} className={styles.signIn} onClick={handleBackToSignIn} >Sign In</div>
				</div>
			</div>
		</div>
	);

	const content = <div id={'ForgotAccount_root'} className={styles.rootContainer}>
		<div className={styles.titleContainer}>
			<div className={styles.titleTopContainer}>
				<div className={styles.title} id={'ForgotAccount_title'}>Forgot your account?</div>
				<div className={styles.label} id={'ForgotAccount_label'}>A link to your account can be found<br/> in your welcome email.</div>
			</div>
			<div className={styles.content} id={'ForgotAccount_content'}>If you no longer have access to it, you can request your account details by submitting your email address.</div>
		</div>
		{form}
	</div>;

	document.title = 'Synamedia Cloud Portal | Recover Account';

	return (backToSignIn ? <Navigate to={`/loginhome`}/> :
		step === 1?<SynamediaAuthContainer
			content={content}
			desc={<div>Your single access point for all your Synamedia products.</div>}
		/>:<StepTwo email={email} backToStep1={backToStep1} />
	);
};

export default RecoverAccountPage;
