import { makeStyles } from "@material-ui/core/styles";
import { Dialog } from '@headlessui/react';
import clsx from "clsx";

const useVariants = makeStyles((theme) => {
	return {
		info: {
			borderColor: '#FFFFFF',
		},
		warn: {
			borderColor: '#FFC41C',
		},
		error: {
			borderColor: '#FF3333',
		},
		general: {
			borderColor: 'transparent',
		}
	};
});

const useStyles = makeStyles((theme) => {
	return {
		dialog: {
			position: 'fixed',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 10,
			overflowY: 'auto'
		},
		overlay: {
			position: 'fixed',
			background: 'rgba(41, 41, 41, 0.5)',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		title: {
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: 17,
			lineHeight: '21px',
			fontFamily: 'Source Sans Pro',
			color: '#292929',
			textTransform: 'capitalize',
			margin: 0,
		},
		variant: {
			left: 0,
			top: 0,
			right: 0,
			borderStyle: 'solid',
			borderWidth: 4
		},
		contentWrapper: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minHeight: '100vh',
		},
		centerModal: {
			display: 'inline-block',
			height: '100vh',
			verticalAlign: 'middle'
		},
		content: {
			boxShadow: '0px 0px 20px rgba(41, 41, 41, 0.4)',
			borderRadius: 8,
			position: 'relative',
			background: 'white',
			display: 'inline-block',
			width: 600,
			overflow: 'hidden',
			verticalAlign: 'middle'
		},
		headerWrapper: {
			position: 'relative',
			borderBottom: '0.5px solid #C2C2C2',
			minHeight: 45,
		},
		header: {
			minHeight: 'calc(100% - 8px)',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			gap: 12,
			padding: '9px 24px',
		},
		headerChildren: {
		},
		titleWrapper: {
			display: 'flex',
			alignItems: 'center',
		},
		body: {
			padding: 24,
			paddingTop: 16,
			display: 'flex',
			flexDirection: 'column'
		},
		btnClose: {
			width: 9,
			height: 9,
			marginLeft: 'auto',
			cursor: 'pointer',
			'&:hover': {
				transform: 'scale(1.2)',
			}
		}
	};
});

export default function AppDialog({
	variant = 'info',
	open,
	onClose,
	title,
	overrideClasses = {},
	overrideBtnClose,
	children,
	headerChildren,
	...props
}) {
	const styles = useStyles();
	const variants = useVariants();

	return (
		<Dialog as="div" className={clsx(styles.dialog, overrideClasses?.dialog)} open={open}
			onClose={() => onClose?.()} {...props}>

			<div className={clsx(styles.contentWrapper, overrideClasses?.contentWrapper)}>
				{/* Use the overlay to style a dim backdrop for your dialog */}
				<Dialog.Overlay className={clsx(styles.overlay, overrideClasses?.overlay)}/>

				{/* This element is to trick the browser into centering the modal contents. */}
				<span className={clsx(styles.centerModal, overrideClasses?.centerModal)} aria-hidden={true}>&#8203;</span>

				<div className={clsx(styles.content, overrideClasses?.content)}>
					<div className={clsx(styles.headerWrapper, overrideClasses?.headerWrapper)}>
						{ variant !== 'none' && <div className={clsx(styles.variant, variants[variant] ?? variants.general, overrideClasses?.variant)}/>}
						<div className={clsx(styles.header, overrideClasses?.header)}>
							<div className={clsx(styles.titleWrapper, overrideClasses?.titleWrapper)}>
								<Dialog.Title className={clsx(styles.title, overrideClasses?.title)}>{title}</Dialog.Title>
								<div className={clsx(styles.btnClose, overrideClasses?.btnClose)} onClick={() => onClose?.()}>{overrideBtnClose}</div>
							</div>
							{headerChildren && <div className={clsx(styles.headerChildren, overrideClasses?.headerChildren)}>{headerChildren}</div>}
						</div>
					</div>
					{/* Render children */}
					<div className={clsx(styles.body, overrideClasses?.body)}>
						{children}
					</div>
				</div>
			</div>
		</Dialog>
	);

}
