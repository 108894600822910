import React from "react";
import styles from "./HomePage.module.css";
import UsersCard from "./topCards/Users";
import RecentBlogs from "./topCards/RecentBlogs";
import ProductInstalled from "./topCards/ProductInstalled";
import RecentlyUsed from "./middleCards/RecentlyUsed";
import { CMSPanel } from "@ip-synamedia/common-ui-library";
import { getCMSEnvironmentType } from '../../config';

const CMSEnvironmentTypeMap = (env) => {

	const map = { prod: "master", staging: "staging", dev: "dev" };

	return map[env] || "dev";
};

export const TopCards = ({ topCards }) => {

	return <div className={styles.row} id={'HomePage_topCards_container'}>
		{
			Array.isArray(topCards) && topCards.length && topCards.map((elementOut, index) => {

				switch (elementOut.id) {
				case ('manage-users'):
					return <UsersCard  key={'topCard' + index} element={elementOut} indexOut={index}/>;
				case('recent-blogs'):
					return <RecentBlogs  key={'topCard' + index} element={elementOut} indexOut={index}/>;
				case('manage-products'):
					return <ProductInstalled  key={'topCard' + index} element={elementOut} indexOut={index}/>;
				default:
					return <div key={'error'}/>;
				}
			})}
	</div>;
};

export const  MiddleCards = ({ middleCards, recentlyUsedProducts }) => {

	const cmsPanelEnvType = CMSEnvironmentTypeMap(getCMSEnvironmentType());

	return <div className={styles.row} id={'HomePage_middleCards_container'}>
		{
			Array.isArray(middleCards) && middleCards.length && middleCards.map((element,index) => {
				switch (element.id) {
				case ('RecentlyUsed'):
					return <RecentlyUsed key={index + 'RecentlyUsed'} element={element} index={index} recentlyUsedProducts={recentlyUsedProducts}/>;
				case('Discover&Learn'):
					return (<div key={`discover-and-learn-${index}`} style={{ width: "436px", height: "360px", gap: "34px" }} data-testid={`HomePage_DiscoverAndLearn_${index}`}>
						<CMSPanel environment={cmsPanelEnvType} slug={"discover-and-learn"} border={true} lineSeparator={true} style={{ width: "436px", height: "360px", gap: "34px" }} overrideHeadingStyle={{
							fontSize: "18px",
							fontWeight: "600",
						}}/>
					</div>);
				case('CaseStudy'):
					return (
						<div key={`case-study-${index}`} style={{ width: "436px", height: "360px", gap: "34px" }} data-testid={`HomePage_CaseStudies_${index}`}>
							<CMSPanel environment={cmsPanelEnvType} slug={"case-studies"} border={true} lineSeparator={true} style={{ width: "436px", height: "360px", gap: "34px" }} overrideHeadingStyle={{
								fontSize: "18px",
								fontWeight: "600",
							}}/>
						</div>);
				default:
					return <div key={'error'}/>;
				}
			})
		}
	</div>;
};

export const  BottomCards = () => {

	const cmsPanelEnvType = CMSEnvironmentTypeMap(getCMSEnvironmentType());

	return (
		<div data-testid={'HomePage_Products_to_try'}>
			<CMSPanel environment={cmsPanelEnvType} slug={"products-to-try"} type={'cards'} border={false}/>
		</div>
	);
};