import React, { useState, useEffect } from 'react';
import styles from "./EditApplication.module.css";
import { useApplicationStore } from '../../../../../state-management';
import Header from '../../../../../components/PageHeader';
import { getProductsPermissions } from "../../../../../external-apis";

import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import Loader from "../../../../../components/Loader";
import getMessageFromError from '../../../../../utils/API-calls';
import EditApplicationContainer from './EditApplicationContainer';

const breadCrumbItems= [{
	id: "applications",
	label: "Applications"
},{
	id: "editApplications",
	label: "Edit"
}];

const EditApplication = ({ resetApplicationsStore, setIsApplicationEditActive }) => {
	useEffect(() => {
		return () => {
			returnToAccountManagement();
		};
		// eslint-disable-next-line
	}, []);

	const currentApplicationData = useApplicationStore(state => state.currentApplicationData);
	const staleTime = 120000;
	const [selectedCrumb,setSelectedCrumb] = useState(breadCrumbItems[1]);
	const handleBreadCrumbClick = (event) => {
		if(event.target.id === "applications") {
			setSelectedCrumb(breadCrumbItems[0]);
			returnToAccountManagement(); 
		}
	};

	const returnToAccountManagement = () => {
		resetApplicationsStore();
		setIsApplicationEditActive(false);
	};

	const {
		isLoading: permissionsIsLoading,
		isError: permissionsIsError,
		error: permissionsError,
		data: productsPermissions = []
	} = useQueryWithAuthorization('all-products-permissions', getProductsPermissions(), {
		staleTime
	});

	if(permissionsIsLoading) return <Loader width={60} height={60}/>;
	if(permissionsIsError) return <div id={'editApplication_permissions_error'}>{getMessageFromError(permissionsError)}</div>;

	return (
		<div className={styles.editApplicationRoot}>
			<Header
				showBreadCrumbs = {true}
				selectedCrumb={selectedCrumb}
				breadCrumbItems={breadCrumbItems}
				handleBreadCrumbClick={handleBreadCrumbClick}
				title={"Edit Application"}
				compId={"Applications"}
			/>
			<EditApplicationContainer
				currentApplicationData={currentApplicationData}
				productsPermissions={productsPermissions}
				returnToAccountManagement={returnToAccountManagement}
			/>
		</div>
	);
};

export default EditApplication;