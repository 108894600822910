import create from "zustand";

const initialState = Object.freeze({
	selectedSpace: {},
	isApplicationCreateActive: false,
	isApplicationEditActive: false,
	currentApplicationData: {},
});

const useApplicationStore = create((set) => ({
	...initialState,
	setIsApplicationCreateActive: (val) => {
		set(() => ({
			isApplicationCreateActive: val
		}));
	},
	setIsApplicationEditActive: (val) => {
		set(() => ({
			isApplicationEditActive: val
		}));
	},
	setCurrentApplicationData : (val) => {
		set(() => ({
			currentApplicationData: val
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useApplicationStore;
