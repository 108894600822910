import { useAppPanelStore } from "../../state-management";
import clsx from "clsx";
import shallow from "zustand/shallow";
import styles from "./MainPanel.module.css";

export default function MainPanel() {

	const [contentComponent, defaultComponent] = useAppPanelStore(state => [state.contentComponent, state.defaultComponent], shallow);

	return (

		<div className={clsx(styles.rootContainer)}>

			{contentComponent ?? defaultComponent}

		</div>

	);

}