import MatissePortalSidebar from "../portals/matisse-portal/MatissePortalSidebar";

const mainMenuItems = [
	{
		id: 'users',
		label: 'Users',
		sidebarComponent: <MatissePortalSidebar/>,
		routePath: 'users',
		currentPageIndicator: "Manage Account",
	},
	{
		id: 'applications',
		label: 'Applications',
		sidebarComponent: <MatissePortalSidebar/>,
		routePath: 'applications',
		currentPageIndicator: "Manage Account",
	},
	{
		id: 'audit',
		label: 'Audit Logs',
		sidebarComponent: <MatissePortalSidebar/>,
		routePath: 'audit',
		currentPageIndicator: "Manage Account",
	}
];

const existByRoutePath = (routePath) => {
	return mainMenuItems.some(item => item.routePath === routePath);
};

export {
	mainMenuItems,
	existByRoutePath
};
