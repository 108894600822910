const cookieNamePrefix = 'synamedia.matisse-portal';
const cookieNames = {
	accountOrgId: `${cookieNamePrefix}.account.org-id`,
	accountAlias: `${cookieNamePrefix}.account.alias`,
	preferences: `${cookieNamePrefix}.account.preferences`,
	accounts: `${cookieNamePrefix}.accounts`,
	userId: `${cookieNamePrefix}.userId`
};

export default cookieNames;
