import Joi from "joi";

const schema = Joi.object({
	dashboardsIntroductionVideoUrl: Joi.string()
		.trim()
		.uri(),
	contentInsightsVideoUrl: Joi.string()
		.trim()
		.uri(),
	appInsightsVideoUrl: Joi.string()
		.trim()
		.uri(),
	dataPrivacyPdfUrl: Joi.string()
		.trim()
		.uri()
});

const validate = (clarissaConfig) => schema.validate(clarissaConfig, { abortEarly: false, stripUnknown: true });

export {
	schema,
	validate
};
