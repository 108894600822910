import 'react-toastify/dist/ReactToastify.min.css';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Vicon from "../../../assets/icons/toast/Vicon.svg";
import useStateWithValidation from "../../../custom-hooks/useStateWithValidation";
import useMutationWithAuthorization from "../../../custom-hooks/external-api-hooks/useMutationWithAuthorization";
import { postContactUs } from "../../../external-apis";
import AppTextArea from "../../AppTextArea";
import AppInput from "../../AppInput";
import closeIcon from "../../../assets/icons/close/closeIcon.png";
import React from "react";
import clsx from "clsx";
import getMessageFromError from "../../../utils/API-calls";
import { PopUp } from "@ip-synamedia/common-ui-library";

const MaxSubjectLen = 100;
const MaxMessageLen = 500;

const useStyles = makeStyles((theme) => {
	return {
		subjectContainer: {
			height: '66px',
			width:'100%',
			marginBottom: 12
		},
		subjectInput: {
			height: '30px',
			borderColor: '#8F8F8F',
		},
		messageContainer: {
			height: '166px',
			width:'100%',
		},
		messageInput: {
			border: '1px solid #8F8F8F',
			lineHeight:'20px',
			flexDirection:'column',
		},
		toastContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '398px',
			height: '66px',
			backgroundColor:'#EFEFEF',
		},
		toastSuccessContainer: {
			backgroundColor: '#DEFBE6'
		},
		toastText: {
			fontFamily: 'Source Sans Pro',
			fontSize: '16px',
			fontWeight: 400,
			marginLeft: '11px',
			width: '299px',
			lineHeight: '130%',
			color: '#198038'

		},
		XButtonToast:{
			color: '#11161D',
			width:'9px',
			height: '9px',
			cursor: 'pointer',
			marginLeft: '12.5px',
			marginRight: '10px'
		},
		toastGreenDiv: {
			position: 'absolute',
			left:0,
			top:0,
			width: '6px',
			height: '66px',
			background:'#198038'
		},
		toastIcon: {
			marginLeft:9
		},
		title: {
			color: '#212B3A',
			fontSize: 24,
			fontWeight: 600,
			fontFamily: 'Poppins',
		},
		header: {
			padding: '0px 0px 9px 0px !important',

		},
	};
});

export default function ContactUsDialog({ setOpen, open }) {
	const styles = useStyles();

	const [subject, setSubject, isValidSubject] = useStateWithValidation('', subject => (subject?.trim() !== '' && subject.length < MaxSubjectLen));
	const [message, setMessage, isValidMessage] = useStateWithValidation('', message => (message?.trim() !== '' && message.length < MaxMessageLen));

	const { mutateAsync } = useMutationWithAuthorization();

	const CloseButton = ({ closeButton }) => <img className={styles.XButtonToast} src={closeIcon} onClick={closeButton} alt={''}/>;

	const ToastSuccess  =
		<div>
			<div className={styles.toastText}>Your message has been received, we will be in touch shortly.</div>
			<div className={styles.toastGreenDiv}/>
		</div>;

	const handleClose = () => {
		setOpen(false);
		setSubject('');
		setMessage('');
	};

	const createReq = async () => {
		const params = {
			'subject': subject,
			'message': message
		};

		handleClose();

		const postEmailPromise = mutateAsync(postContactUs({ ...params }));

		toast.promise(postEmailPromise,
			{
				pending:{
					render({ data }) {
						return 'Sending...';
					},
					className: styles.toastContainer,
				},
				success:{
					render({ data }) {
						return ToastSuccess;
					},
					className: clsx(styles.toastContainer, styles.toastSuccessContainer),
					icon: <img className={styles.toastIcon} src={Vicon} alt={''}/>,
					closeButton: CloseButton,
				},
				error: {
					render({ data: error }) {
						return getMessageFromError(error);
					}
				},
			},{ position: toast.POSITION.TOP_CENTER, autoClose: false }

		);

	};

	return (
		<PopUp
			onClose={handleClose}
			open={open}
			setOpen={setOpen}
			size="small"
			title="Contact Us"
			onAccept={createReq}
			onCancel={handleClose}
			showFooter={true}
			cancelButtonLabel={'CANCEL'}
			acceptButtonLabel={'SUBMIT'}
		>
			<div className={styles.subjectContainer} id={'contactUs_subject_container'}>
				<AppInput
					autoFocus
					value={subject}
					name={'subject'}
					label='Subject'
					onChange={setSubject}
					overrideClasses={{ input: styles.subjectInput }}
					placeholder={'Enter Your Subject Here...'}
					error={!isValidSubject}
					errorMessage={'Please insert a valid value, input is empty or too long'}
					required={true}
				/>
			</div>
			<div className={styles.messageContainer} id={'contactUs_message_container'}>
				<AppTextArea
					value={message}
					name={'message'}
					label='Message'
					onChange={setMessage}
					overrideClasses={{ textArea: styles.messageInput }}
					placeholder={'Enter Your Message Here...'}
					error={!isValidMessage}
					errorMessage={'Please insert a valid value, input is empty or too long'}
					required={true}
				/>
			</div>

		</PopUp>
	);

}

