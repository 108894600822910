import * as React from "react";

const GoSVG = (props) => (
	<svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M23.9666 36.0914C24.4296 35.936 24.8862 35.7599 25.3344 35.5633L25.3338 35.5635C27.7504 34.505 29.9339 32.8602 31.7107 30.7498C33.7009 28.3853 35.079 25.5733 35.6964 22.6173C36.0799 20.7807 34.9143 18.9785 33.0924 18.5921C31.2711 18.2054 29.4836 19.3811 29.1001 21.2178C28.719 23.0446 27.8211 24.8675 26.572 26.3513C25.2642 27.9045 23.6278 29.0431 21.8392 29.6427C19.2371 30.5151 16.2032 30.3207 13.5156 29.1086C10.68 27.8298 8.5783 25.5513 7.5977 22.6938C7.0708 21.1586 6.85243 19.607 6.9479 18.0813C7.03844 16.6378 7.40601 15.2542 8.04007 13.9698C9.32887 11.3605 11.6213 9.37066 14.4964 8.36793C16.2681 7.74977 18.199 7.63305 20.2369 8.0201C21.9555 8.34722 23.1027 8.9207 23.1559 8.94727L23.1562 8.94744C24.8201 9.78922 26.8453 9.11201 27.6809 7.43431C28.5159 5.7569 27.8444 3.71462 26.181 2.87256C25.7069 2.63223 23.9664 1.81207 21.4871 1.34053C18.2809 0.730899 15.1877 0.934015 12.2926 1.9444C7.73994 3.53365 4.08709 6.72751 2.00632 10.9395C-0.11526 15.2339 -0.391407 20.1976 1.22712 24.9158C2.82323 29.5667 6.2095 33.259 10.7633 35.3131C14.9714 37.2096 19.7835 37.4942 23.9666 36.0914ZM13.9773 14.4758V23.085C13.9773 24.6736 15.7401 25.6281 17.0704 24.7598L23.9327 20.2807C25.1683 19.4742 25.1351 17.6532 23.8709 16.8923L17.0086 12.7622C15.6756 11.96 13.9773 12.92 13.9773 14.4758Z" fill="url(#paint0_linear_6896_230179)"/>
		<defs>
			<linearGradient id="paint0_linear_6896_230179" x1="18.0236" y1="18.9844" x2="18.0236" y2="52.7906" gradientUnits="userSpaceOnUse">
				<stop stopColor="#A56EFF"/>
				<stop offset="1" stopColor="#003A99"/>
			</linearGradient>
		</defs>
	</svg>

);

export default GoSVG;
