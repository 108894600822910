import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import useStateWithValidation from "../../custom-hooks/useStateWithValidation";
import AppButton from "../../components/AppButton";
import SynamediaAuthContainer from "../../components/SynamediaAuthContainer";
import AppCheckbox from "../../components/AppCheckbox";
import { getQueryParams } from "../../utils/router-utils";
import AppSteps from "../../components/AppSteps";
import { getAuth0Config } from "../../config";
import { Navigate } from "react-router-dom";
import clsx from "clsx";
import AppInput from "../../components/AppInput";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: "center",
		margin: '0 auto 0 auto',
		width: 370,
		paddingBottom: 12
	},
	formContainer: {
		width: '100%',
	},
	stepsContainer: {
		marginLeft: 4,
		width: '100%',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'start',
		height: '100%',
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start',
		alignItems: 'start',
	},
	userInputContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	termsAndConditionsContainer: {
		display: 'flex',
		paddingBottom: 5
	},
	termsAndConditionsLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 14,
	},
	setupAccountButton: {
		width: '100%',
		height: 40,
		marginTop: 30,
		fontWeight: 400,
		fontFamily: 'Source Sans Pro',
	},
	labelWelcome: {
		height: 42,
		width: '100%',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 600,
		fontSize: 28,
		textAlign: 'center',
		color: '#11161D',
		marginBottom: 8
	},
	label: {
		width: '100%',
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		textAlign: 'center',
		color: '#293447',
	},
	link: {
		textDecoration: 'none'
	},
	input: {
		width: '373px !important',
		height: '40px !important',
	},
	inputLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 14,
		textAlign: 'center',
		color: '#293447',
		height: 15
	},
	inputTop:{
		margin: '8px 0 8px 0 !important',

	},
	inputBottom:{
		margin: '8px 0 12px 0 !important'
	}
}));

const steps = ['Set Password', 'About You'];

const SignupPage = () => {
	const styles = useStyles();

	const { state } = getQueryParams();

	const [userName, setUserName, isValidUserName] = useStateWithValidation('', name => name?.trim() && name.length < 100);
	const [userRole, setUserRole, isValidUserRole] = useStateWithValidation('', role => role?.trim() && role.length < 100);

	// TODO: implement dynamic active state when implementing full signup
	// const [activeStep, setActiveStep] = useState(1);
	const activeStep = 1;
	const [userAcceptConditions, setUserAcceptConditions] = useState(false);
	const buttonDisabled = !userAcceptConditions || !isValidUserName || !isValidUserRole;

	const form = (
		<form className={styles.formContainer} method={'POST'} action={`https://${getAuth0Config().domain}/continue?state=${state}&confirm=${userAcceptConditions}&name=${userName}&role=${userRole}`} >

			<div className={styles.userInputContainer}>
				<AppInput
					autoFocus
					error={!isValidUserName}
					value={userName}
					name={'name'}
					onChange={setUserName}
					label={'Full Name'}
					overrideClasses={{ input: clsx(styles.input, styles.inputTop), label: styles.inputLabel }}
				/>
				<AppInput
					value={userRole}
					error={!isValidUserRole}
					name={'role'}
					label={'Job title'}
					onChange={setUserRole}
					overrideClasses={{ input:  clsx(styles.input, styles.inputBottom), label: styles.inputLabel }}
				/>
			</div>
			<div className={styles.termsAndConditionsContainer}>
				<AppCheckbox
					name={'confirm'}
					value={'yes'}
					sx={{ padding: 0, marginRight: '5px' }}
					onClick={(checked) => setUserAcceptConditions(checked)}
				/>
				<div className={styles.termsAndConditionsLabel}>
					I agree to the <a href="https://www.synamedia.com/cloud-services-agreement" target="_blank" rel="noreferrer" className={styles.link}>terms and conditions</a>
				</div>
			</div>
			<AppButton
				variant={'contained'}
				disabled={buttonDisabled}
				type={'submit'}
				onClick={(event) => {
					event.currentTarget.disabled = true; // Preventing multiple submits
					event.target.form.submit();
				}}
				overrideClasses={{ button: styles.setupAccountButton }}
			>
						Create Account
			</AppButton>
		</form>
	);

	const content = (
		<div className={styles.rootContainer}>
			<div className={styles.stepsContainer}>
				<AppSteps steps={steps} activeStep={activeStep}/>
			</div>
			<div className={styles.titleContainer}>
				<div className={styles.labelWelcome}>Welcome</div>
				<div className={styles.label}>Create your account</div>
			</div>
			{form}
		</div>
	);

	if (!state) {
		console.warn('Missing Auth0 state');

		return <Navigate replace to={'/'}/>;
	}

	document.title = 'Synamedia Cloud Portal | Signup';

	return (
		<SynamediaAuthContainer
			content={content}
			desc={<div>Your single access point for all your Synamedia products. <br/> Create your account here to access your products.</div>}
		/>
	);
};

export default SignupPage;
