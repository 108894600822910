import React, { useState, useEffect } from 'react';
import styles from "./InviteUsersForm.module.css";
import { useUserInfo } from '../../../../../../../auth/UserInfoProvider';
import { useMainContainerStore, useUsersInvitationsStore, useUsersStore } from '../../../../../../../state-management';
import {
	inviteUser,
	getAllProductRoles,
	getAccountRoles,
	getProjectsByAccountId
} from "../../../../../../../external-apis";
import { useQueryWithAuthorization } from "../../../../../../../custom-hooks";
import shallow from 'zustand/shallow';
import { useQueryClient } from 'react-query';
import Loader from '../../../../../../../components/Loader';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import CloseIconSvg from '../../../../../../../assets/icons/CloseIconSvg';
import AccountRoles from '../AccountRoles';
import ProductsPerProjectRoles from '../ProductsPerProjectRoles';
import AppCloseConfirmation from '../../../../../../../components/AppCloseConfirmation';
import ProductAdminRoles from '../ProductAdminRoles';
import usePostCall from './usePostCall';
import { Button, TextArea } from '@ip-synamedia/common-ui-library';
import InputEmail from '../InputEmail/InputEmail';
import getMessageFromError from "../../../../../../../utils/API-calls";
import UsersSummary from '../../../UsersSummary';
import { mapProductsRoles, mapAccountRoles, mapProjectsToUiElement, mapFilterProductRoles } from "../../../useUsers";
import analytics from "@matisse/portal-analytics";
import useStore from "../../../../../../../state-management/mainContainerStore";

const MAX_CHAR_WARNING = 240;
const MAX_CHAR = 250;

const InviteUsersForm = (props) => {
	useEffect(() => {
		return () => handleReset();
		// eslint-disable-next-line
	},[]);
	const setActiveTab = useUsersStore(state => state.setActiveTab);
	const [isInviting, setIsInviting] = useState(false);
	const [tags, setTags] = useState([]);
	const [emailError, setEmailError] = useState({ isError: false, errorString: "" });
	let successEmailsObj = [];
	let failedEmailsObj = [];
	let successCount = 0;
	let failedCount = 0;
	let toastErrorMessage = "Something went Wrong!";

	const { closeEditConfirmationVisible, setCloseEditConfirmationVisible, returnToAccountManagement, ...rest } = props;
	const staleTime = 120000;
	const { accountId } = useUserInfo();
	const queryClient = useQueryClient();
	const [{
		data: users = [],
	}] =  useMainContainerStore(state => [state.usersPromise], shallow);

	const {
		data: tenantsPerProjects = {},
	} = useStore(state => state.tenantsPerProjectsPromise, shallow);

	const [resetState, setEmails, removeEmail, resetEmails, setMessage, setProductRolesByProjectId] = useUsersInvitationsStore(state => [state.reset, state.setEmails, state.removeEmail, state.resetEmails, state.setMessage, state.setProductRolesByProjectId], shallow);
	const [firstRender, setFirstRender] = useState(true);

	const userInvitationData = useUsersInvitationsStore(state => ({
		emails: state.emails,
		message: state.message,
		accountRolesIds: state.accountRolesIds,
		productAdminRolesIds: state.productAdminRolesIds,
		productRolesByProjectId: state.productRolesByProjectId,
		
	}), shallow);

	const {
		data:projects = [],
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectsError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const {
		isLoading: isAccountRolesLoading,
		isError: isAccountRolesError,
		error: accountRolesError,
		data: accountRoles = [],
	} = useQueryWithAuthorization("roles-account", getAccountRoles("account"), {
		select: mapAccountRoles,
		staleTime: staleTime,
	});
	const {
		isLoading: productsIsLoading,
		isError: productsIsError,
		error: productsError,
		data: allRoles = []
	} = useQueryWithAuthorization('all-roles', getAllProductRoles(), {
		staleTime
	});

	const handleSuccessPostApiCall = (data,variables) => {
		if(data === "" || data) successCount += 1;
		const email = JSON.parse(variables?.body).email;

		successEmailsObj.push({ data,email });
	};

	const handleFailedPostApiCall = (error, variables) => {
		if(error) {
			failedCount += 1;
			const email = JSON.parse(variables?.body)?.email;

			failedEmailsObj.push({ error,email });
		}
	};
	
	const { mutateAsync } = usePostCall(handleSuccessPostApiCall,handleFailedPostApiCall);

	const addFailedEmailsTags = () => {
		resetEmails();
		const failedTags = [];

		failedEmailsObj.forEach(({ email, error }) => {
			let errMessage = JSON.parse(error?.message);

			if(errMessage?.message) {
				errMessage = errMessage.message;
			}

			failedTags.push({
				id: email,
				label: email,
				tooltipMessage: errMessage ? "An active user cannot be invited." : "Unable to invite.",
				type: "removable",
				variant: "error"
			});
		});
		setTags(failedTags);
		setEmailError({ isError: true, errorString: "Something went wrong while trying to send out the invites.Please try again." });
	};

	const showToast = () => {
		const successMessage = <div id={'inviteUser_toast_successContainer'}>
			<div  id={'inviteUser_toast_successMessage'}>Invites sent to {successCount} users successfully.</div>
			<div onClick={() => setActiveTab("invitedUsers")} className={styles.toastLink} id="view-invited">view invited users</div>
		</div>;

		if(successCount && failedCount) {
			toast.info(successMessage,{
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			});
			toast.error(<div id={'inviteUser_toast_error'}>{`${failedCount} invites could not sent out. \nPlease try again.`}</div>,{
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			});
		} else if (successCount) {
			toast.success(successMessage,{
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			});
			handleCancel(false);
		} else {
			toast.error(<div id={'inviteUser_toast_error'}>{toastErrorMessage}</div>, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			});
		}
		setIsInviting(false);
		queryClient.invalidateQueries(['users-invited', accountId]);
	};

	const assignProjectIds = () => {
		let _productRolesByProjectId = {};

		projects.forEach((project, index) => {
			_productRolesByProjectId[project.id] = [];
		});
		setProductRolesByProjectId(_productRolesByProjectId);
	};

	const handleReset = () => {
		resetState();
		setFirstRender(true);
		assignProjectIds();
		setTags([]);
	};

	useEffect(() => {
		if ((firstRender) && projects) {
			assignProjectIds();
		}
		// eslint-disable-next-line
    }, [projects, firstRender]);

	const handleEmailChange = (email, isRemove = false) => {
		if(firstRender) setFirstRender(false);
		if(isRemove)
			removeEmail(email);
		else
			setEmails([email]);
	};
	const handleMessageChange = (event) => {
		let substringMessage = event.target.value;

		if(event.target.value?.length >= MAX_CHAR) {
			substringMessage = substringMessage.substring(0,250);
		}
		setMessage(substringMessage);
	};

	const productRoles = mapProductsRoles(allRoles, false);
	const productAdminRoles = mapProductsRoles(allRoles, true);
	const filterProductRoles = mapFilterProductRoles(tenantsPerProjects, productRoles, projects);

	const createInvitationObj = () => {
		const {
			emails,
			message,
			accountRolesIds,
			productAdminRolesIds,
			productRolesByProjectId
		} = userInvitationData;

		const membership = [];
		const invites = [];

		Object.keys(productRolesByProjectId).forEach(projectId => {
			if (productRolesByProjectId[projectId].length > 0)
				membership.push({
					projectId,
					productRoles: [].concat.apply([], productRolesByProjectId[projectId].map(role => role.id))
				});
		});

		if(Array.isArray(emails) && emails.length > 0) {
			emails.forEach(email => {
				invites.push({
					email,
					message,
					accountRoles: [...accountRolesIds, ...productAdminRolesIds],
					membership
				});
			});
		} 

		return invites;
	};

	const handleCancel = (isConfirmationNeeded = true) => {
		isConfirmationNeeded = isConfirmationNeeded && (userInvitationData?.emails?.length);
		isConfirmationNeeded && setCloseEditConfirmationVisible(!!isConfirmationNeeded);
		if (!isConfirmationNeeded) {
			setFirstRender(true);
			returnToAccountManagement();
		}
	};

	const handleInvite = async () => {
		setIsInviting(true);
		analytics.account.userInvited();
		const invitationObjs = createInvitationObj();

		if(Array.isArray(invitationObjs) && invitationObjs.length > 0) {
			invitationObjs.forEach((invitationObj,index) => {
				mutateAsync(inviteUser(accountId, invitationObj),{ 
					onSettled: () => { 
						if(index === invitationObjs.length - 1) {
							const isDone = () => {
								if(invitationObjs.length === (successEmailsObj.length + failedEmailsObj.length)) {
									if(failedEmailsObj.length) {
										addFailedEmailsTags();
									}
									showToast();
								} else {
									setTimeout(() => isDone(),1000);
								}
							};

							isDone();
						}
					} 
				}).catch(() => {});
			});
		} else {
			toast.warning(`Unable to invite, Please verify the information!`,{
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
			});
			setIsInviting(false);
		}

	};

	if (productsIsLoading || isAccountRolesLoading || projectsIsLoading) return <Loader />;
	if(projectsIsError) return <div id={"inviteUser_projects_error"}>{getMessageFromError(projectsError)}</div>;
	if (productsIsError) return <div id={'inviteUser_productRoles_error'}>{getMessageFromError(productsError)}</div>;
	if (isAccountRolesError) return <div id={'inviteUser_accountRoles_error'}>{getMessageFromError(accountRolesError)}</div>;

	if (accountRolesError || productsError) {
		toast.error("Failed to featch the roles");
	}

	const getMaxCharError = (message) => {
		let res = null;

		if(message?.length > 0) {
			if(message?.length >= MAX_CHAR_WARNING) {
				res = `Maximum ${MAX_CHAR} characters (` + (MAX_CHAR - message?.length) + " remaining)" ;
			}
		}

		return res;
	};
	
	return (
		<div className={styles.inviteUsersContainer} {...rest}>
			<div className={styles.inviteUsersFormContainer} id="InviteUsersForm_inviteUsersFormContainer">
				<div className={clsx(styles.formRow, styles.paddingTop)}>
					<div className={styles.formCol}>
						<div className={styles.clearAll}>
							<Button size="small" color="ghost">
								<div className={styles.clearAllContainer} onClick={handleReset}>
									<div className={styles.clearAllIcon}><CloseIconSvg /></div>
									<div id={'inviteUser_clearAll_btn'} className={styles.clearAllText}>clear all entries</div>
								</div>
							</Button>
						</div>
					</div>
				</div>
				<div className={styles.formRow}>
					<div className={styles.formCol}>
						<div className={styles.emailContainer}>
							<div className={styles.messageTextAreaLabel}>Enter email addresses <span className={styles.required}>*</span></div>
							<InputEmail 
								id={"inviteUser_content_emailInput"}
								tags={tags}
								setTags={setTags}
								emailError={emailError}
								setEmailError={setEmailError}
								handleEmailChange={handleEmailChange}
							/>
						</div>
						<div className={styles.textAreaContainer}>
							<div id={'inviteUser_content_optionalMessage'} className={styles.messageTextAreaLabel}>Add a message (optional)</div>
							<TextArea
								value={userInvitationData.message ? userInvitationData.message : ''}
								onChange={handleMessageChange}
								id="textAreaMessage"
								error={userInvitationData.message?.length >= MAX_CHAR_WARNING}
								helperText={getMaxCharError(userInvitationData?.message)}
								placeholder="Add a custom message that you want your users to read."
								resizing = {false}
								rows={3}
								style={{ width: "100%" }}
							/>
						</div>
					</div>
				</div>
				<div className={styles.formRow}>
					<div className={styles.formCol}>
						<div className={styles.sectionContainer}>
							<div  id={'inviteUser_content_rolesTitle'} className={styles.sectionHeader}>Assign Roles <span className={styles.asterisk}>*</span></div>
							<div className={styles.accountRolesContainer}>
								<div  id={'inviteUser_content_accountRolesTitle'} className={styles.accountRolesTitle}>Account Roles</div>
								{accountRoles.length ? <AccountRoles accountRoles={accountRoles} setFirstRender={setFirstRender} firstRender={firstRender}/>:<div>There was a problem to retrieve the list of the available account roles.Please try again later or contact the support.</div>}
							</div>
							<ProductAdminRoles productAdminRoles={productAdminRoles} parentFirstRender={firstRender} setParentFirstRender={setFirstRender} />
							{(projects.length && productRoles.length  && filterProductRoles.length) ? <div style={{ background: "#FFFFFF", height: "360px", width: "100%", borderRadius: "8px" }}>
								<ProductsPerProjectRoles projects={projects} productRoles={productRoles} filterProductRoles={filterProductRoles} parentFirstRender={firstRender} setParentFirstRender={setFirstRender}/>
							</div>:<div id={'inviteUser_projectRoles_empty'}/>}
						</div>
					</div>
				</div>
				{closeEditConfirmationVisible !== undefined && <AppCloseConfirmation id={'inviteUser_closeConfirmation'} visible={closeEditConfirmationVisible} setVisible={setCloseEditConfirmationVisible} text={'Are you sure you want to cancel inviting the user?'} resetState={() => { resetState(); setFirstRender(true); returnToAccountManagement(); }} />}
			</div>
			<UsersSummary summaryTitle="Invite Summary" isProcessing={isInviting} projects={projects} accountRolesData={accountRoles} tenantsPerProjects={tenantsPerProjects} productAdminRolesData={productAdminRoles} userInvitationData={userInvitationData} processDisabled={!!users.find(user => user.email === userInvitationData.emails) || emailError.isError || userInvitationData.emails.length === 0 || userInvitationData.accountRolesIds.size === 0 || tags.some(tag => tag?.variant === "error") || isInviting } handleProcessing={handleInvite} handleCancel={handleCancel} />
			{isInviting ? <div className={styles.loaderBackDrop}><Loader/></div> : null}
		</div>
	);
};

export default InviteUsersForm;