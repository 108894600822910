import * as React from "react";

const IrisSVG = (props) => (
	<svg
		width={38}
		height={37}
		viewBox="0 0 38 37"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M24.8003 18.8789C24.8003 22.1925 22.1139 24.8789 18.8003 24.8789C15.4867 24.8789 12.8003 22.1925 12.8003 18.8789C12.8003 15.5653 15.4867 12.8789 18.8003 12.8789C19.1051 12.8789 19.4043 12.9013 19.6971 12.9453L17.5747 18.0189L17.5387 18.1149C17.3435 18.6933 17.4899 19.3221 17.9211 19.7549C18.2275 20.0621 18.6339 20.2269 19.0507 20.2269C19.2203 20.2269 19.3923 20.1997 19.5603 20.1437L19.6091 20.1269L24.7363 17.9989C24.7787 18.2861 24.8003 18.5797 24.8003 18.8789Z"
			fill="url(#paint0_linear_6896_230134)"
		/>
		<path
			d="M27.2421 1.03558L20.2797 16.9796C20.1397 17.2556 20.4333 17.55 20.7093 17.41L36.7357 10.5212C37.0245 10.3756 36.9389 9.94198 36.6165 9.91638L31.0957 7.94198C31.0749 7.94038 31.0549 7.93718 31.0349 7.93158C30.4877 7.78438 30.0605 7.35958 29.9093 6.81318C29.9037 6.79398 29.9005 6.77398 29.8989 6.75398L27.8461 1.15158C27.8173 0.831584 27.3877 0.749184 27.2421 1.03558Z"
			fill="url(#paint1_linear_6896_230134)"
		/>
		<path
			d="M31.9229 14.6791L31.8453 14.7127C30.4525 15.3111 29.6077 16.7247 29.7005 18.2375C29.7141 18.4631 29.7213 18.6903 29.7213 18.9191C29.7213 21.8255 28.5893 24.5575 26.5349 26.6127C24.4797 28.6671 21.7477 29.7991 18.8413 29.7991C15.9349 29.7991 13.2029 28.6671 11.1477 26.6127C9.09327 24.5575 7.96127 21.8255 7.96127 18.9191C7.96127 16.0127 9.09327 13.2807 11.1477 11.2255C13.2029 9.17111 15.9349 8.03911 18.8413 8.03911C19.0741 8.03911 19.3069 8.04631 19.5373 8.06071C21.0261 8.15431 22.4117 7.29911 23.0093 5.93191L23.0437 5.85351C24.0349 3.58311 22.4621 1.02951 19.9893 0.87431C19.6197 0.85191 19.2485 0.83911 18.8741 0.83911C9.02687 0.82151 0.929274 8.76151 0.762874 18.6071C0.590874 28.8343 8.91087 37.1599 19.1357 36.9967C28.9837 36.8391 36.9333 28.7447 36.9197 18.8951C36.9197 18.4887 36.9053 18.0855 36.8781 17.6863C36.7141 15.2463 34.1677 13.7135 31.9213 14.6791H31.9229Z"
			fill="url(#paint2_linear_6896_230134)"
		/>
		<mask
			id="mask0_6896_230134"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={38}
			height={37}
		>
			<path
				d="M24.9602 18.8785C24.9602 22.1921 22.2738 24.8785 18.9602 24.8785C15.6466 24.8785 12.9602 22.1921 12.9602 18.8785C12.9602 15.5649 15.6466 12.8785 18.9602 12.8785C19.265 12.8785 19.5642 12.9009 19.857 12.9449L17.7346 18.0185L17.6986 18.1145C17.5034 18.6929 17.6498 19.3217 18.081 19.7545C18.3874 20.0617 18.7938 20.2265 19.2106 20.2265C19.3802 20.2265 19.5522 20.1993 19.7202 20.1433L19.769 20.1265L24.8962 17.9985C24.9386 18.2857 24.9602 18.5793 24.9602 18.8785V18.8785Z"
				fill="url(#paint3_linear_6896_230134)"
			/>
			<path
				d="M27.4021 1.03519L20.4397 16.9792C20.2997 17.2552 20.5933 17.5496 20.8693 17.4096L36.8957 10.5208C37.1845 10.3752 37.0989 9.94159 36.7765 9.91599L31.2557 7.94159C31.2349 7.93999 31.2149 7.93679 31.1949 7.93119C30.6477 7.78399 30.2205 7.35919 30.0693 6.81279C30.0637 6.79359 30.0605 6.77359 30.0589 6.75359L28.0061 1.15119C27.9773 0.831187 27.5477 0.748787 27.4021 1.03519Z"
				fill="url(#paint4_linear_6896_230134)"
			/>
			<path
				d="M32.0828 14.6787L32.0052 14.7123C30.6124 15.3107 29.7676 16.7243 29.8604 18.2371C29.874 18.4627 29.8812 18.6899 29.8812 18.9187C29.8812 21.8251 28.7492 24.5571 26.6948 26.6123C24.6396 28.6667 21.9076 29.7987 19.0012 29.7987C16.0948 29.7987 13.3628 28.6667 11.3076 26.6123C9.25319 24.5571 8.12119 21.8251 8.12119 18.9187C8.12119 16.0123 9.25319 13.2803 11.3076 11.2251C13.3628 9.17071 16.0948 8.03871 19.0012 8.03871C19.234 8.03871 19.4668 8.04591 19.6972 8.06031C21.186 8.15391 22.5716 7.29871 23.1692 5.93151L23.2036 5.85311C24.1948 3.58271 22.622 1.02911 20.1492 0.873913C19.7796 0.851513 19.4084 0.838713 19.034 0.838713C9.18679 0.821113 1.08919 8.76111 0.922786 18.6067C0.750786 28.8339 9.07079 37.1595 19.2956 36.9963C29.1436 36.8387 37.0932 28.7443 37.0796 18.8947C37.0796 18.4883 37.0652 18.0851 37.038 17.6859C36.874 15.2459 34.3276 13.7131 32.0812 14.6787H32.0828Z"
				fill="url(#paint5_linear_6896_230134)"
			/>
		</mask>
		<g mask="url(#mask0_6896_230134)">
			<circle
				opacity={0.1}
				cx={18.7185}
				cy={37.0745}
				r={25.2534}
				fill="#003A99"
			/>
		</g>
		<mask
			id="mask1_6896_230134"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={37}
			height={37}
		>
			<path
				d="M24.8804 18.8797C24.8804 22.1933 22.194 24.8797 18.8804 24.8797C15.5668 24.8797 12.8804 22.1933 12.8804 18.8797C12.8804 15.5661 15.5668 12.8797 18.8804 12.8797C19.1852 12.8797 19.4844 12.9021 19.7772 12.9461L17.6548 18.0197L17.6188 18.1157C17.4236 18.6941 17.57 19.3229 18.0012 19.7557C18.3076 20.0629 18.714 20.2277 19.1308 20.2277C19.3004 20.2277 19.4724 20.2005 19.6404 20.1445L19.6892 20.1277L24.8164 17.9997C24.8588 18.2869 24.8804 18.5805 24.8804 18.8797Z"
				fill="url(#paint6_linear_6896_230134)"
			/>
			<path
				d="M27.3222 1.03635L20.3598 16.9803C20.2198 17.2563 20.5134 17.5507 20.7894 17.4107L36.8158 10.5219C37.1046 10.3763 37.019 9.94275 36.6966 9.91715L31.1758 7.94275C31.155 7.94115 31.135 7.93795 31.115 7.93235C30.5678 7.78515 30.1406 7.36035 29.9894 6.81395C29.9838 6.79475 29.9806 6.77475 29.979 6.75475L27.9262 1.15235C27.8974 0.832347 27.4678 0.749947 27.3222 1.03635Z"
				fill="url(#paint7_linear_6896_230134)"
			/>
			<path
				d="M32.003 14.6799L31.9254 14.7135C30.5326 15.3119 29.6878 16.7255 29.7806 18.2383C29.7942 18.4639 29.8014 18.6911 29.8014 18.9199C29.8014 21.8263 28.6693 24.5583 26.615 26.6135C24.5598 28.6679 21.8278 29.7999 18.9214 29.7999C16.015 29.7999 13.283 28.6679 11.2278 26.6135C9.17335 24.5583 8.04135 21.8263 8.04135 18.9199C8.04135 16.0135 9.17335 13.2815 11.2278 11.2263C13.283 9.17187 16.015 8.03987 18.9214 8.03987C19.1542 8.03987 19.387 8.04707 19.6174 8.06147C21.1062 8.15507 22.4918 7.29987 23.0893 5.93267L23.1238 5.85427C24.115 3.58387 22.5422 1.03027 20.0694 0.875073C19.6998 0.852673 19.3286 0.839873 18.9542 0.839873C9.10695 0.822273 1.00935 8.76227 0.842952 18.6079C0.670952 28.8351 8.99095 37.1607 19.2158 36.9975C29.0638 36.8399 37.0134 28.7455 36.9998 18.8959C36.9998 18.4895 36.9854 18.0863 36.9582 17.6871C36.7942 15.2471 34.2478 13.7143 32.0014 14.6799H32.003Z"
				fill="url(#paint8_linear_6896_230134)"
			/>
		</mask>
		<g mask="url(#mask1_6896_230134)">
			<circle
				opacity={0.2}
				cx={27.4539}
				cy={46.414}
				r={25.2534}
				fill="#003A99"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_6896_230134"
				x1={18.6312}
				y1={18.7639}
				x2={18.6312}
				y2={29.4627}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_6896_230134"
				x1={28.3428}
				y1={8.99416}
				x2={28.3427}
				y2={23.7815}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_6896_230134"
				x1={18.3303}
				y1={18.5726}
				x2={18.3303}
				y2={50.8115}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_6896_230134"
				x1={18.7911}
				y1={18.7636}
				x2={18.7911}
				y2={29.4623}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_6896_230134"
				x1={28.5027}
				y1={8.99376}
				x2={28.5027}
				y2={23.7811}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_6896_230134"
				x1={18.4902}
				y1={18.5722}
				x2={18.4902}
				y2={50.8111}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint6_linear_6896_230134"
				x1={18.7112}
				y1={18.7647}
				x2={18.7112}
				y2={29.4635}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint7_linear_6896_230134"
				x1={28.4228}
				y1={8.99492}
				x2={28.4228}
				y2={23.7822}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint8_linear_6896_230134"
				x1={18.4104}
				y1={18.5733}
				x2={18.4104}
				y2={50.8122}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#A56EFF" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
		</defs>
	</svg>

);

export default IrisSVG;
