import * as React from "react";

const ClarissaSVG = (props) => (
	<svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M23.8504 34.6315C24.3112 34.4782 24.7656 34.3044 25.2116 34.1104L25.211 34.1107C27.6159 33.0661 29.7888 31.443 31.557 29.3604C33.5376 27.0271 34.909 24.2522 35.5234 21.3352C35.905 19.5227 34.7451 17.7443 32.932 17.363C31.1196 16.9814 29.3406 18.1416 28.959 19.9541C28.5798 21.7568 27.6863 23.5556 26.4432 25.0199C25.1418 26.5526 23.5132 27.6762 21.7333 28.2679C19.1439 29.1288 16.1246 28.937 13.45 27.7408C10.6281 26.4789 8.53667 24.2304 7.56081 21.4106C7.03647 19.8956 6.81915 18.3645 6.91416 16.8589C7.00426 15.4344 7.37005 14.0691 8.00104 12.8017C9.2836 10.2267 11.565 8.26311 14.4261 7.2736C16.1892 6.66359 18.1107 6.54841 20.1388 6.93035C21.8491 7.25318 22.9908 7.81913 23.0436 7.84531L23.0439 7.84547C24.6998 8.67615 26.7152 8.00787 27.5467 6.35229C28.3777 4.69699 27.7094 2.68163 26.0541 1.85067C25.5823 1.61352 23.8502 0.80417 21.3829 0.338847C18.1923 -0.262752 15.1141 -0.0623124 12.233 0.93475C7.70237 2.50305 4.06722 5.65481 1.99653 9.81126C-0.114775 14.049 -0.389584 18.9473 1.2211 23.6033C2.80948 28.1929 6.17934 31.8365 10.7111 33.8635C14.8988 35.7351 19.6876 36.0159 23.8504 34.6315ZM37.0262 7.72695C37.9486 6.78754 37.9348 5.2782 36.9954 4.35576C36.056 3.43332 34.5467 3.44709 33.6242 4.3865L22.5577 15.6567L17.0888 10.2648L10.2591 16.5772C9.29218 17.4708 9.23281 18.9791 10.1264 19.9459C11.0201 20.9128 12.5283 20.9722 13.4951 20.0786L16.9825 16.8554L22.6124 22.406L37.0262 7.72695Z" fill="url(#paint0_linear_6896_230160)"/>
		<defs>
			<linearGradient id="paint0_linear_6896_230160" x1="18.9955" y1="17.7501" x2="18.9955" y2="51.1107" gradientUnits="userSpaceOnUse">
				<stop stopColor="#A56EFF"/>
				<stop offset="1" stopColor="#003A99"/>
			</linearGradient>
		</defs>
	</svg>

);

export default ClarissaSVG;
