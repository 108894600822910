import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import SynamediaCover from "../../components/SynamediaCover";

const useStyles = makeStyles(() => ({
	rootContainer: {
		height: '100%',
		width: '100%',
		display: 'flex'
	},
	cover: {
		flex: 3,
	},
	rootContent: {
		marginTop: 15,
		width: '100%',
		height: '100%',
		flex: 5
	},
	contentBody: {
		width: '100%',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'start',
		height: '100%',
		width:  '100%',
	},
}));

const SynamediaAuthContainer = ({ content, showTrial = false, desc = '' }) => {
	const styles = useStyles();

	return (
		<div className={styles.rootContainer}>
			<div className={styles.cover}>
				<SynamediaCover desc={desc} showTrial={showTrial}/>
			</div>
			<div className={styles.rootContent}>
				<div className={styles.contentContainer}>
					<div className={styles.content}>
						<div className={styles.contentBody}>
							{content}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SynamediaAuthContainer;
