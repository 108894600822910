import React, { useState } from 'react';
import InviteUsersForm from './InviteUsersForm';

const InviteUserContainer = (props) => {
	const { returnToAccountManagement, ...rest } = props;
	const [closeEditConfirmationVisible, setCloseEditConfirmationVisible] = useState(false);

	return (
		<InviteUsersForm closeEditConfirmationVisible={closeEditConfirmationVisible} setCloseEditConfirmationVisible={setCloseEditConfirmationVisible} returnToAccountManagement={returnToAccountManagement} {...rest}/>
	);
};

export default InviteUserContainer;