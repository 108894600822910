import React, { useState } from 'react';
import styles from "./EditApplicationForm.module.css";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { editApplication as apiEditApp } from "../../../../../../../external-apis";
import { useMutationWithAuthorization } from '../../../../../../../custom-hooks';
import { Tag, TextArea, TextInput } from '@ip-synamedia/common-ui-library';
import { useUserInfo } from '../../../../../../../auth/UserInfoProvider';
import Loader from '../../../../../../../components/Loader';
import { isEqual, sortBy } from "lodash";
import ApplicationSummary from '../../../ApplicationSummary';
import { secretCopyCb } from '../../../appUtils';
import CustomList from '../../../CustomList';

const EditApplicationForm = ({
	applicationData,
	productsPermissions,
	returnToAccountManagement,
	editApplicationData,
	setEditApplicationData,
}) => {

	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const { accountId } = useUserInfo();
	const [isUpdating,setIsUpdating] = useState(false);

	const handleEdit = () => {
		if(!accountId) return;
		const appUpdateData = {
			name: editApplicationData.application.trim(),
			permissions: editApplicationData.permissions
		};

		setIsUpdating(true);
		const editAppPromise = mutateAsync(apiEditApp(accountId, applicationData.projectId,applicationData.applicationId, appUpdateData));

		toast.promise(editAppPromise,
			{
				pending: "Editing Application...",
				success: {
					render() {
						queryClient.invalidateQueries(['get-application-by-id', applicationData.applicationId]);
						setIsUpdating(false);
						handleCancel();

						return 'Edited Successfully';
					}
				},
				error: {
					render({ data: error }) {
						console.error('Error Editing Application', error);
						setIsUpdating(false);

						return `Failed to Edit application: ${error.message}`;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	};

	const handleCancel = () => {
		returnToAccountManagement();
	};

	const isProcessDisabled = editApplicationData.permissions.length === 0 || isUpdating ||
	editApplicationData.application === "" || 
	(isEqual(sortBy(applicationData.permissions), sortBy(editApplicationData.permissions)) && 
	(applicationData.application === editApplicationData.application));

	return (
		<div className={styles.editApplicationContainer}>
			<div className={styles.editFromContainer}>
				<div className={styles.applicationDetailsContainer}>
					<div id={'editApplication_formContainer_title'} className={styles.applicationDetailsTitle}>
	 					{'Application Details'}
	 					{applicationData.application !== editApplicationData.application ?
							<div id={"application_details_modified_tag_container"}>
								<Tag id={"application_details_modified_tag"}
									label="modified"
									variant="info"
									type="status"
								/>
							</div> : null
						}
	 				</div>
					 <div className={styles.userInputContainer}>
		 				<div className={styles.userInputRow}>
		 					<div className={styles.inputTextContainer}>
		 						<TextInput 
									required 
									style={{ width: "100%" }} 
									id={'editApplication_name_textInput'}
									label={<>Application name <span className={styles.required}>*</span></>}
									value={editApplicationData?.application}
									onChange={(e) => {
										setEditApplicationData({ ...editApplicationData, application: (e.target.value) });
									}}
									error={editApplicationData?.application === ""}
									helperText={editApplicationData?.application === "" ? "Please enter a valid application name" : ""}
								/>
		 					</div>
		 					<div className={styles.inputTextContainer}>
		 						<TextInput style={{ width: "100%" }} id={'editApplication_clinetId_textInput'}
									label={'Client Id'} value={editApplicationData?.clientId} readOnly={true}
								/>
		 					</div>
		 					<div className={styles.inputTextContainer}>
		 						<TextInput style={{ width: "100%" }} type="password" id={'editApplication_clientSecret_textInput'}
									label={'Client Secret'} value={editApplicationData?.secretKey} readOnly={true} handleCopyCb={secretCopyCb} showActions={true}
								/>
		 					</div>
							<div className={styles.textAreaContainer} style={{ display: "none" }}>
								<TextArea
									label="Application description"
									// value={editApplicationData.applicationDescription}
									// onChange={()=>}
									id="applicationDescription"
									// error={}
									// helperText={}
									placeholder="Add a description for your application"
									resizing = {false}
									rows={3}
									style={{ width: "100%" }}
								/>
							</div>
		 				</div>
		 				
		 			</div>
				</div>

				{Object.keys(productsPermissions?.productsPermissions).length  && <div className={styles.editPermissionsContainer}>
					<div id={'editApplication_permissionsContainer_title'} className={styles.editPermissionsHeader}>
	 					{'Permissions'}
	 					{!isEqual(sortBy(applicationData.permissions), sortBy(editApplicationData.permissions)) ?
							<div id={"application_permissions_modified_tag_container"}>
								<Tag id={"application_permissions_modified_tag"}
									label="modified"
									variant="info"
									type="status"
								/>
							</div> : null
						}
	 				</div>
					<CustomList 
						id={"editApplication"}
						applicationData ={editApplicationData}
						setApplicationData = {setEditApplicationData}
						productsPermissions={productsPermissions}
					/>
				</div>}
			</div>
			<ApplicationSummary 
				summaryTitle="Summary" 
				isEdit={true} 
				handleProcessing={handleEdit} 
				handleCancel={handleCancel}
				applicationData={editApplicationData}
				processDisabled={isProcessDisabled}
				isProcessing={isUpdating}
			/>
		 	{isUpdating ? <div className={styles.loaderBackDrop}><Loader/></div> : null}
		</div>
	);
};

export default EditApplicationForm;