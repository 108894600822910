import { Tag } from "@ip-synamedia/common-ui-library";
import React from "react";
import ProductsRoles from "../ProductsRoles";
import styles from "./ProductAdminRoles.module.css";

export default function ProductAdminRoles({ productAdminRoles, isEdit, parentFirstRender, setParentFirstRender, changesMadeCmp }) {
	const productRoles = productAdminRoles;

	return (
		<>
			{productRoles?.length > 0 ?
				<div id={'inviteUser_productAdminRole_container'} className={styles.productAdminRolesContainer}>
					<div className={styles.productAdminRolesTitleContainer}>
						<div id={(isEdit ? 'edit' : 'invite' ) + 'User_productAdminRole_title'}  className={styles.productAdminRolesTitle}>Product Admin Roles</div>
						{isEdit && changesMadeCmp ? <Tag id={"productAdmin_role_modified_tag"} label="modified" variant="info" type="status"/> : null}
					</div>
					<div className={styles.rolesContainer}>
						<div id={'inviteUser_productAdminRole_content'} className={styles.productsRolesContainer}>
							<ProductsRoles productRoles={productRoles} isProductAdmin={true} isEdit={isEdit} parentFirstRender={parentFirstRender} setParentFirstRender={setParentFirstRender} />
						</div>
					</div>
				</div>:<div id={'inviteUser_content_emptyProductAdminRole'}/>
			}
		</>
	);
}
