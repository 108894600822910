import React from "react";
import styles from "./middleCards.module.css";
import variables from "@ip-synamedia/common-ui-library/dist/styles/variables.json";
import ProductsGraphicsSVG from "../../../assets/icons/graphics/ProductsGraphicsSVG";
import IrisSVG from "../../../assets/icons/products/IrisSVG";
import ClarissaSVG from "../../../assets/icons/products/ClarissaSVG";
import GoSVG from "../../../assets/icons/products/GoSVG";
import VividSVG from "../../../assets/icons/products/VividSVG";
import CsfEyeSVG from "../../../assets/icons/products/CsfEyeSVG";
import EverguardSVG from "../../../assets/icons/products/EverguardSVG";
import ProductsSVG from "../../../assets/icons/products/ProductsSVG";
import HyperscaleSVG from "../../../assets/icons/products/HyperscaleSVG";
import GravitySVG from "../../../assets/icons/products/GravitySVG";

const getProductLink = (item, tenantsMoreInfo) => {
	let link;
	const tenant =  tenantsMoreInfo.find(query => query?.data && query.data.id === item.tenantId);

	if(item?.productId)
		link = tenant && tenant.data.tenantProperties?.uiLink;

	if(item?.link) link = item.link;

	return link;
};

const emptyRecentlyUse = <div id={'RecentlyUsed_empty'} className={styles.emptyRecentlyUseRoot}>
	<div id={'recentlyUsed_emptyState_svg'} className={styles.emptyRecentlyUseSVG}>
		<ProductsGraphicsSVG/>
	</div>
	<div id={'recentlyUsed_emptyState_text'} className={styles.emptyRecentlyUseText}>
        No recently visited products
	</div>

</div>;

const iconStyle = {
	height: "14px",
	width: "13px",
	fill: variables.blue70,
	cursor: "pointer"
};

const linkWrapper = (link, children) => {
	return link ? <div
		onClick={() => {window.open(link,'_blank');}}
		rel="noreferrer"
		style={{
			display: 'flex',
			textDecoration: 'none',
			color: "inherit",
			alignItems: "center",
			gap: "16px",
			cursor: 'pointer'
		}}
	>
		{children}
	</div>:children;
};

const getIconByProductId = (id) => {
	switch (id) {
	case "iris":
		return <IrisSVG/>;
	case "everguard":
		return <EverguardSVG/>;
	case "clarissa":
		return <ClarissaSVG/>;
	case "go":
		return <GoSVG/>;
	case "vivid":
		return <VividSVG/>;
	case "csfeye":
		return <CsfEyeSVG/>;
	case "hyperscale":
		return <HyperscaleSVG/>;
	case "gravity":
		return <GravitySVG/>;
	default:
		return <ProductsSVG/>;
	}
};

export {
	getIconByProductId,
	linkWrapper,
	iconStyle,
	emptyRecentlyUse,
	getProductLink
};