import create from "zustand";

const initialState = Object.freeze({
	emails: [],
	email: undefined,
	message: undefined,
	accountRolesIds: new Set(),
	productAdminRolesIds: new Set(),
	productRolesByProjectId: {},
	jobTitle: undefined,
	userName: undefined,
	currProductsRoles : {}
});

const useStore = create((set, get) => ({
	...initialState,

	setEmails: (val) => {
		set((state) => ({
			emails: [...state.emails, ...val]
		}));
	},
	setEmail: (val) => {
		set(() => ({
			email: val
		}));
	},
	removeEmail: (val) => {
		set((state) => ({
			emails: state.emails.filter(email => email !== val)
		}));
	},
	resetEmails : () => {
		set(() => ({
			emails: []
		}));
	},
	setUserName: (val) => {
		set(() => ({
			userName: val
		}));
	},
	setJobTitle: (val) => {
		set(() => ({
			jobTitle: val
		}));
	},
	setMessage: (val) => {
		set(() => ({
			message: val
		}));
	},
	setAccountRolesIds: (val) => {
		set(() => ({
			accountRolesIds: new Set([...val])
		}));
	},
	setProductAdminRolesIds: (val) => {
		set(() => ({
			productAdminRolesIds: new Set([...val])
		}));
	},
	setProductRolesByProjectId: (val) => {
		set(() => ({
			productRolesByProjectId: { ...val }
		}));
	},
	setCurrProductsRoles: (val) => {
		set(() => ({
			currProductsRoles: { ...val }
		}));
	},
	reset: () => {
		// set(() => ({ accountRolesIds: initialState.accountRolesIds.clear(),...initialState }));
		set(() => ({ ...initialState }));
		set(() => ({
			accountRolesIds: new Set()
		}));
		set(() => ({
			productAdminRolesIds: new Set()
		}));
	},
}));

export default useStore;
