import { getApiEndpoint } from '../../config';

const baseHeaders = {
	'content-type': 'application/json'
};

const postContactUs = (params={}) => {
	const { headers={}, subject, message } = params;
	const body = {
		'emailType': 'contactUs',
		'subject': subject,
		'message':message
	};

	return {
		method: "POST",
		url: `${getApiEndpoint()}/accounts/mail`,
		headers: {
			...headers,
			...baseHeaders
		},
		body:JSON.stringify(body)
	};
};

export {
	postContactUs
};