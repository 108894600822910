import { useAuth0 } from "@auth0/auth0-react";
import { decode } from "jsonwebtoken";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import analytics from "@matisse/portal-analytics";
import axios from 'axios';
import { getApiEndpoint, getDomain } from '../../config';
import { useCookies } from "react-cookie";
import cookiesNames from "../../config/cookies";

const APP_REDIRECT_URL_CLAIM = "https://user.synamedia.com/appRedirectUrl";
const IDTokenClaimsMap = {
	[APP_REDIRECT_URL_CLAIM]: "appRedirectUrl"
};
const JWTKeys = {
	'https://account.synamedia.com/id': 'accountId',
	'https://account.synamedia.com/alias': 'accountAlias',
	'https://user.synamedia.com/id': 'userId',
	org_id: 'orgId',
	scope: 'scope',
};

const insertAccessTokenClaims = (decodedJWT = {}) => {
	return Object.keys(JWTKeys).reduce((userInfo, claimKey) => {
		const userInfoKey = JWTKeys[claimKey];

		if (claimKey in decodedJWT) userInfo[userInfoKey] = decodedJWT[claimKey];

		return userInfo;
	}, {});
};

function insertIdTokenClaims(userClaims, idTokenClaims) {
	for (const [claim, destKey] of Object.entries(IDTokenClaimsMap)) {
		if (claim in idTokenClaims) {
			userClaims[destKey] = idTokenClaims[claim];
		}
	}

	return userClaims;
}

async function getUserAccounts(accessToken, userId) {
	if (!userId) return [];

	try {
		const response = await axios.get(`${getApiEndpoint()}/accounts?email=${userId}`, {
			headers: {
				'Authorization': `Bearer ${accessToken}`,
				'Content-Type': 'application/json'
			},
			timeout: 5000
		});

		return response.data.map(account => ({ alias: account.alias, org_id: account.organizationId }));
	} catch (error) {
		return [];
	}
}

const UserInfoContext = createContext({});

const UserInfoProvider = ({ children }) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
	const [userClaims, setUserClaims] = useState({ isFetchingData: true });
	const [cookies, setCookie] = useCookies([cookiesNames.accounts]);

	const getUserClaims = useCallback(async () => {
		try {
			setUserClaims({ isFetchingData: true });
			const accessToken = await getAccessTokenSilently();
			let userClaims = insertAccessTokenClaims(decode(accessToken));
			const idTokenClaims = await getIdTokenClaims();

			userClaims = insertIdTokenClaims(userClaims, idTokenClaims);

			const userAccounts = await getUserAccounts(accessToken, userClaims.userId);

			const accountsFromCookie = cookies?.[cookiesNames.accounts] ?? [];
			const index = accountsFromCookie.findIndex(entry => entry.userId === userClaims.userId);

			if (index === -1) {
				accountsFromCookie.push({ userId: userClaims.userId, accounts: userAccounts });
			} else {
				accountsFromCookie[index].accounts = userAccounts;
			}

			setCookie(cookiesNames.accounts, accountsFromCookie, {
				path: '/',
				maxAge: 86400 * 365 * 10,
				...(!window.location.hostname.includes('localhost') && { domain: `.${getDomain()}` })
			});

			setUserClaims({ ...userClaims,
				isFetchingData: false
			});

			const allowCookieConsent = !idTokenClaims[APP_REDIRECT_URL_CLAIM];

			if (allowCookieConsent) {
				analytics.common.setCustomerAlias(userClaims.accountAlias);
			}

		} catch (e) {
			setUserClaims({ isFetchingData: false });
			console.error('An error occurred in UserInfoProvider', e);
		}
		// We get a lint warning as the dependencies are not exhaustive as we exclude cookies and setCookies.
		// We can not include them as we update the cookies in the hook which triggers another invocation and
		// we enter a loop. So disable the warning.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAccessTokenSilently, getIdTokenClaims, setUserClaims]);

	useEffect(() => {
		if (!isLoading && !isAuthenticated) {
			setUserClaims(prev => ({ ...prev, isFetchingData: false }));
		}

		if (!isLoading && isAuthenticated) {
			getUserClaims();
		}

	}, [getUserClaims, isAuthenticated, isLoading]);

	return (
		<UserInfoContext.Provider value={userClaims}>
			{children}
		</UserInfoContext.Provider>
	);
};

// A custom hook to get access to the provided data
export const useUserInfo = () => useContext(UserInfoContext);

// The provider
export default UserInfoProvider;
