import * as React from "react";

const GravitySVG = (props) => (

	<svg xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" width="36px" height="37px" viewBox="0 0 36 36" version="1.1">
		<defs>
			<linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="0.50009" y1="0.3749" x2="0.50009" y2="1.96741" gradientTransform="matrix(35.886719,0,0,35.78125,0.21875,0.214844)">
				<stop offset="0" style={{ "stopColor": "rgb(64.705882%,43.137255%,100%)", "stopOpacity": "1" }}/>
				<stop offset="1" style={{ "stopColor": "rgb(0%,22.745098%,60%)", "stopOpacity": "1" }}/>
			</linearGradient>
			<filter id="alpha" filterUnits="objectBoundingBox" x="0%" y="0%" width="100%" height="100%">
				<feColorMatrix type="matrix" in="SourceGraphic" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
			</filter>
			<mask id="mask0">
				<g filter="url(#alpha)">
					<rect x="0" y="0" width="36" height="36" style={{ "fill":"rgb(0%,0%,0%)", "fillOpacity": "0.101961", "stroke": "none" }}/>
				</g>
			</mask>
			<clipPath id="clip1">
				<rect x="0" y="0" width="36" height="36"/>
			</clipPath>
			<g id="surface5" clipPath="url(#clip1)">
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 11.640625 35.601562 C 13.460938 36.34375 15.546875 35.492188 16.296875 33.707031 C 17.050781 31.921875 16.1875 29.875 14.367188 29.136719 C 9.484375 27.15625 6.429688 22.101562 7.265625 16.757812 C 7.59375 14.664062 8.480469 12.792969 9.75 11.261719 C 6.09375 12.417969 2.800781 14.363281 0.0742188 16.894531 C -0.660156 25.042969 4.160156 32.566406 11.636719 35.601562 Z M 11.640625 35.601562 "/>
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 24.511719 31.332031 C 23.199219 31.332031 22.136719 32.375 22.136719 33.664062 C 22.136719 34.949219 23.199219 35.992188 24.511719 35.992188 C 25.824219 35.992188 26.886719 34.949219 26.886719 33.664062 C 26.886719 32.375 25.824219 31.332031 24.511719 31.332031 Z M 24.511719 31.332031 "/>
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 33.726562 17.339844 L 15.296875 17.339844 C 13.984375 17.339844 12.921875 18.382812 12.921875 19.671875 C 12.921875 20.957031 13.988281 22 15.296875 22 L 33.726562 22 C 35.039062 22 36.101562 20.957031 36.101562 19.671875 C 36.101562 18.382812 35.035156 17.339844 33.726562 17.339844 Z M 33.726562 17.339844 "/>
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 30.15625 24.332031 L 18.863281 24.332031 C 17.550781 24.332031 16.488281 25.378906 16.488281 26.664062 C 16.488281 27.949219 17.554688 28.996094 18.863281 28.996094 L 30.15625 28.996094 C 31.46875 28.996094 32.53125 27.949219 32.53125 26.664062 C 32.53125 25.378906 31.46875 24.332031 30.15625 24.332031 Z M 30.15625 24.332031 "/>
			</g>
			<mask id="mask1">
				<g filter="url(#alpha)">
					<rect x="0" y="0" width="36" height="36" style={{ "fill": "rgb(0%,0%,0%)", "fillOpacity": "0.101961", "stroke": "none" }}/>
				</g>
			</mask>
			<clipPath id="clip2">
				<rect x="0" y="0" width="36" height="36"/>
			</clipPath>
			<g id="surface8" clipPath="url(#clip2)">
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 33.816406 22 C 34.621094 22 35.328125 21.609375 35.757812 21.011719 C 32.886719 19.910156 29.765625 19.304688 26.5 19.304688 C 22.375 19.304688 18.484375 20.269531 15.035156 21.972656 C 15.152344 21.992188 15.265625 22 15.390625 22 Z M 33.816406 22 "/>
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 30.25 24.332031 L 18.957031 24.332031 C 17.644531 24.332031 16.582031 25.378906 16.582031 26.664062 C 16.582031 27.949219 17.648438 28.996094 18.957031 28.996094 L 30.25 28.996094 C 31.566406 28.996094 32.628906 27.949219 32.628906 26.664062 C 32.628906 25.378906 31.5625 24.332031 30.25 24.332031 Z M 30.25 24.332031 "/>
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 11.730469 35.601562 C 13.550781 36.34375 15.636719 35.492188 16.390625 33.707031 C 17.140625 31.921875 16.277344 29.875 14.457031 29.136719 C 12.535156 28.355469 10.894531 27.097656 9.667969 25.53125 C 7.855469 27.101562 6.269531 28.910156 4.96875 30.917969 C 6.816406 32.921875 9.109375 34.539062 11.730469 35.601562 Z M 11.730469 35.601562 "/>
				<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "rgb(0%,22.745098%,60%)", "fillOpacity": "1" }} d="M 24.601562 31.332031 C 23.289062 31.332031 22.226562 32.375 22.226562 33.664062 C 22.226562 34.949219 23.292969 35.992188 24.601562 35.992188 C 25.914062 35.992188 26.980469 34.949219 26.980469 33.664062 C 26.980469 32.375 25.914062 31.332031 24.601562 31.332031 Z M 24.601562 31.332031 "/>
			</g>
		</defs>
		<g id="surface1">
			<path style={{ "stroke": "none", "fillRule": "nonzero", "fill": "url(#linear0)" }} d="M 16.300781 33.710938 C 15.546875 35.496094 13.464844 36.34375 11.640625 35.605469 C 3.800781 32.421875 -1.121094 24.304688 0.21875 15.703125 C 1.800781 5.597656 11.429688 -1.332031 21.730469 0.214844 C 26.765625 0.972656 31.035156 3.625 33.890625 7.308594 C 35.082031 8.851562 34.777344 11.042969 33.207031 12.214844 C 31.636719 13.382812 29.402344 13.085938 28.210938 11.542969 C 26.429688 9.246094 23.78125 7.605469 20.644531 7.132812 C 14.242188 6.167969 8.253906 10.476562 7.269531 16.761719 C 6.433594 22.105469 9.488281 27.160156 14.367188 29.140625 C 16.191406 29.878906 17.054688 31.925781 16.300781 33.710938 Z M 33.726562 17.339844 L 15.300781 17.339844 C 13.988281 17.339844 12.925781 18.382812 12.925781 19.671875 C 12.925781 20.957031 13.992188 22 15.300781 22 L 33.726562 22 C 35.042969 22 36.105469 20.957031 36.105469 19.671875 C 36.105469 18.382812 35.039062 17.339844 33.726562 17.339844 Z M 30.164062 24.335938 L 18.871094 24.335938 C 17.554688 24.335938 16.492188 25.382812 16.492188 26.667969 C 16.492188 27.953125 17.558594 29 18.871094 29 L 30.164062 29 C 31.476562 29 32.539062 27.953125 32.539062 26.667969 C 32.539062 25.382812 31.472656 24.335938 30.164062 24.335938 Z M 24.515625 31.335938 C 23.199219 31.335938 22.136719 32.378906 22.136719 33.664062 C 22.136719 34.953125 23.203125 35.996094 24.515625 35.996094 C 25.824219 35.996094 26.890625 34.953125 26.890625 33.664062 C 26.890625 32.378906 25.824219 31.335938 24.515625 31.335938 Z M 24.515625 31.335938" />
			<use xlinkHref="#surface5" mask="url(#mask0)"/>
			<use xlinkHref="#surface8" mask="url(#mask1)"/>
		</g>
	</svg>

);

export default GravitySVG;
