import create from "zustand";
import { isEqual } from "lodash";
import { mainMenuItems } from "../config/mainMenu";

const initialState = Object.freeze({
	defaultComponent: undefined,
	contentComponent: undefined,
	sidebarComponent: undefined,
	activeMainMenuId: undefined,
	activeSidebarItemId: undefined,
	currentPageIndicator: undefined,
});

const useStore = create((set) => ({
	...initialState,
	setDefaultComponent: (val) => {
		set(() => ({
			defaultComponent: val,
		}));
	},
	setContentComponent: (val) => {
		set(() => ({
			contentComponent: val,
		}));
	},
	setActiveMainMenuByRoutePath: (val) => {
		const mainMenuItem = mainMenuItems.find(menuItem => menuItem.routePath === val);

		set((state) => ({
			activeMainMenuId: mainMenuItem?.id ?? state.activeMainMenuId,
			sidebarComponent: mainMenuItem?.sidebarComponent ?? state.sidebarComponent,
			contentComponent: null,
			activeSidebarItemId: mainMenuItem?.id ?? state.activeMainMenuId,
			currentPageIndicator: mainMenuItem?.currentPageIndicator,
		}));
	},
	setActiveMainMenuId: (val) => {
		const sidebarComponentToSet = mainMenuItems.find(menuItem => menuItem.id === val)?.sidebarComponent;

		set((state) => ({
			activeMainMenuId: val,
			sidebarComponent: sidebarComponentToSet ?? state.sidebarComponent,
			contentComponent: !isEqual(sidebarComponentToSet, state.sidebarComponent) ? null : state.contentComponent, // Clear content component when the side bar is changing
		}));
	},
	setActiveSidebarItemId: (val) => {
		set(() => ({ activeSidebarItemId: val }));
	},
	setCurrentPageIndicator: (val) => {
		set(() => ({ currentPageIndicator: val }));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;

