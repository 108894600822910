import { Tag } from "@ip-synamedia/common-ui-library";
import clsx from "clsx";
import React from "react";
import styles from "./List.module.css";

const List = ({ lable,options,selectedSpace,handleSpaceClick, isEdit = false, changesMadeCmp, ...rest }) => {

	return (
		<div className={styles.listContainer} {...rest}>
			<div id={'inviteUser_content_spaceListLabel'} className={styles.headerLabel}>{lable}</div>
			<div className={styles.listOpetions}>
				<div className={styles.listOptionsContainer}>
					{
						options.map((option, index) =>
							<div id={'inviteUser_content_spaceListOption' + index} key={option.id}  className={clsx(styles.listOption, option.id === selectedSpace?.id && styles.selectedlistOption)} onClick={() => handleSpaceClick(option)}>
								<div id={'inviteUser_content_spaceListOption_label' + index}>{option.label}</div>
								{ isEdit && !(changesMadeCmp?.productRolesByProjectIdCmp?.[option?.id]) ? <Tag id={"product_roles_modified_tag" + index} label="modified" variant="info" type="status"/> : null }
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default List;