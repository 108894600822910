import React, { useCallback, useEffect, useState } from "react";
import { useHref, useParams } from "react-router-dom";
import { useUserInfo } from "../../auth/UserInfoProvider";
import { useAuth0 } from "@auth0/auth0-react";
import { useCookies } from "react-cookie";
import cookiesNames from "../../config/cookies";
import { getDomain } from "../../config";
import NavBar from "../NavBar";
import { SynamediaLogoIcon } from "../Assets";
import { useQueriesWithAuthorization, useQueryWithAuthorization } from "../../custom-hooks";
import moment from "moment";
import {
	getAccountById,
	getInvitedUsers,
	getProducts,
	getProjectsByAccountId,
	getTenantByAccountIdAndProjectId,
	getUsers
} from "../../external-apis";
import { useAppPanelStore, useDecodedJwtStore, useMainContainerStore } from "../../state-management";
import shallow from "zustand/shallow";
import useDeepCompareEffect from "../../custom-hooks/useDeepCompareEffect";
import AccountUsers from "../../portals/matisse-portal/MatissePortalPanel/AccountUsers";
import styles from './MainContainer.module.css';
import Applications from "../../portals/matisse-portal/MatissePortalPanel/Applications";
import Audit from "../../portals/matisse-portal/MatissePortalPanel/Audit";

const mapProjectsToUiElement = (projects) => {
	projects.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);

	return (projects || []).map(project => {
		const { id, displayName } = project;

		return {
			id,
			label: displayName
		};
	});
};

const mapActiveUsers = (apiResponse) => {
	return apiResponse.map(userData => {
		const { userId, name, email, lastLogin, joined, role } = userData;

		return {
			id: userId,
			userName: name,
			userRole: role,
			email,
			lastLogin: lastLogin && moment(new Date(lastLogin)).format("DD MMM YYYY, h:mm A"),
			joined: joined && moment(new Date(joined)).format("DD MMM YYYY, h:mm A")
		};
	});
};

const sortFunction = (a, b) => {
	const priorityProducts = ['clarissa', 'csfeye', 'vivid'];
	const aProductId = a.productId?.toLowerCase();
	const bProductId = b.productId?.toLowerCase();
	const aExistInList = priorityProducts.includes(aProductId);
	const bExistInList = priorityProducts.includes(bProductId);

	if ((aExistInList && !bExistInList)) {
		return -1;
	}
	if ((!aExistInList && bExistInList)) {
		return 1;
	}
	if (!aExistInList && !bExistInList) {
		return 0;
	}

	return priorityProducts.indexOf(aProductId) - priorityProducts.indexOf(bProductId);
};
const mapInvitees = (apiResponse) => {

	return apiResponse.map(invite => ({ ...invite, id: invite.invitationId }));
};

const mapAccount = (apiResponse) => {
	const { owner, id } = apiResponse;

	return {
		owner,
		id
	};
};

export default function MainContainer({ redirectTo, children }) {
	const [cookies, setCookie] = useCookies();
	const { accountAlias, isFetchingData, orgId, accountId, scope, userId } = useUserInfo();
	const accountPreferences = cookiesNames.preferences + "." + orgId;
	const setDefaultComponent = useAppPanelStore(state => state.setDefaultComponent); // preload data maybe useAppInit method
	const [setProjectsPromise, setProductsPromise, setUsersPromise, setInviteesPromise,
		setTenantsPromise, setAccountPromise, setTenantsPerProjectsPromise] = useMainContainerStore(state =>
		[state.setProjectsPromise, state.setProductsPromise, state.setUsersPromise, state.setInviteesPromise,
			state.setTenantsPromise, state.setAccountPromise, state.setTenantsPerProjectsPromise], shallow);
	const [selectedSpace, setSelectedSpace] = useState(cookies?.[accountPreferences]?.activeSpace);
	const setDecodedJwt = useDecodedJwtStore(state => state.setDecodedJwt, shallow);

	const { logout } = useAuth0();
	const { accountAlias: accountAliasPathParam } = useParams();
	const urlPath = useHref();
	const staleTime = 120000;
	let callUserApi = false;

	callUserApi = scope && scope?.includes("account:user:list") ? true : false;

	const {
		data: projects = [],
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectsError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const {
		data: products = [],
		isError: productsIsError,
		isLoading: productsIsLoading,
		error: productsError
	} = useQueryWithAuthorization(['products'], getProducts(), {
		staleTime: staleTime
	});

	const {
		data: users = [],
		isError: usersIsError,
		isLoading: usersIsLoading,
		error: usersError
	} = useQueryWithAuthorization(['users-active', accountId], getUsers(accountId, callUserApi), {
		select: mapActiveUsers,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const {
		data: invitees = [],
		isError: inviteesIsError,
		isLoading: inviteesIsLoading,
		error: inviteesError
	} = useQueryWithAuthorization(['users-invited', accountId], getInvitedUsers(accountId, callUserApi), {
		enabled: accountId != null,
		select: mapInvitees,
		staleTime: staleTime
	});

	const {
		data: tenants = [],
		isError: tenantsIsError,
		isLoading: tenantsIsLoading,
		error: tenantsError
	} = useQueryWithAuthorization(['get-tenant-by-account-id-and-project-id', accountId, selectedSpace?.id], getTenantByAccountIdAndProjectId(accountId, selectedSpace?.id, {}, 'tenantId,productId,projectId,subscriptionStatus'), {
		staleTime: staleTime,
		enabled: !!(accountId && projects.length && selectedSpace?.id),
		select: (data) => data.sort(sortFunction)
	});

	const {
		data: account = {},
		isError: accountIsError,
		isLoading: accountIsLoading,
		error: accountError
	} = useQueryWithAuthorization(['get-account-by-id', accountId], getAccountById(accountId), {
		select: mapAccount,
		enabled: accountId != null,
		staleTime: staleTime
	}, setDecodedJwt);

	// Save API res
	useDeepCompareEffect(() => {
		accountId && setProjectsPromise({
			isLoading: projectsIsLoading,
			isError: projectsIsError,
			error: projectsError,
			data: projects
		});
		// eslint-disable-next-line
    }, [accountId, projects, projectsError, projectsIsError, projectsIsLoading]);

	useDeepCompareEffect(() => {
		setProductsPromise({
			isLoading: productsIsLoading,
			isError: productsIsError,
			error: productsError,
			data: products
		});
		// eslint-disable-next-line
    }, [products, productsError, productsIsError, productsIsLoading]);

	useDeepCompareEffect(() => {
		accountId && setUsersPromise({
			isLoading: usersIsLoading,
			isError: usersIsError,
			error: usersError,
			data: users
		});
		// eslint-disable-next-line
    }, [accountId, users, usersError, usersIsError, usersIsLoading]);

	useDeepCompareEffect(() => {
		accountId && setInviteesPromise({
			isLoading: inviteesIsLoading,
			isError: inviteesIsError,
			error: inviteesError,
			data: invitees
		});
		// eslint-disable-next-line
    }, [accountId, invitees, inviteesError, inviteesIsError, inviteesIsLoading]);

	useDeepCompareEffect(() => {
		accountId && projects.length && selectedSpace?.id && setTenantsPromise({
			isLoading: tenantsIsLoading,
			isError: tenantsIsError,
			error: tenantsError,
			data: tenants
		});
		// eslint-disable-next-line
    }, [accountId, tenants, tenantsError, tenantsIsError, tenantsIsLoading]);

	useDeepCompareEffect(() => {
		accountId && setAccountPromise({
			isLoading: accountIsLoading,
			isError: accountIsError,
			error: accountError,
			data: account
		});
		// eslint-disable-next-line
    }, [accountId, account, accountError, accountIsError, accountIsLoading]);

	// Save cookies
	useEffect(() => {
		//to support MTS-2070,
		// the accountAliasPathParam needs to be converted to lowercase (accountAlias must be in lowercase)
		if (!isFetchingData && accountAliasPathParam.toLowerCase() !== accountAlias) {
			logout({ returnTo: window.location.origin });
		}
	}, [accountAlias, accountAliasPathParam, isFetchingData, logout]);

	const saveCookies = useCallback((cookiesList = []) => {
		cookiesList.forEach(cookie => {
			// cookies expired for 10 years
			const maxAge = 86400 * 365 * 10;

			setCookie(cookie.name, cookie.value, {
				path: '/',
				maxAge,
				...(!window.location.hostname.includes('localhost') && { domain: `.${getDomain()}` })
			});
		});
	}, [setCookie]);

	useEffect(() => {
		if (!orgId || !accountAlias) return;

		saveCookies([
			{ name: cookiesNames.accountOrgId, value: orgId },
			{ name: cookiesNames.accountAlias, value: accountAlias },
			{ name: cookiesNames.userId, value: userId },
		]);

	}, [accountAlias, orgId, userId, saveCookies]);

	function assignRedirect() {
		window.location.assign(redirectTo);

		return null;
	}

	useDeepCompareEffect(() => {

		if (!projects || !projects.length) {
			return;
		}
		const cookiesActiveSpace = cookies?.[accountPreferences]?.activeSpace;
		const _selectedSpace = cookiesActiveSpace && projects.find(project => project.id === cookiesActiveSpace.id) ?
			cookies[accountPreferences].activeSpace : projects[0];

		if (!projects.length) return;

		saveCookies([
			{ name: accountPreferences, value: { activeSpace: _selectedSpace } },
		]);

	}, [accountAlias, orgId]);
	
	useDeepCompareEffect(() => {
		if (!selectedSpace && projects.length) {
			setSelectedSpace(projects[0]);
			saveCookies([
				{ name: accountPreferences, value: { activeSpace: projects[0] } },
			]);
		}
		selectedSpace && setSelectedSpace(cookies?.[accountPreferences]?.activeSpace);
	}, [cookies, projects]);
	
	const getTenantsPerProjectsQueries = ( accountId, projects,  staleTime ) => {
		return projects?.map((project) => {
			return {
				queryKey: ['get-tenant-by-account-id-and-project-id', project.id],
				query: getTenantByAccountIdAndProjectId(accountId, project.id, {}, 'tenantId,productId,projectId,subscriptionStatus'),
				staleTime: staleTime,
				enabled: accountId !== undefined && projects?.length>0
			};
		});
	};

	const queries = getTenantsPerProjectsQueries(accountId, Object.values(projects), staleTime);
	const tenantsPerProjectsQueries = useQueriesWithAuthorization(queries);
	let tenantsPerProjects = null;

	tenantsPerProjects = tenantsPerProjectsQueries.reduce((acc, query) => {
		query.data?.forEach(entry => {
			if (!acc[entry.projectId]) {
				acc[entry.projectId] = [];
			}
			acc[entry.projectId].push(entry.productId);
		});

		return acc;
	}, {});

	const checkLoadError = (queries) => {

		if (!queries.every(key => !key.isLoading)) return true;
		if (queries.length > 0 && queries.every(key => key.isError)) {
			return true;
		}

		return false;
	};

	useDeepCompareEffect(() => {

		tenantsPerProjectsQueries.length && tenantsPerProjects !== null && projects?.length && setTenantsPerProjectsPromise({
			data: tenantsPerProjects,
			isLoading: checkLoadError(tenantsPerProjectsQueries),
			isError: checkLoadError(tenantsPerProjectsQueries),
		});
		// eslint-disable-next-line
	}, [accountId, projects, tenantsPerProjects ]);

	// set sidebar component
	const sideBarComponentList = new Map();

	sideBarComponentList.set("users", <AccountUsers/>);

	sideBarComponentList.set("applications", <Applications/>);

	sideBarComponentList.set("audit", <Audit/>);

	const defaultComponent = sideBarComponentList.get("users");

	useEffect(() => {
		if (urlPath.includes("/account/users"))
			setDefaultComponent(sideBarComponentList.get("users"));
		else if (urlPath.includes("/account/applications"))
			setDefaultComponent(sideBarComponentList.get("applications"));
		else if (urlPath.includes("/account/audit"))
			setDefaultComponent(sideBarComponentList.get("audit"));
		else
			setDefaultComponent(defaultComponent);
		// eslint-disable-next-line
    }, []);

	return !redirectTo ? (
		<div className={styles.rootContainer}>
			<div className={styles.navBar}>
				<NavBar logo={<SynamediaLogoIcon/>}/>
			</div>
			{children}
		</div>
	) : assignRedirect();
}
