import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getAudit = (accountId, startTime, endTime, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/audit?startTime=${startTime}&endTime=${endTime}`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

export { getAudit };