import { Link } from "react-router-dom";

export default function AccessDenied() {

	return (<div style={{
		backgroundColor: "black", color: "white",
		display: 'flex',
		justifyContent: 'center',
	}}>
		<div className="Access Denied">
			<h1 className="Access Denied-animate-top w3-center"><code>Access Denied</code></h1>
			<h3 className="Access Denied-animate-right">You dont have permission to view this site.</h3>
			<h3 className="Access Denied-animate-zoom">🚫🚫🚫🚫</h3>
			<Link to="/recover-account" style={{ display: 'flex', color: "white" }}><h6
				className="Access Denied recover ">return to recover account </h6></Link>
		</div>
	</div>);

}
