import Joi from 'joi';
import { schema as auth0Schema } from './auth0Schema';
import { schema as clarissaSchema } from './clarissaSchema';

const schema = Joi.object({
	apiEndpoint: Joi.string().trim().uri().required(),
	auth0: auth0Schema,
	clarissa: clarissaSchema,
	loginOrgId: Joi.string().trim().allow(''),
	developerPortalEndpoint: Joi.string().trim().uri(),
	domain: Joi.string().trim().required(),
	cmsEnvType: Joi.string().trim().required()
});

const validate = (newConfig) => schema.validate(newConfig, { abortEarly: false, stripUnknown: true });

export {
	schema,
	validate
};
