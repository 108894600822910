import React from 'react';
import EmptyInvitees from '../../../../../assets/icons/EmptyInvitees';
import { useUserInfo } from '../../../../../auth/UserInfoProvider';
import { Button, EmptyState } from '@ip-synamedia/common-ui-library';
import style from './css/NoInvitees.module.css';
import { useUsersStore } from '../../../../../state-management';
import shallow from 'zustand/shallow';
import getMessageFromError from "../../../../../utils/API-calls";

export default function NoInvitees({ inviteesIsError, inviteesError }) {
	const [setIsUserInviteActive] = useUsersStore(state => [state.setIsUserInviteActive], shallow);
	const { scope } = useUserInfo();
	const hasInvitePermission = scope?.includes('account:user:create') || scope?.includes('account-admin:user:create');

	return (
		<div className={style.rootContainer}>
			<EmptyState
				data-testid="emptyState_container"
				id="ActiveUsers_EmptyState"
				showImage
				image={<EmptyInvitees id={'NoInvitees_icon'} className={style.icon}/>}
			>
				<div className={style.col}>
					<div className={style.colContent}>
					
						{!inviteesIsError ? <div className={style.textContainer}>
							<div className={style.textUp} id={'NoInvitees_text_up'}>There are no pending invitees.</div>
							<div className={style.text} id={'NoInvitees_text_bottom'}>Invite new users who need access to
                                your products.
							</div>
						</div> :
							<div className={style.errorMessage} id={'NoInvitees_errorMessage'}>
								{getMessageFromError(inviteesError)}
							</div>}
					</div>
					{!inviteesIsError && <div className={style.button}>
						<Button disabled={!hasInvitePermission}
							onClick={() => hasInvitePermission && setIsUserInviteActive(true)}>Invite User</Button>
					</div>}
				</div>
			</EmptyState>
		</div>
	);
}
