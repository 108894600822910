export default function userInfoCompare(obj1, obj2) {
	if(Object.keys(obj2).length !== Object.keys(obj1).length) return {};

	const accountRoleCmp = obj1?.accountRolesIds?.size === obj2?.accountRolesIds?.size &&
		[...obj1.accountRolesIds].every((role) =>  obj2.accountRolesIds.has(role));

	const productAdminRolesCmp = obj1?.productAdminRolesIds?.size === obj2?.productAdminRolesIds?.size &&
		[...obj1.productAdminRolesIds].every((role) => obj2.productAdminRolesIds.has(role));

	let productRolesByProjectIdCmp = { lenCmp : obj1?.productRolesByProjectId?.length === obj2?.productRolesByProjectId?.length };

	productRolesByProjectIdCmp.lenCmp && Object.entries(obj1.productRolesByProjectId).forEach((projectData) => {

		const [curProjectId, data] = projectData;

		productRolesByProjectIdCmp[curProjectId] = (data.length === obj2.productRolesByProjectId[curProjectId].length) && [...obj2.productRolesByProjectId[curProjectId]].every(element => {

			return data.find(role => role.id === element.id);
		});
	});
	const fieldsCmp = obj1.jobTitle === obj2.jobTitle && obj1.userName === obj2.userName;

	return { accountRoleCmp,
		productAdminRolesCmp,
		productRolesByProjectIdCmp,
		fieldsCmp,
		finalCmp:  accountRoleCmp && productAdminRolesCmp && fieldsCmp && Object.values(productRolesByProjectIdCmp).every(role => role)
	};

}