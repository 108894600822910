import * as React from "react";

const InviteUsersDefaultSVG = (props) => (
	<svg
		width={256}
		height={155}
		viewBox="0 0 256 155"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7_3425)">
			<path
				d="M249.277 154.377C272.73 112.892 227.746 91.6457 221.956 135.453C221.663 129.8 221.352 124.055 219.474 118.715C216.622 107.636 201.125 99.6082 193.723 110.823C189.81 117.772 194.999 127.52 190.499 134.104C187.661 117.532 163.599 121.42 166.133 138.048C168.035 146.036 176.475 150.685 183.212 154.299L249.277 154.377Z"
				fill="#F2F2F2"
			/>
			<path
				d="M169.579 128.01C181.485 131.873 196.362 138.38 197.569 152.603C197.604 153.166 198.486 153.17 198.452 152.602C198.143 147.58 195.696 142.984 192.307 139.35C188.591 135.365 183.776 132.643 178.804 130.531C177.833 130.307 167.715 125.52 169.579 128.01Z"
				fill="white"
			/>
			<path
				d="M199.657 107.207C207.896 120.965 211.817 136.878 210.911 152.889C210.878 153.456 211.76 153.454 211.793 152.889C212.495 140.305 210.254 127.73 205.248 116.164C204.685 115.128 200.149 104.227 199.657 107.207V107.207Z"
				fill="white"
			/>
			<path
				d="M242.834 112.274C237.026 125.333 233.43 139.267 232.195 153.505C232.146 154.071 233.029 154.067 233.078 153.505C234.038 142.526 236.411 131.717 240.138 121.344C240.127 120.189 245.212 110.812 242.834 112.274V112.274Z"
				fill="white"
			/>
			<path
				d="M140.649 153.664C147.363 141.788 134.486 135.706 132.828 148.247C132.744 146.628 132.655 144.984 132.118 143.455C131.301 140.284 126.865 137.986 124.746 141.196C123.626 143.185 125.111 145.975 123.823 147.86C123.011 143.116 116.123 144.229 116.848 148.989C117.393 151.276 119.809 152.607 121.737 153.641L140.649 153.664Z"
				fill="#F2F2F2"
			/>
			<path
				d="M117.835 146.116C121.243 147.222 125.502 149.084 125.847 153.156C125.857 153.317 126.11 153.318 126.1 153.156C125.97 151.726 125.348 150.385 124.341 149.362C123.243 148.26 121.926 147.4 120.475 146.838C120.197 146.773 117.301 145.403 117.835 146.116Z"
				fill="white"
			/>
			<path
				d="M126.445 140.161C128.803 144.099 129.926 148.655 129.666 153.238C129.657 153.4 129.909 153.4 129.919 153.238C130.12 149.635 129.478 146.036 128.045 142.725C127.884 142.428 126.586 139.308 126.445 140.161V140.161Z"
				fill="white"
			/>
			<path
				d="M138.805 141.611C137.142 145.35 136.113 149.338 135.759 153.414C135.745 153.576 135.998 153.575 136.012 153.414C136.287 150.271 136.966 147.177 138.033 144.208C138.03 143.877 139.485 141.193 138.805 141.611V141.611Z"
				fill="white"
			/>
			<path
				d="M97.6156 6.53062H0.343717C0.252557 6.53062 0.165131 6.49441 0.100672 6.42995C0.0362127 6.36549 0 6.27806 0 6.1869C0 6.09574 0.0362127 6.00832 0.100672 5.94386C0.165131 5.8794 0.252557 5.84319 0.343717 5.84319H97.6156C97.7067 5.84319 97.7942 5.8794 97.8586 5.94386C97.9231 6.00832 97.9593 6.09574 97.9593 6.1869C97.9593 6.27806 97.9231 6.36549 97.8586 6.42995C97.7942 6.49441 97.7067 6.53062 97.6156 6.53062Z"
				fill="#3F3D56"
			/>
			<path
				d="M77.611 11.6864C80.8381 11.6864 83.4542 9.07029 83.4542 5.84319C83.4542 2.61608 80.8381 0 77.611 0C74.3839 0 71.7678 2.61608 71.7678 5.84319C71.7678 9.07029 74.3839 11.6864 77.611 11.6864Z"
				fill="#004ECC"
			/>
			<path
				d="M97.6156 28.5285H0.343717C0.252557 28.5285 0.165131 28.4923 0.100672 28.4278C0.0362127 28.3634 0 28.2759 0 28.1848C0 28.0936 0.0362127 28.0062 0.100672 27.9417C0.165131 27.8773 0.252557 27.8411 0.343717 27.8411H97.6156C97.7067 27.8411 97.7942 27.8773 97.8586 27.9417C97.9231 28.0062 97.9593 28.0936 97.9593 28.1848C97.9593 28.2759 97.9231 28.3634 97.8586 28.4278C97.7942 28.4923 97.7067 28.5285 97.6156 28.5285Z"
				fill="#3F3D56"
			/>
			<path
				d="M23.3038 33.6843C26.5309 33.6843 29.1469 31.0682 29.1469 27.8411C29.1469 24.614 26.5309 21.9979 23.3038 21.9979C20.0767 21.9979 17.4606 24.614 17.4606 27.8411C17.4606 31.0682 20.0767 33.6843 23.3038 33.6843Z"
				fill="#004ECC"
			/>
			<path
				d="M97.6156 50.5264H0.343717C0.252557 50.5264 0.165131 50.4902 0.100672 50.4257C0.0362127 50.3612 0 50.2738 0 50.1827C0 50.0915 0.0362127 50.0041 0.100672 49.9396C0.165131 49.8752 0.252557 49.8389 0.343717 49.8389H97.6156C97.7067 49.8389 97.7942 49.8752 97.8586 49.9396C97.9231 50.0041 97.9593 50.0915 97.9593 50.1827C97.9593 50.2738 97.9231 50.3612 97.8586 50.4257C97.7942 50.4902 97.7067 50.5264 97.6156 50.5264Z"
				fill="#3F3D56"
			/>
			<path
				d="M46.3327 55.6821C49.5599 55.6821 52.1759 53.066 52.1759 49.8389C52.1759 46.6118 49.5599 43.9958 46.3327 43.9958C43.1056 43.9958 40.4896 46.6118 40.4896 49.8389C40.4896 53.066 43.1056 55.6821 46.3327 55.6821Z"
				fill="#004ECC"
			/>
			<path
				d="M142.149 54.0208C142.27 54.1845 142.377 54.3576 142.471 54.5382L160.587 56.5753L163.222 53.0154L169.114 56.2896L163.927 64.9366L141.732 59.1118C141.168 59.6547 140.451 60.0129 139.678 60.1386C138.904 60.2643 138.111 60.1516 137.403 59.8156C136.696 59.4795 136.107 58.9359 135.716 58.2571C135.325 57.5783 135.149 56.7965 135.213 56.0157C135.277 55.2348 135.577 54.492 136.074 53.8858C136.57 53.2797 137.239 52.8391 137.992 52.6225C138.745 52.4059 139.546 52.4237 140.289 52.6735C141.031 52.9233 141.68 53.3932 142.149 54.0208V54.0208Z"
				fill="#FFB8B8"
			/>
			<path
				d="M167.826 59.3864C167.68 59.3223 167.544 59.2375 167.423 59.1346L160.142 53.0011C159.967 52.8537 159.824 52.6716 159.723 52.4663C159.622 52.2609 159.565 52.0369 159.555 51.8083C159.545 51.5797 159.582 51.3515 159.665 51.1381C159.748 50.9248 159.874 50.7309 160.035 50.5688L166.643 43.9344C166.92 43.6534 167.289 43.4816 167.683 43.4503C168.076 43.4189 168.468 43.5301 168.786 43.7636L173.783 47.3919C174.095 47.6185 174.318 47.9469 174.413 48.3203C174.509 48.6937 174.471 49.0888 174.306 49.4374L169.984 58.5767C169.8 58.9655 169.472 59.2674 169.069 59.4186C168.666 59.5697 168.221 59.5582 167.826 59.3864H167.826Z"
				fill="#004ECC"
			/>
			<path
				d="M196.225 146.134L191.876 147.317L185.243 131.107L191.661 129.361L196.225 146.134Z"
				fill="#FFB8B8"
			/>
			<path
				d="M188.089 147.012L196.819 144.637L198.256 149.917L184.589 153.635L184.495 153.292C184.139 151.983 184.318 150.586 184.992 149.408C185.666 148.23 186.78 147.368 188.089 147.012V147.012Z"
				fill="#2F2E41"
			/>
			<path
				d="M159.05 149.452L154.543 149.452L152.399 132.069L159.051 132.069L159.05 149.452Z"
				fill="#FFB8B8"
			/>
			<path
				d="M150.969 148.164H160.016V153.636H145.852V153.281C145.852 151.924 146.391 150.622 147.351 149.663C148.31 148.703 149.612 148.164 150.969 148.164V148.164Z"
				fill="#2F2E41"
			/>
			<path
				d="M172.223 32.0367C175.749 28.5108 175.749 22.7942 172.223 19.2684C168.697 15.7425 162.981 15.7425 159.455 19.2684C155.929 22.7942 155.929 28.5108 159.455 32.0367C162.981 35.5625 168.697 35.5625 172.223 32.0367Z"
				fill="#FFB8B8"
			/>
			<path
				d="M158.774 137.058H152.615C152.194 137.057 151.789 136.896 151.482 136.608C151.176 136.32 150.99 135.926 150.963 135.506C149.128 106.333 150.201 85.4062 154.341 69.6494C154.359 69.5805 154.381 69.5128 154.408 69.4466L154.738 68.622C154.86 68.3144 155.071 68.0506 155.345 67.8651C155.619 67.6796 155.943 67.581 156.274 67.5821H168.938C169.177 67.5819 169.413 67.6334 169.629 67.7331C169.846 67.8328 170.039 67.9783 170.194 68.1597L171.914 70.1667C171.948 70.2058 171.979 70.2457 172.009 70.2877C179.766 81.1046 186.717 102.252 194.768 130.676C194.887 131.089 194.84 131.532 194.637 131.911C194.434 132.29 194.091 132.574 193.681 132.704L187.062 134.822C186.682 134.941 186.272 134.921 185.906 134.763C185.54 134.606 185.243 134.323 185.068 133.965L167.893 98.3417C167.773 98.0928 167.576 97.8891 167.331 97.7609C167.086 97.6327 166.806 97.5867 166.533 97.6299C166.26 97.673 166.008 97.8029 165.815 98.0004C165.621 98.1978 165.497 98.4523 165.459 98.7262L160.413 135.628C160.357 136.023 160.161 136.386 159.86 136.649C159.559 136.911 159.173 137.057 158.774 137.058V137.058Z"
				fill="#2F2E41"
			/>
			<path
				d="M171.22 70.3135C171.203 70.3135 171.185 70.3132 171.168 70.3128L155.785 69.8271C155.389 69.816 155.011 69.6631 154.719 69.3963C154.427 69.1295 154.24 68.7665 154.194 68.3736C152.243 52.5884 157.592 48.3596 159.982 47.2744C160.181 47.1843 160.354 47.0441 160.484 46.8674C160.613 46.6908 160.695 46.4836 160.721 46.2661L160.928 44.6115C160.963 44.3193 161.076 44.0419 161.255 43.8081C165.002 38.954 170.119 37.3996 172.835 36.9052C173.055 36.8656 173.282 36.8712 173.5 36.9217C173.718 36.9722 173.923 37.0664 174.104 37.1988C174.284 37.3311 174.436 37.4988 174.55 37.6916C174.664 37.8844 174.737 38.0983 174.765 38.3204L175.075 40.6961C175.107 40.9227 175.197 41.1371 175.338 41.3178C183.152 51.5341 174.446 66.6185 172.622 69.5385C172.473 69.7756 172.266 69.971 172.021 70.1066C171.776 70.2421 171.5 70.3133 171.22 70.3135Z"
				fill="#004ECC"
			/>
			<path
				d="M169.785 92.75C170.258 92.4185 170.652 91.9874 170.94 91.487C171.228 90.9867 171.403 90.4293 171.452 89.8541C171.502 89.2789 171.424 88.6999 171.225 88.1578C171.027 87.6158 170.712 87.1239 170.302 86.7168L173.16 52.2099L164.652 53.3803L164.49 86.9069C163.852 87.6399 163.505 88.5816 163.514 89.5535C163.524 90.5255 163.89 91.4601 164.542 92.1803C165.195 92.9006 166.089 93.3564 167.056 93.4614C168.022 93.5663 168.993 93.3132 169.785 92.75H169.785Z"
				fill="#FFB8B8"
			/>
			<path
				d="M173.716 57.1787C173.556 57.1787 173.398 57.1558 173.245 57.1105L164.112 54.4243C163.892 54.3598 163.688 54.2504 163.513 54.103C163.338 53.9557 163.195 53.7736 163.094 53.5684C162.993 53.3631 162.936 53.1391 162.926 52.9105C162.916 52.6819 162.953 52.4536 163.036 52.2402L166.416 43.5084C166.557 43.1396 166.826 42.8338 167.173 42.6467C167.521 42.4597 167.924 42.4039 168.31 42.4896L174.344 43.8013C174.72 43.8834 175.057 44.0943 175.294 44.3977C175.532 44.7012 175.656 45.0781 175.646 45.4634L175.365 55.5695C175.353 55.9994 175.174 56.4079 174.866 56.7082C174.558 57.0086 174.146 57.1774 173.716 57.1788V57.1787Z"
				fill="#004ECC"
			/>
			<path
				d="M249.888 154.329H118.931C118.84 154.329 118.753 154.293 118.688 154.228C118.624 154.164 118.588 154.076 118.588 153.985C118.588 153.894 118.624 153.807 118.688 153.742C118.753 153.678 118.84 153.641 118.931 153.641H249.888C249.979 153.641 250.066 153.678 250.131 153.742C250.195 153.807 250.231 153.894 250.231 153.985C250.231 154.076 250.195 154.164 250.131 154.228C250.066 154.293 249.979 154.329 249.888 154.329Z"
				fill="#3F3D56"
			/>
			<path
				d="M175.773 20.0374C175.102 18.0046 174.607 15.918 174.293 13.8006C174.194 12.5678 174.51 11.2414 175.408 10.3908C177.088 8.79904 179.942 9.55719 181.699 11.0637C184.239 13.2408 185.505 16.5867 185.984 19.8972C186.462 23.2076 186.252 26.574 186.367 29.9169C186.482 33.2598 186.96 36.697 188.665 39.5752C190.369 42.4533 193.531 44.6625 196.867 44.4176C198.214 44.3448 199.492 43.803 200.481 42.8861C200.966 42.4244 201.334 41.853 201.553 41.2199C201.772 40.5868 201.836 39.9104 201.74 39.2475C203.445 42.3006 202.58 46.4384 200.037 48.8385C197.494 51.2385 193.582 51.917 190.239 50.8923C186.895 49.8676 184.144 47.2982 182.5 44.2117C180.856 41.1251 180.254 37.5596 180.341 34.0639C180.408 31.3794 180.865 28.6729 180.411 26.0262C179.957 23.3795 178.301 20.6921 175.689 20.0688L175.773 20.0374Z"
				fill="#2F2E41"
			/>
			<path
				d="M164.043 23.5172C163.597 25.0048 161.894 25.9221 160.362 25.6705C158.829 25.4189 157.569 24.157 157.104 22.6756C156.683 21.1625 156.855 19.5459 157.585 18.1555C157.988 17.3222 158.551 16.5764 159.242 15.9608C159.933 15.3452 160.739 14.872 161.613 14.5682C162.492 14.2769 163.428 14.2011 164.343 14.3472C165.257 14.4933 166.123 14.8571 166.868 15.4079C166.947 15.0737 167.114 14.7667 167.352 14.5184C167.589 14.2702 167.888 14.0897 168.219 13.9955C168.884 13.8162 169.586 13.8266 170.245 14.0254C172.146 14.567 173.793 15.7651 174.893 17.4067C175.98 19.0431 176.698 20.8957 177 22.8367C177.518 25.7475 177.327 28.9282 175.692 31.3917C174.057 33.8553 170.713 35.3099 167.999 34.1354C167.83 33.2705 168.231 32.3996 168.695 31.6501C169.158 30.9006 169.708 30.1705 169.886 29.3073C170.064 28.4442 169.723 27.3884 168.888 27.1061C168.176 26.8653 167.416 27.2555 166.666 27.3026C166.24 27.3293 165.814 27.2436 165.431 27.0539C165.049 26.8642 164.723 26.5772 164.486 26.2218C164.25 25.8664 164.111 25.455 164.083 25.0289C164.056 24.6028 164.141 24.1769 164.33 23.7941L164.043 23.5172Z"
				fill="#2F2E41"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7_3425">
				<rect width={256} height={154.377} fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default InviteUsersDefaultSVG;
