import React, { useMemo } from "react";
// import AppTooltip from "../../../../../../components/AppTooltip";
import AppButton from "../../../../../../components/AppButton";
import Loader from "../../../../../../components/Loader";
import emptyContentBackground from "../../../../../../assets/icons/emptyContent/emptyContentBackground.png";
import emptyUsersIcon from "../../../../../../assets/icons/emptyContent/emptyUsersIcon.png";
import { useQueriesWithAuthorization, useQueryWithAuthorization } from "../../../../../../custom-hooks";
import { getUserRolesByAccount, getUserRolesByProject } from "../../../../../../external-apis";
import getMessageFromError, { checkLoadError } from "../../../../../../utils/API-calls";
import styles from "./Details.module.css";
// import EditIconSVG from "../../../../../../assets/icons/EditIconSVG";
import { useUserInfo } from "../../../../../../auth/UserInfoProvider";
import { useMainContainerStore } from "../../../../../../state-management";
import shallow from "zustand/shallow";
import { Tag, TrashIcon } from "@ip-synamedia/common-ui-library";
import useStore from "../../../../../../state-management/mainContainerStore";

const sortingOrder = ['userName', 'email', 'userRole', 'joined', 'lastLogin'];
const sortData = (a, b) => {
	return sortingOrder.indexOf(a) - sortingOrder.indexOf(b);
};
const mapFields = (field) => {
	switch (field) {
	case 'userName':
		return "Name";
	case 'userRole':
		return "Job Title";
	case 'email':
		return "Email";
	case 'lastLogin':
		return "Last Login";
	case "joined":
		return "Joined On";
	default:
		return '';
	}
};

const getQueries = (accountId, projects, userId, staleTime) => {
	return projects.map((project) => {
		return {
			queryKey: ['get-user-roles-by-project', project.id, userId],
			query: getUserRolesByProject(accountId, project.id, userId),
			staleTime: staleTime,
			enabled: userId !== undefined
		};
	});
};

function GetAdminComponentFromData(roleOfAccount, styles) {
	const filteredRoles = roleOfAccount ? roleOfAccount.filter(q => q?.isProductAdmin) : [];

	return filteredRoles && filteredRoles.length > 0 && <div className={styles.usersRolesContainer}>
		<div className={styles.accountRolesTitle} id={'userDrawer_AdminRoles_title'}>Product Administration</div>
		<div className={styles.accountRolesContainer}>
			<div className={styles.rolesLineContainer}>
				{filteredRoles.map((role, index) => (
					<Tag key={"user-admin-role" + index}>
						{role.displayName}
					</Tag>))
				}
			</div>
		</div>
	</div>;
}

function GetProjectComponentFromData(productIdToDisplayName, rolesQueries, projectRoles, styles, tenantsPerProjects, projects, isExistRole) {

	let filterProjectRoles = {};

	Object.keys(projectRoles).forEach(projectLabel => {
		let project= projects.find(project => project.label === projectLabel );
		let temp=[];

		if (Object.keys(tenantsPerProjects?.data || {} ).includes(project.id)) {
			projectRoles[projectLabel].forEach(role => {

				if (tenantsPerProjects.data[project.id].includes (role.productId)) {
					temp.push(role);
				}
			});
			filterProjectRoles[project.label] = temp;
		}
	});

	return isExistRole ? <div className={styles.projectRolesContainer}>
		<div className={styles.spaceRolesTittle}>Roles by Space</div>
		<div className={styles.projectRolesInfoContainer}>
			{Object.values(filterProjectRoles).length > 0 && Object.values(projectRoles).length > 0 && Object.entries(filterProjectRoles).map((data, index) => {
				const roles = data[1];
				const projectLabel = data[0];
				const filteredRoles = (roles ?? []);

				const sortedRoles = {};

				filteredRoles.forEach((role) => {
					if (!sortedRoles[role.productId]) {
						sortedRoles[role.productId] = [];
					}
					sortedRoles[role.productId]?.push(role);
				});

				return (Object.keys(sortedRoles).length > 0) ?
					<div key={'project-role' + index} id={"userDrawer_details_projectRole" + index}
						className={styles.projectContainer}>
						<div className={styles.projectRoleTitle}>{projectLabel}</div>
						<div className={styles.projectRoleWrapper}>
							{Object.entries(sortedRoles).map((data, index) => {
								const productDisplayName = productIdToDisplayName[data[0]];
								const roles = data[1];

								return <div key={'project-roles' + index} className={styles.projectRole}>
									<div
										className={styles.productTitle}>{productDisplayName}</div>
									<div className={styles.projectRolesLineContainer}>
										{roles.map((role, index) => (<Tag key={index}>
											{role.displayName}
										</Tag>))}
									</div>
								</div>;
							})}
						</div>
					</div> : <div/>;
			})}
		</div>
	</div> : undefined;
}

export function Details({ curData, accountId, initiateDelete }) {
	const { scope } = useUserInfo();
	const tenantsPerProjects  = useStore(state => state.tenantsPerProjectsPromise, shallow);

	const staleTime = 60000;
	const [{ data: projects = [] }] = useMainContainerStore(state => [state.projectsPromise], shallow);

	const [{ data: products }] = useMainContainerStore(state => [state.productsPromise], shallow);
	const productIdToDisplayName = products.reduce((idMap, product) => {
		idMap[product.id] = product.displayName;

		return idMap;
	}, {});
	const queries = getQueries(accountId, Object.values(projects), curData?.id, staleTime);
	const rolesQueries = useQueriesWithAuthorization(queries);

	const hasUserDeletePermission = scope?.includes('account:user:delete') || scope?.includes('account-admin:user:delete');
	// const hasUserEditPermission = scope?.includes('account:user:update') || scope?.includes('account-admin:user:update');

	const {
		data: roleOfAccount = [],
		isError: roleOfAccountIsError,
		isLoading: roleOfAccountIsLoading,
		error: roleOfAccountError
	} = useQueryWithAuthorization(['user-roles-of-account', accountId, curData?.id], getUserRolesByAccount(accountId, curData?.id), {
		enabled: accountId != null && curData?.id !== undefined,
		staleTime: staleTime

	});

	function EmptyUserRolesComponent() {
		return <div className={styles.emptyUsersComponentContainer}>
			<div className={styles.emptyIconContainer}>
				<img alt={''} src={emptyContentBackground} className={styles.emptyContentBackgroundIcon}/>
				<img alt={''} src={emptyUsersIcon} className={styles.emptyContentIcon}/>
			</div>
			<div className={styles.emptyUsersText}>{'This user has no role yet'}</div>
		</div>;
	}

	const generalDetails = useMemo(() => {
		return <div className={styles.generalDetailsContainer} id={'userDrawer_generalDetails_container'}>
			<div className={styles.generalDetailsHeader}>
				<div className={styles.generalDetailsTitle} id={'userDrawer_generalDetails_title'}>General Details</div>
				<div className={styles.actions}>
					<div id={'userDrawer_generalDetails_Remove_Btn'}>
						<AppButton variant={'text'} hidden={!hasUserDeletePermission}>
							<div className={styles.buttonContent} onClick={() => {
								initiateDelete(curData);
							}}>
								<TrashIcon/>
								<div className="bodyShort1">Remove</div>
							</div>
						</AppButton>
					</div>
					{/*<AppTooltip title={'coming soon'}>*/}
					{/*	<div id={'userDrawer_generalDetails_Edit_Btn'}>*/}
					{/*		<AppButton variant={'text'} hidden={!hasUserEditPermission}>*/}
					{/*			<div className={styles.buttonContent}>*/}
					{/*				<EditIconSVG/>*/}
					{/*				<div className="bodyShort1">Edit</div>*/}
					{/*			</div>*/}
					{/*		</AppButton>*/}
					{/*	</div>*/}
					{/*</AppTooltip>*/}
				</div>
			</div>
			<div className={styles.generalDetailsContentContainer}>
				{curData && Object.entries(curData).filter(x => x[0] !== 'id').sort(([a,], [b,]) => sortData(a, b)).map((field, index) => {
					const key = mapFields(field[0]);
					const value = field[1];

					return <div key={'general-details' + key} className={styles.generalDetailsFieldContainer}>
						<div className={styles.generalDetailsKey} id={'userDrawer_generalDetails_key' + index}>
							{key}
						</div>
						<div className={styles.generalDetailsValue} id={'userDrawer_generalDetails_value' + index}>
							{value}
						</div>
					</div>;
				})}
			</div>
		</div>;
		//eslint-disable-next-line
    }, [curData, styles]);
	const accountRoles = useMemo(() => {

		if (roleOfAccountIsLoading) return <div
			style={{ display: "grid", placeItems: "center", width: "100%", height: "4rem" }}><Loader width={30}
				height={30}
				type={'Rings'}/>
		</div>;

		if (roleOfAccountIsError) {
			const errorMessage = getMessageFromError(roleOfAccountError);

			return <div id={'userDrawer_account_errorCase'} className={styles.error}>{errorMessage}</div>;
		}

		const filteredRoles = roleOfAccount.filter(q => !q?.isProductAdmin);

		return filteredRoles.length > 0 ? <div className={styles.usersRolesContainer}>
			<div className={styles.accountRolesTitle} id={'userDrawer_AccountRoles_title'}>Account Roles</div>
			<div className={styles.accountRolesContainer}>
				<div className={styles.rolesLineContainer}>
					{filteredRoles.map((role, index) => (<Tag key={"user-role" + index}>
						{role.displayName}
					</Tag>))
					}
				</div>
			</div>
		</div> : undefined;
	}, [roleOfAccountIsError, roleOfAccountIsLoading, roleOfAccount, roleOfAccountError]);

	const productAdministration = useMemo(() => {
		if (roleOfAccountIsLoading) return <div
			style={{ display: "grid", placeItems: "center", width: "100%", height: "4rem" }}><Loader width={30}
				height={30}
				type={'Rings'}/>
		</div>;

		if (roleOfAccountIsError) {
			const errorMessage = getMessageFromError(roleOfAccountError);

			return <div id={'userDrawer_productAdmin_errorCase'} className={styles.error}>{errorMessage}</div>;
		}

		return roleOfAccount && GetAdminComponentFromData(roleOfAccount, styles);
	}, [roleOfAccount, roleOfAccountError, roleOfAccountIsError, roleOfAccountIsLoading]);

	const projectRoles = useMemo(() => {
		const loadingError = checkLoadError(rolesQueries, false);

		if (loadingError) {
			return <div id={'userDrawer_projects_errorCase'}
				style={{ display: "grid", placeItems: "center", width: "100%", height: "4rem" }}
				className={styles.error}>{loadingError}</div>;
		}

		let projectRoles = {};

		rolesQueries.map(q => q.data).forEach((arrayRole, index) => projectRoles[projects[index]?.label] = arrayRole);
		let isExistRole = false;

		Object.keys(projectRoles).length > 0 && Object.values(projectRoles).forEach(data => {
			isExistRole = isExistRole || data?.length;
		});

		return GetProjectComponentFromData(productIdToDisplayName, rolesQueries, projectRoles, styles, tenantsPerProjects, projects, isExistRole);
	}, [productIdToDisplayName, rolesQueries, projects, tenantsPerProjects]);

	return <div>
		{generalDetails}
		{
			(accountRoles || productAdministration || projectRoles) ? (<div className={styles.assignedRolesContainer}>
				<div className={styles.title}>Assigned Roles</div>
				<div className={styles.spaces}>
					{accountRoles}
					{productAdministration}
					{projectRoles}
				</div>
			</div>) : EmptyUserRolesComponent({ drawer: true })
		}
	</div>;
}