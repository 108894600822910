import stylesCard from "../topCard.module.css";
import Loader from "../../../../components/Loader";
import getMessageFromError from "../../../../utils/API-calls";
import TopCardContainer from "../TopCardContainer";
import React from "react";

export default function ProductInstalled({ element, indexOut }) {
	return <TopCardContainer key={'ProductInstalled'} index={indexOut} elementOut={element}>
		<div className={stylesCard.cardDesc}>
			{
				element?.desc.map((elementIn, index) => {
					if (element?.isLoading) return <div key={index + 'ProductInstalled Loader'}
						className={stylesCard.cardLoaderContainer}><Loader/></div>;
					if (element?.isError) return <div key={index + 'ProductInstalled error'}
						className={stylesCard.errorMessage}>{getMessageFromError(element?.error)}</div>;

					return <div key={'ProductsCardValue' + index} className={stylesCard.cardAdditionalInfo}>
						<div className="SemiBoldShort1" style={{ color: "#52698E" }}>{elementIn?.value["one"]}</div>
						<div className={stylesCard.vSeparator}/>
						<div className="SemiBoldShort1" style={{ color: "#52698E" }}>{elementIn?.value["two"]}</div>
					</div>;

				})
			}
		</div>
	</TopCardContainer>;
}