import './fontawaeome';
import { validate as validateSchema } from './schemas/rootSchema';

let config;

const set = (newConfig) => {
	// Schema validation
	const { value: validConfig, error } = validateSchema(newConfig);

	if (error) {
		const errors = error.details.map(detail => detail.message).join(', ');

		console.error('Config schema validation error', errors);

		return;
	}

	config = validConfig;

	return true;
};

const getAuth0Config = () => {
	return { ...config.auth0 };
};

const getProductConfig = (productId) => {
	return config && { ...config[productId] };
};

const getApiEndpoint = () => {
	return config.apiEndpoint;
};

const getDeveloperPortalEndpoint = () => {
	return config.developerPortalEndpoint;
};

const getDomain = () => {
	return config.domain;
};

const getCMSEnvironmentType = () => {
	return config.cmsEnvType;
};

export {
	set as setConfig,
	getAuth0Config,
	getProductConfig,
	getApiEndpoint,
	getDeveloperPortalEndpoint,
	getDomain,
	getCMSEnvironmentType
};
