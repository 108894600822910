import * as React from "react";

const AppIconSVG = (props) => (
	<svg
		width={16}
		height={16}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 2.28571C0 1.03571 0.875 0 2 0H14C15.0938 0 16 1.03571 16 2.28571V13.7143C16 15 15.0938 16 14 16H2C0.875 16 0 15 0 13.7143V2.28571ZM11.25 4.85714H14.5V2.28571C14.5 2 14.25 1.71429 14 1.71429H11.25V4.85714ZM9.75 1.71429H6.25V4.85714H9.75V1.71429ZM4.75 1.71429H2C1.71875 1.71429 1.5 2 1.5 2.28571V4.85714H4.75V1.71429ZM1.5 6.57143V9.42857H4.75V6.57143H1.5ZM1.5 11.1429V13.7143C1.5 14.0357 1.71875 14.2857 2 14.2857H4.75V11.1429H1.5ZM6.25 14.2857H9.75V11.1429H6.25V14.2857ZM11.25 14.2857H14C14.25 14.2857 14.5 14.0357 14.5 13.7143V11.1429H11.25V14.2857ZM14.5 9.42857V6.57143H11.25V9.42857H14.5ZM9.75 9.42857V6.57143H6.25V9.42857H9.75Z"
			fill="#91A3C0"
		/>
	</svg>
);

export default AppIconSVG;
