const CloseIconSvg = (props) => (
	<svg
		width={8}
		height={9}
		viewBox="0 0 8 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.3125 7.28906C7.52344 7.52344 7.52344 7.875 7.3125 8.08594C7.07812 8.32031 6.72656 8.32031 6.51562 8.08594L3.75 5.29688L0.960938 8.08594C0.726562 8.32031 0.375 8.32031 0.164062 8.08594C-0.0703125 7.875 -0.0703125 7.52344 0.164062 7.28906L2.95312 4.5L0.164062 1.71094C-0.0703125 1.47656 -0.0703125 1.125 0.164062 0.914062C0.375 0.679688 0.726562 0.679688 0.9375 0.914062L3.75 3.72656L6.53906 0.9375C6.75 0.703125 7.10156 0.703125 7.3125 0.9375C7.54688 1.14844 7.54688 1.5 7.3125 1.73438L4.52344 4.5L7.3125 7.28906Z"
			fill="currentColor"
		/>
	</svg>
);

export default CloseIconSvg;