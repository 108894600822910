import stylesCard from "../topCard.module.css";
import styles from "./RecentBlogs.module.css";
import copySvg from "../../../../assets/icons/CopySvg.svg";
import TopCardContainer from "../TopCardContainer";
import React from "react";
import { Tooltip } from "@ip-synamedia/common-ui-library";

export default function RecentBlogs({ element, indexOut }) {
	return <TopCardContainer key={'RecentBlogs'} index={indexOut} elementOut={element}>
		<div className={stylesCard.cardDesc}>
			{
				element && Object.values(element.desc[0].value).map((elementIn, index) => {

					return (
						<Tooltip key={'RecentBlogs' + index} content={elementIn.label} side="top" type="inline" align="center" >
							<div className={styles.cardAuditInfoContainer} key={'RecentBlogs' + index} id={`RecentBlogs_container${index}`}>
								<a
									style={{ textDecoration:"none", color: "inherit",display:"flex",alignItems:"center" }}
									href={elementIn.url}
									target={"_blank"} rel="noreferrer">
									<div className={styles.cardAuditInfo} key={`${index}_${elementIn.label}`} id={'RecentBlogs_row' + index}>
										<div className={styles.auditInfoText}>
											{elementIn.label}
										</div>
										<img alt={"link"} src={copySvg}/>
									</div>
								</a>

							</div>
						</Tooltip>);

				})
			}
		</div>
	</TopCardContainer>;
}