import React, { useEffect, useState } from 'react';
import styles from "./ProductLauncherPage.module.css";
import { useParams } from 'react-router-dom';
import { useQueryWithAuthorization } from '../../custom-hooks';
import { getTenantUiLink } from '../../external-apis';
import { useUserInfo } from "../../auth/UserInfoProvider";
import cookiesNames from "../../config/cookies";
import { useCookies } from "react-cookie";

const ProductLauncherPage = () => {
	const { orgId } = useUserInfo();
	const accountPreferences = cookiesNames.preferences + "." + orgId;

	const [cookies] = useCookies([accountPreferences]);

	const { tenantId } = useParams();
	const { accountId } = useUserInfo();
	const [textMsg, setTextMsg] = useState('');
	const [isFetch, setIsFetch] = useState(false);
	const selectedSpace = cookies?.[accountPreferences]?.activeSpace?.id;

	const {
		data: tenantUILink,
		isError: tenantInfoIsError,
		isLoading: tenantInfoIsLoading,
		error: tenantInfoError
	} = useQueryWithAuthorization(['get-tenant-by-id', tenantId], getTenantUiLink(accountId, selectedSpace, tenantId), {
		staleTime: 12000,
		enabled: (!!tenantId && !!accountId && !!selectedSpace)
	});

	document.title = 'Synamedia Cloud Portal | Launch Product';

	useEffect(() => {
		if (tenantInfoIsLoading) {
			setTextMsg("Fetching data, Please wait.");
			setIsFetch(true);
		}
		if (tenantInfoIsError || tenantInfoError) {
			setTextMsg("Opps... Something went wong, Please contact the Support.");

		}

		if (tenantUILink?.uiLink && !tenantInfoIsError && !tenantInfoIsLoading && isFetch) {
			window.location.replace(tenantUILink.uiLink);
		}

		if(!tenantUILink?.uiLink && !tenantInfoIsError && !tenantInfoIsLoading && isFetch) {
			setTextMsg("Unable to fetch the required information, Please check back after sometime!");
		}
		// eslint-disable-next-line
	}, [tenantInfoError, tenantInfoIsError, tenantInfoIsLoading, tenantUILink?.uiLink]);

	return (
		<div className={styles.featchLoading}>
			{textMsg}
		</div>
	);

};

export default ProductLauncherPage;