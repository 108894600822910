import React from 'react';
import { InputTags } from '@ip-synamedia/common-ui-library';
import { isValidEmail } from "../../../../../../../utils/validation";
import shallow from 'zustand/shallow';
import { useMainContainerStore } from "../../../../../../../state-management";
import "./InputEmail.module.css";
// fix tooltip z-index issue

const EMAIL_INPUT_PLACEHOLDER = "Enter upto 50 email address separated by commas, spaces, semicolon or line breaks.";

const ACTIVE_USER_TOOLTIP_MESSAGE = "An active user cannot be invited.";
const INVITED_USER_TOOLTIP_MESSAGE = "An invalid user cannot be invited.";
const INVALID_EMAIL_TOOLTIP_MESSAGE = "Invalid Email address";
const ACTIVE_INVITED_INVALID_ERROR_MESSAGE = "The email addresses are currently active or invited in the portal and some are invalid. Please remove it or enter another email address.";
const ACTIVE_INVITED_ERROR_MESSAGE = "The email addresses are currently active or invited in the portal. Please remove it or enter another email address.";
const ACTIVE_ERROR_MESSAGE = "The email addresses are currently active. Please remove it or enter another email address.";
const INVITED_ERROR_MESSAGE = "The email addresses are invited. Please remove it or enter another email address.";
const INVALID_ERROR_MESSAGE = "The email addresses are invalid. Please remove it or enter another email address.";

const InputEmail = ({ id, tags, setTags, emailError, setEmailError, handleEmailChange }) => {

	const [{
		data: users = [],
	}] =  useMainContainerStore(state => [state.usersPromise], shallow);
	const [{
		data: invitees = [],
	}] =  useMainContainerStore(state => [state.inviteesPromise], shallow);

	const handleError = (isReset, errObj = { error: false, errorString: "" }) => {
		if (isReset) setEmailError({ isError: false, errorString: "" });
		else setEmailError({ isError: errObj.error, errorString: errObj.errorString });
	};

	const constructTag = ({ email,tagErrorMessage }) => {
		const tag = {
			id: email,
			label: email,
			type: "removable"
		};

		if(tagErrorMessage) {
			tag['variant'] = "error";
			tag['tooltipMessage'] = tagErrorMessage;
		}

		return tag;
	};

	const handleAddTag = (data, setInput) => {
		const newEmails = data.split(/[\s,;]+/);
		const newTags = [];
		let error = false;
		let errorString = "";
		const tempContentList = [];

		if(newEmails.length) {
			let isActive = false;
			let isInvited = false;
			let isInvalid = false;

			newEmails.forEach(newEmail => {
				const email = newEmail.trim().toLowerCase();

				if(email) {
					if (!tags.some((tag) => tag.label === email) && !tempContentList.includes(email)) {
						if(isValidEmail(email)) {
							let tag = null;

							if(users.find(user => user.email === email)) {
								isActive = true;
								tag = constructTag({ email,tagErrorMessage: ACTIVE_USER_TOOLTIP_MESSAGE });
							}
							else if(invitees.find(user => user.email === email)) {
								isInvited = true;
								tag = constructTag({ email,tagErrorMessage: INVITED_USER_TOOLTIP_MESSAGE });
							} else {
								tag = constructTag({ email,tagErrorMessage: null });
								handleEmailChange(email, false);
							}
							newTags.push(tag);
						} else {
							isInvalid = true;
							const invalidTag = constructTag({ email, tagErrorMessage: INVALID_EMAIL_TOOLTIP_MESSAGE });

							newTags.push(invalidTag);
						}
					}
					tempContentList.push(email);
				}
			});
			setTags([...tags,...newTags]);

			if(isActive && isInvited && isInvalid) {
				error = true;
				errorString = ACTIVE_INVITED_INVALID_ERROR_MESSAGE;
			} else if(isActive && isInvited) {
				error = true;
				errorString = ACTIVE_INVITED_ERROR_MESSAGE;
			} else if(isActive) {
				error = true;
				errorString = ACTIVE_ERROR_MESSAGE;
			}else if(isInvited) {
				error = true;
				errorString = INVITED_ERROR_MESSAGE;
			} else if(isInvalid) {
				error = true;
				errorString = INVALID_ERROR_MESSAGE;
			}

			setInput("");
			if(error) {
				handleError(false,{
					error: error,
					errorString: errorString
				});
			}
		}
	};

	const checkError = (temptags) => {
		if(emailError.isError && temptags.every(tag => tag?.variant !== "error")) handleError(true);
	};

	const handleRemoveTag = ({ id }) => {
		const temptags = tags.filter((tag) => tag.id !== id);

		setTags(temptags);
		handleEmailChange(id, true);
		checkError(temptags);
	};

	return (
		<InputTags
			id={id}
			style={{ width: "100%" }}
			label=""
			labelStyles={{ display: "none" }}
			tags={tags}
			inputHeight="120px"
			inputWidth="100%"
			handleAddTag={handleAddTag}
			handleRemoveTag={handleRemoveTag}
			helperText={emailError.isError ? emailError.errorString : ""}
			error={!!emailError.isError}
			handleError={handleError}
			placeholder={EMAIL_INPUT_PLACEHOLDER}
			resizable={false}
			multiline={true}
		/>
	);
};

export default InputEmail;