import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import styles from './NavBar.module.css';
import SearchIcon from '@mui/icons-material/Search';
import clsx from "clsx";
import { AddUserIcon, EnvelopeIcon } from '@ip-synamedia/common-ui-library';
import { useAuth0 } from "@auth0/auth0-react";
import SpaceSwitcher from './SpaceSwitcher';
import { useAppPanelStore, useDeepLinkStore } from '../../state-management';
import ProductSwitcher from './ProductSwitcher';
import UserProfile from './UserProfile';
import ContactUsDialog from "./contactUs/index";
import NavBarIconButton from '../NavBarIconButton';
import Loader from "../Loader";
import { useUserInfo } from "../../auth/UserInfoProvider";
import HelpDoc from "./helpDoc";

const NavBar = ({ logo }) => {
	const { accountAlias } = useUserInfo();
	const { logout, isAuthenticated, user, isLoading } = useAuth0();
	const navigate = useNavigate();
	const [openContactUs, setOpenContactUs] = useState(false);
	const currentPageIndicator = useAppPanelStore(state => state.currentPageIndicator);
	const setIsInviteUser = useDeepLinkStore(state => state.setIsInviteUser);
	const [productSwitcherOpen, setProductSwitcherOpen] = useState(false);
	const { scope } = useUserInfo();
	const hasInvitePermission = scope?.includes('account:user:create') || scope?.includes('account-admin:user:create');
	let showContactUs = false;

	showContactUs = scope?.includes("account:support:create") ? true : false;

	const leftList = [
		{ name: "Products" },
		{ name: "Pricing" },
		{ name: "Documents" },
		{ name: "Learn" }
	];

	const handleSetOpen = (value) => {
		setProductSwitcherOpen(value);
	};

	const handleClickLogo = () => {
		navigate(`/${accountAlias}`);
	};

	const handleLogout = () => {
		logout({ returnTo: window.location.origin });
	};

	// const rightList = [
	// 	{ name: <SearchIcon/>, show: true },
	// 	{ name: "Contact Us", show: true },
	// 	{ name: "Sign In", show: isAuthenticated },
	// 	{ name: "Learn", show: isAuthenticated }
	// ];
	const addUser = () => {
		setIsInviteUser(true);
		window.location.pathname === `/${accountAlias}/home` && navigate(`/${accountAlias}/account`);
	};
	const handleSearch = (event) => {
		console.log("search clicked", event);
	};

	const handleSignInClick = () => {
		navigate('/login');
	};

	const onClickContactUs = () => {
		setOpenContactUs(true);
	};

	function NavLoader() {
		return <div className={styles.loaderRoot}>
			<Loader width={100} height={60} type={'Rings'}/>
		</div>;
	}

	// return <NavLoader/>;
	return isAuthenticated ? <div className={styles.root}>
		<div className={styles.searchContainer}>
			<div className={styles.searchbar} id={'NavBar_searchbar_container'}>
				<SearchIcon
					sx={{ width: '14px', height: '14px', fontSize: "14px", marginInline: '14px', color: "#54698C" }}/>
				<input type="text" placeholder='Search for products, features, docs and more'/>
			</div>
		</div>
		<div className={styles.container} id={'NavBar_heading_container'}>
			<div className={styles.left}>
				<ul className={styles.list}>
					<li className={clsx(styles.items, styles.logo, styles.anchor)} onClick={handleClickLogo}
						id={'NavBar_heading_logo'}>{logo}</li>
					<div className={styles.leftListContainer}>
						<div className={styles.navIndicator}>
							{currentPageIndicator ?
								<div className={styles.navName} id={'NavBar_heading_pageIndicator'}>{currentPageIndicator}</div> :
								<SpaceSwitcher/>}
						</div>
					</div>
				</ul>
			</div>
			<div className={styles.right}>
				<ul className={styles.list}>
					<div className={styles.rightListContainerAuth} id={'NavBar_rightList_container'}>
						<>
							{hasInvitePermission && <NavBarIconButton onClick={addUser}><AddUserIcon id={'navBar_addUser_icon'} className={styles.iconItem}/></NavBarIconButton>}
							<HelpDoc/>
							{showContactUs && <NavBarIconButton onClick={onClickContactUs}>
								<EnvelopeIcon className={styles.iconItem}
									id={'navBar_contactUs_icon'}/>
							</NavBarIconButton>}
							<UserProfile handleLogout={handleLogout} user={user}/>
							<ProductSwitcher open={productSwitcherOpen} handleSetOpen={handleSetOpen}/>
						</>
					</div>
				</ul>
			</div>
			<ContactUsDialog open={openContactUs} setOpen={setOpenContactUs}/>

		</div>
	</div> : <div className={styles.rootLogout}>
		{isLoading ? <NavLoader/> : <div>
			<div className={styles.rightListContainer} id={'NavBar_rightList_container'}>
				<div className={styles.leftListContainer}>
					{leftList.map((item, index) => (
						<li key={`${item.name}_${index}`} className={styles.items}>
							<Link className={styles.anchor} to="/">{item.name}</Link>
						</li>
					))}
				</div>
			</div>
			<div className={styles.right}>
				<ul className={styles.list}>
					<div className={styles.rightListContainerAuth} id={'NavBar_rightList_container'}>
						<>
							<SearchIcon onClick={handleSearch}
								sx={{ width: '20px', height: '24px', fontSize: "20px", marginRight: '14px' }}/>
							<li className={styles.items} id={'NavBar_rightList_contactUs'}><Link
								className={styles.anchor} to="#">Contact Us</Link></li>
							<li className={`${styles.items} ${styles.anchor}`} onClick={handleSignInClick}
								id={'NavBar_rightList_anchor'}>Sign In
							</li>
							{/* <li className={styles.items}><Link className={styles.anchor} to="/login">Sign In</Link></li> */}
							<li className={styles.items}><Link to="#" className={`${styles.btnBlue} ${styles.anchor}`}
								id={'NavBar_rightList_btnBlue'}>Get Started</Link></li>
						</>
					</div>
				</ul>
			</div>
		</div>}
	</div>;
};

export default NavBar;
