const RecentlyUseSvg = (props) => (
	<svg
		width={24}
		height={24}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.0073 24C9.54757 24 7.18136 23.2584 5.1634 21.8552C4.68438 21.4859 4.57369 20.882 4.90038 20.4152C5.09238 20.1394 5.40634 19.9753 5.74136 19.9753C5.95197 19.9753 6.15524 20.0395 6.32961 20.161C7.99883 21.3262 9.96242 21.9424 12.0073 21.9424C17.4867 21.9424 21.9443 17.4818 21.9443 11.9995C21.9443 9.32767 20.9103 6.82531 19.0325 4.95233C17.16 3.08522 14.6654 2.05665 12.0073 2.05665C9.53434 2.05665 7.19752 2.95494 5.3701 4.59624L3.91638 3.14155C4.92634 2.21682 6.07344 1.4782 7.33418 0.942367C8.80699 0.317388 10.3645 0 11.9632 0H12.0073C15.2111 0 18.2228 1.248 20.4876 3.51429C22.7525 5.78057 24 8.79429 24 12C24 15.2057 22.7525 18.2194 20.4876 20.4857C18.2228 22.752 15.2111 24 12.0073 24Z"
			fill="#293447"
		/>
		<path
			d="M16.32 14.6939L13.1824 11.5151V6.90612C13.1824 6.25469 12.6588 5.73061 12.0074 5.73061C11.3559 5.73061 10.8323 6.25469 10.8323 6.90612V12C10.8323 12.3135 10.9548 12.6122 11.1752 12.7886L14.6998 16.3151C15.1597 16.8147 15.9042 16.8147 16.32 16.3151C16.8191 15.8988 16.8191 15.1543 16.32 14.6939V14.6939Z"
			fill="#293447"
		/>
		<path
			d="M6.56131 8.29372H1.17404C0.525551 8.29372 0 7.77013 0 7.11919V1.73192C0 0.685718 1.26465 0.161147 2.00473 0.901229L7.38857 6.28702C8.12718 7.02613 7.60408 8.29372 6.5618 8.29372H6.56131Z"
			fill="#293447"
		/>
	</svg>
);

export default RecentlyUseSvg;
