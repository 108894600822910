import styles from "./SynamediaCover.module.css";
import background from "../../assets/login/png/background.png";
import { SynamediaLogoIcon, BeinSport, SkyIcon, YesIcon, AstroIcon, VodafoneIcon } from "../../components/Assets";
import React from "react";

const contentObj = {
	title: <div>Synamedia <br/> Cloud Portal</div>,
	desc: <div>Your single access point for all your Synamedia products. <br/> Login here to access your products.</div>,
	trial: <div id={'synamediaCover_trial_container'} className={styles.trialContainer}>
		<div id={'synamediaCover_trial_text0'}>Don&apos;t have access yet?</div>
		<div className={styles.trialLinkContainer}>
			<a  id={'synamediaCover_trial_link'} className={styles.trialLink} target={'_blank'} href={'https://www.synamedia.com/contact/'} rel="noreferrer">Request a trial</a>
			<div  id={'synamediaCover_trial_text1'}>to get set up.</div>
		</div>
	</div>,
	trustText: "Trusted by leaders",
};

export default function SynamediaCover({ showTrial = false, desc='' }) {
	return (
		<div className={styles.root} style={{ backgroundImage: `url(${background})` }}>
			<div className={styles.container}>
				<div className={styles.logo}><SynamediaLogoIcon/></div>
				<div className={styles.infoContainer}>
					<div className={styles.title}>{contentObj.title}</div>
					<div className={styles.desc}>{desc ?? contentObj.desc}</div>
					{showTrial && <div className={styles.desc}>{contentObj.trial}</div>}
				</div>
				<div className={styles.trustContainer}>
					<div className={styles.trustedTypography}>{contentObj.trustText}</div>
					<div className={styles.companyIconsContainer}>
						<AstroIcon/>
						<BeinSport/>
						<SkyIcon/>
						<VodafoneIcon/>
						<YesIcon/>
					</div>
				</div>
			</div>
		</div>
	);
}
