import { Button } from '@ip-synamedia/common-ui-library';
import React from 'react';
import styles from "./PageHeader.module.css";
import { AppBreadcrumbs } from '../AppBreadcrumbs';

const PageHeader = (props) => {
	const {
		title = "",
		description = "",
		isShowActions = false,
		primaryActionButtonProps,
		primaryActionHandler,
		showBreadCrumbs = false,
		breadCrumbItems = [],
		selectedCrumb = {},
		handleBreadCrumbClick = () => {},
		compId = "app",
		...rest
	} = props;

	return (
		<div className={styles.pageHeaderContainer} {...rest}>
			
			{showBreadCrumbs && <div id={`${compId}_BreadCrumb_container`}>
				<AppBreadcrumbs id={`${compId}_BreadCrumb`} items={breadCrumbItems} selectedCrumb={selectedCrumb} handleClick={handleBreadCrumbClick}/>
			</div>
			}
			<div id={`${compId}_title_container`} className={styles.titleContainer}>
				{title ?
					<div className={styles.textContainer}>
						{title ? <div className={styles.title} id={`title_${title}`}>
							{title}
						</div> : null}
						{description ? <div className={styles.description}>
							{description}
						</div> : null}
					</div>
					: null}
				{
					isShowActions && 
            <div className={styles.actionContainer}>
            	<Button {...primaryActionButtonProps} onClick={primaryActionHandler}/>
            </div>
				}
			</div>
		</div>
	);
};

export default PageHeader;