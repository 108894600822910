import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { getQueryParams } from "../../utils/router-utils";
import useAccountAlias from "../../custom-hooks/useAccountAlias";
import Loader from "../../components/Loader";
import { NotFoundPage } from "../index";

const InvitationPage = () => {
	const { loginWithRedirect } = useAuth0();
	const { invitation, organization } = getQueryParams();
	const [error, setError] = useState(null);
	const { isLoading, isError, error: accountAliasError, accountAlias } = useAccountAlias(organization);

	const acceptInvitation = useCallback(async () => {
		if (!invitation || !organization) {

			setError(new Error('Missing invitation params...'));

			return;
		}

		loginWithRedirect({
			organization,
			invitation,
			appState: {
				returnTo: `/${accountAlias}`,
			},
		});
	}, [invitation, loginWithRedirect, organization, accountAlias]);

	useEffect(() => {
		if (accountAlias) {
			acceptInvitation();
		}
	}, [acceptInvitation, accountAlias]);

	if (isLoading) {
		return <Loader/>;
	}
	if (isError) {
		console.warn('Failed to lookup for account alias', accountAliasError.message);

		//TODO: introduce more accurate error for such a case
		return <NotFoundPage/>;
	}

	if (error) {
		return <div>{error.message}</div>;
	}

	return null;
};

export default InvitationPage;

