import React from 'react';
import { Checkbox } from "@mui/material";

const styles = {
	base: {
		padding: 0,
		'&.Mui-checked': {
			color: 'hsla(217, 100%, 60%, 1)',
		},
	}
};

const AppCheckbox = ({ name, onClick, sx = {}, ...props }) => {
	const handleClick = (checked) => {
		onClick?.(checked);
	};

	return (
		<Checkbox
			name={'confirm'}
			value={'yes'}
			size={'small'}
			sx={{ ...styles.base, ...sx }}
			onClick={event => handleClick(event.target.checked)}
			{...props}
		/>
	);
};

export default AppCheckbox;
