import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import { deleteUserInvitation, resendInvitation } from "../../../../../external-apis";
import { useDecodedJwtStore, useMainContainerStore } from "../../../../../state-management";
import { Dropdown, EllipsisIcon, Table, Tag, TrashIcon } from "@ip-synamedia/common-ui-library";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./css/InvitedUsers.module.css";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useCopyToClipboard from "../../../../../custom-hooks/useCopyToClipboard";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import shallow from "zustand/shallow";
import NoInvitees from "./NoInvitees";
import Loader from "../../../../../components/Loader";
import moment from "moment";
import ResendInvitationSVG from "../../../../../assets/icons/ResendInvitationSVG";
import { PopUp } from "@ip-synamedia/common-ui-library";
import analytics from "@matisse/portal-analytics";

const columns = [
	{
		id: "email",
		accessor: "email",
		Header: "Email",
		width: 80
	},
	{
		id: "status",
		accessor: (originalRow) => {
			return originalRow.isExpired ? "Expired" : "Active";
		},
		Header: "Status",
		width: 40,
		Cell: ({ row: { original } }) => (<div style={{ width: "max-content", display: "flex", alignItems: "center", justifyContent: "center" }}>
			<Tag id={`${original.email}_invite_status_tag`} type="status" variant={original.isExpired ? "error" : "success"}>
				{original.isExpired ? "Expired" : "Active"}
			</Tag>
		</div>)
	},
	{
		id: "invitedAt",
		accessor: "invitedAt",
		Header: "Invited",
		width: 60,
		Cell: ({ row: { original } }) => <>{moment(new Date(original.invitedAt)).format("DD MMM YYYY, h:mm A")}</>
	},
	{
		id: "projects",
		accessor: "projects",
		Header: "Projects",
		disableFilters: true,
		width: 250,
		Cell: ({ row: { original } }) => <Projects projects={original.projects}/>
	},
];

function Projects({ projects }) {
	return (
		<div className={styles.rootContainer}>
			{(projects ?? []).map((project, index) =>
				<div key={project.id + index} className={styles.project}>
					{project.displayName}
					{index < projects.length - 1 && ','}
				</div>)}
		</div>
	);
}

export default function InvitedUsers() {
	const { mutateAsync } = useMutationWithAuthorization();
	const queryClient = useQueryClient();

	const [copyToClipboard, resetCopy, { success: copySuccess }] = useCopyToClipboard();
	const [curUserData, setCurUserData] = useState();
	const [removeUserDialogVisible, setRemoveUserDialogVisible] = useState(false);
	const [decodedJwt] = useDecodedJwtStore(state => [state.decodedJwt], shallow);
	const allowsInvitationDeletion = decodedJwt?.scope?.includes("account:user:delete");
	const allowsInvitationCreation = decodedJwt?.scope?.includes("account:user:create");

	const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
	const ownerDeleteMessage = "You are trying to delete the owner, this action is not permitted.";
	const deleteMessage = "Are you sure you want to revoke the invitation for";

	const [{
		data: account = [],
	}] = useMainContainerStore(state => [state.accountPromise], shallow);

	const [{
		data: invitees = [],
		isError: inviteesIsError,
		error: inviteesError,
		isLoading: inviteesIsLoading,
	}] = useMainContainerStore(state => [state.inviteesPromise], shallow);

	function handleDeleteInvitation() {
		analytics.account.userRevoked();

		const deleteUserPromise = mutateAsync(deleteUserInvitation(account.id, curUserData.id));

		setRemoveUserDialogVisible(false);
		toast.promise(deleteUserPromise,
			{
				pending: "Revoking invitation...",
				success: {
					render() {
						queryClient.invalidateQueries(['users-invited', account.id]);

						return <div id={'deleteInvitee_toast_text'}>Invitation revoked successfully</div>;
					}
				},
				error: {
					render({ data: error }) {
						const json = JSON.parse(error.message);

						return <div
							id={'deleteInvitee_toast_textError'}>{`Failed to revoke invitation: ${json.status === 409 ? "owner invitation can not be revoked" : json.message}`}</div>;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	}

	useEffect(() => {
		account.owner?.email && curUserData?.email && setIsDeleteAllowed(account.owner.email !== curUserData?.email);
	}, [curUserData, account]);

	if (copySuccess) {
		toast.success('Invitation URL was copied to the clipboard!', { position: toast.POSITION.TOP_CENTER });
		resetCopy();
	}

	if (copySuccess != null && !copySuccess) {
		toast.error('Failed to copy invitation URL', { position: toast.POSITION.TOP_CENTER });
		resetCopy();
	}

	const handleResendInvitation = (original) => {
		const reInvitePromise = mutateAsync(resendInvitation(account.id, original.invitationId));

		toast.promise(reInvitePromise,
			{
				pending: "Resend invitation...",
				success: {
					render() {
						queryClient.invalidateQueries(['users-invited', account.id]);

						return <div id={'resendInvitee_toast_text'}>Invitation resend successfully</div>;
					}
				},
				error: {
					render({ data: error }) {
						const json = JSON.parse(error.message);

						return <div
							id={'resendInvitee_toast_textError'}>{`Failed to resend invitation: ${json?.message?.message || json?.message ||  original.invitationId}`}</div>;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	};

	const RowActions = ({ row: { original } }) => {
		const options = useMemo(() => {
			const actionsList =  [
				{
					id: "copyInvitationUrl",
					label: "Copy invitation URL",
					icon: <ContentCopyIcon sx={{ width: 14, height: 14 }}/>,
					disabled: original.isExpired,
					info: original.isExpired ? 'This invite has expired. Please resend this invite.' : ''
				}
			];

			if(allowsInvitationCreation) {
				actionsList.push({
					id: "resendInvitation",
					label: "Resend Invite",
					icon: <ResendInvitationSVG/>
				});
			}

			if(allowsInvitationDeletion)
				actionsList.push({
					id: "revokeInvitation",
					label: "Revoke Invitation",
					icon: <TrashIcon/>
				});

			return actionsList;
		}, [original]);

		return (
			<>
				{!original.isExpired ? <div
					style={{
						padding: "0px 4px",
						cursor: "pointer",
					}}
					onClick={(e) => {
						e.stopPropagation();
						copyToClipboard(original.invitationUrl);
					}}
				>
					<ContentCopyIcon sx={{ width: 14, height: 14 }}/>
				</div> : null}
				{allowsInvitationDeletion ? <div
					style={{
						padding: "0px 4px",
						cursor: "pointer",
					}}
					onClick={(e) => {
						e.stopPropagation();
						setCurUserData(original);
						setRemoveUserDialogVisible(true);
					}}
				>
					<TrashIcon />
				</div> : null}
				<Dropdown
					type={"menu"}
					// side="bottom"
					align="end"
					options={options}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onValueChange={(value) => {
						if(value === "copyInvitationUrl") {
							copyToClipboard(original.invitationUrl);
						}
						if(value === "resendInvitation") {
							setCurUserData(original);
							handleResendInvitation(original);
						}
						if(value === "revokeInvitation") {
							setCurUserData(original);
							setRemoveUserDialogVisible(true);
						}
					}}
				>
					<EllipsisIcon style={{ cursor: "pointer" }} height="14px" width="14px" />{" "}
				</Dropdown>
		  </>
		);
	};

	return inviteesIsLoading ?  <Loader type={'Rings'}/>: (invitees && invitees.length) ? <div className={styles.tableRootContiner}>
		<Table
			data-cy="table"
			label="Invitees"
			data={invitees}
			columns={columns}
			RowActions={RowActions}
			onRowClick={() => {}}
			style={{
				height: '100%',
				padding: '0 20px'
			}}
		/>
		<PopUp
			onClose={() => setRemoveUserDialogVisible(false)}
			open={removeUserDialogVisible}
			size="small"
			title="Revoke invitation"
			description={`${!isDeleteAllowed ? ownerDeleteMessage : deleteMessage + ` '${curUserData?.email}'?`}`}
			onAccept={() => {
				setRemoveUserDialogVisible(false);
				handleDeleteInvitation();
			}}
			onCancel={() => setRemoveUserDialogVisible(false)}
			showFooter={isDeleteAllowed}
			cancelButtonLabel={'CANCEL'}
			acceptButtonLabel={isDeleteAllowed ? 'Yes, Revoke it': 'OK'}
		/>
	</div> : <NoInvitees inviteesIsError={inviteesIsError} inviteesError={inviteesError}/>;
}
