import React, { useEffect } from 'react';
import { useDeepLinkStore, useUsersStore } from "../../../../state-management";
import Header from "./Header";
// import { Info } from "@ip-synamedia/syna-ui-library";
import { useUserInfo } from "../../../../auth/UserInfoProvider";
import styles from "./AccountUser.module.css";
import InviteUsers from './InviteUsers';
import shallow from 'zustand/shallow';
import EditUser from './EditUser';
import AccessDenied from "../../../../components/AccessDenied";

export default function AccountUsers() {
	const { accountId, scope } = useUserInfo();
	const [resetUserStore, activeTabComponent, isUserInviteActive, setIsUserInviteActive, isUserEditActive, setIsUserEditActive] = useUsersStore(state => [state.reset, state.activeTabComponent, state.isUserInviteActive, state.setIsUserInviteActive, state.isUserEditActive, state.setIsUserEditActive], shallow);
	const isInviteUser = useDeepLinkStore(state => state.isInviteUser);

	useEffect(() => {
		if (isInviteUser)
			setIsUserInviteActive(true);

		return () => {
			resetUserStore();
		};
		//eslint-disable-next-line
    }, [isInviteUser]);

	if (scope && !scope?.includes("account:user:list")) {
		return <AccessDenied/>;
	}

	const hasInvitePermission = scope?.includes('account:user:create') || scope?.includes('account-admin:user:create');

	if (isUserInviteActive)
		return <InviteUsers setIsUserInviteActive={setIsUserInviteActive}/>;

	if (isUserEditActive)
		return <EditUser setIsUserEditActive={setIsUserEditActive}/>;

	return (
		<div style={{ height: '100%' }}>
			<div style={{ height: 100 }}>
				<Header hasInvitePermission={hasInvitePermission} setIsUserInviteActive={setIsUserInviteActive}/>
			</div>
			<div className={styles.root}>
				{/* {!accountId && <div className={styles.selectAccountContainer}>
					<div className={styles.selectAccountInfo}>
						<Info style={{ display: "block", margin: "auto" }} fill='#3381FF' width='4em' height='4em'/>
						<div className={styles.selectAccountLabel}>
						Select account to manage users
						</div>
					</div>
				</div>} */}
				{accountId && activeTabComponent}
			</div>
		</div>
	);
}
