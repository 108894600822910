const BookSvg = (props) => (
	<svg
		width={32}
		height={24}
		viewBox="0 0 32 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M29.0721 0.727347C27.4379 0.198367 25.668 0 23.9657 0C21.1745 0 18.1103 0.595102 16 2.11592C13.8892 0.595102 10.8255 0 8.03379 0C5.24204 0 2.11084 0.595102 0 2.11592V22.8098C0 23.1404 0.408549 23.471 0.749007 23.471C0.88519 23.471 0.953282 23.4049 1.08946 23.4049C3.06412 22.4792 5.92346 21.8841 8.0343 21.8841C10.8255 21.8841 13.8897 22.4131 16 24C17.9747 22.8098 21.5149 21.8841 23.9657 21.8841C26.4165 21.8841 28.8678 22.2808 30.9105 23.3388C31.0467 23.4049 31.1148 23.4049 31.251 23.4049C31.5915 23.4049 32 23.0743 32 22.6776V2.11592C31.1148 1.45469 30.1615 1.05796 29.0726 0.727347H29.0721ZM15.3302 21.5192C13.0645 20.1933 10.527 19.8813 7.71402 19.8813C5.68287 19.8813 3.65122 20.1022 1.85411 20.6914V3.01273C3.65122 2.49698 5.68237 1.83527 7.71402 1.83527C10.527 1.83527 13.0645 2.14776 15.3302 3.47314V21.5192ZM30.2841 20.6914C28.4981 20.1022 26.479 19.8813 24.46 19.8813C21.6642 19.8813 19.1423 20.1938 16.8902 21.5192V3.47363C19.1423 2.14775 21.6642 1.83576 24.46 1.83576C26.479 1.83576 28.4981 2.49747 30.2841 3.01322V20.6914Z"
			fill="#293447"
		/>
	</svg>
);

export default BookSvg;
