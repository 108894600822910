import React, { useEffect, useMemo, useState } from "react";
import { Details } from "./Details";
import { Tab } from '@ip-synamedia/common-ui-library';
import styles from "./UserRolesDrawer.module.css";
import { SidePanel } from "@ip-synamedia/common-ui-library";

export default function UserRolesDrawer({ isDrawerOpen, onClose, curData, accountId, initiateDelete }) {
	const [activeTab, setActiveTab] = useState('details');
	const [activeComponent, setActiveComponent] = useState(<Details curData={curData} accountId={accountId}
		initiateDelete={initiateDelete}/>);

	const tabs = useMemo(() => [
		{
			id: 'details',
			label: 'Details',
			component: <Details curData={curData} accountId={accountId} initiateDelete={initiateDelete}/>
		},
		// { id: 'audit', label: 'Audit', component:<div className={styles.comingSoon}><CommingSoon/></div> }
	]
	// eslint-disable-next-line
        , [accountId, curData, initiateDelete]);

	useEffect(() => {
		setActiveComponent(tabs.find(tab => tab.id === activeTab).component);
	}, [activeTab, tabs]);

	const handleTabChange = (tabId) => {
		setActiveTab(tabId);
	};

	const headerCustomComponent = () => {
		return <div>
			<div className={styles.headerContainer} id={'userDrawer_header_container'}>
				<div className={styles.headerTitle} id={'userDrawer_header_title'}>{curData.userName}</div>
			</div>
			<div className={styles.tabs}>
				{tabs.map((tab, inedx) => {
					return <div className={styles.tab} key={'tab' +inedx }><Tab key={'userDrawerTab' + tab.id} selected={tab.id === activeTab} onClick={() => handleTabChange(tab.id)} label={tab.label}/></div>;
				})}
			</div>
		</div>;
	};

	return (
		<div>
			<SidePanel
				className={styles.drawer}
				open={isDrawerOpen}
				description={headerCustomComponent()}
				onCancel={onClose}
				onClose={onClose}
			>

				{activeComponent}
			</SidePanel>
		</div>

	);
}