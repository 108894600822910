import React from 'react';
import styles from "./Header.module.css";
import clsx from 'clsx';

const Header = ({ title, isHomePage = false }) => {
	return (
		<div className={clsx(isHomePage && styles.homeHeader, styles.header)} id={'HomePage_header_container'}>
			<div className={clsx(isHomePage && styles.homeTitle , styles.title)} id={'HomePage_header_title'}>{title}</div>
		</div>
	);
};

export default Header;