import { Card } from "../../../components/Card";
import styles from "./middleCards.module.css";
import React from "react";
import clsx from "clsx";

const TitleContainer = ({ title, subTitle,icon,id, index }) => {
	return ( icon ? <div className={styles.titleContainerWithIcon} id={id}>
		<div className={styles.cardIcon} id={`HomePage_card${index}_titleIcon`}>{icon}</div>
		<div className={clsx(styles.cardTitle, 'productiveHeading3')} id={`HomePage_card${index}_titleText`}>{title}</div>
	</div> : <div className={styles.titleContainerWithSubTitle}>
		<div className={clsx(styles.cardTitleOnly, 'productiveHeading3')} id={`HomePage_card${index}_titleText`}>{title}</div>
		{subTitle ? <div className={styles.cardsubTitle} id={`HomePage_card${index}_subTitleText`}>{subTitle}</div> : null}
	</div>);
};

export default function MiddleCardsContainer({ children, elementOut, index }) {

	return  <React.Fragment key={`${elementOut.title}_${index}`}>
		<Card className={styles.card} id={`${elementOut.title}_${index}`}>
			<div className={styles.cardContainer}>
				<TitleContainer title={elementOut.title} icon={elementOut.icon} id={`HomePage_card${index}_title`} index={index}/>
				<div className={styles.listContainer}>
					{children}
				</div>
			</div>
		</Card>
	</React.Fragment>;
}