import React from 'react';
import styles from "./Applications.module.css";
import { useUserInfo } from '../../../../auth/UserInfoProvider';
import PageHeader from '../../../../components/PageHeader';
import { getApplicationsByAccountIdAndProjectId, getProjectsByAccountId } from '../../../../external-apis';
import { useCleanupEffect, useQueriesWithAuthorization, useQueryWithAuthorization } from '../../../../custom-hooks';
import Loader from '../../../../components/Loader';
import getMessageFromError from '../../../../utils/API-calls';
import ApplicationsContainer from './ApplicationsContainer';
import { useApplicationStore } from '../../../../state-management';
import { CircleAddIcon } from '@ip-synamedia/common-ui-library';
import EditApplication from './EditApplication';
import CreateApplication from './CreateApplication';
//import shallow from "zustand/shallow";

const mapProjects = (projects) => {
	return projects.reduce((projectDataByProjectId, project) => {
		const { id, displayName, description } = project;

		return {
			...projectDataByProjectId,
			[id]: {
				displayName,
				description,
			}
		};
	}, {});
};

const getQueries = ( accountId, projectsData, staleTime ) => {
	return projectsData && Object.entries(projectsData).map(([id]) => {
		return {
			queryKey:['get-application-by-account-id-and-project-id', accountId, id],
			query: getApplicationsByAccountIdAndProjectId(accountId, id, {}, 'projectId,applicationId'),
			staleTime: staleTime,
			enabled: accountId !== undefined
		};
	});
};

const checkLoadError = (queries) => {
	if (!queries.every(key => !key.isLoading)) return<Loader className={styles.appLoader} width={30} height={30} type={'Rings'}/>;
	if (queries.length>0 && queries.every(key => key.isError)) {
		const error = queries.map(key => key.error).join(',');

		return <div id="applications_error">Error fetching data: {error}</div>;
	}

	return false;
};

const Applications = (props) => {
	const { accountId, scope } = useUserInfo();
	const [resetApplicationStore,isApplicationCreateActive, setIsApplicationCreateActive, isApplicationEditActive, setIsApplicationEditActive] = useApplicationStore(state => [state.reset, state.isApplicationCreateActive, state.setIsApplicationCreateActive, state.isApplicationEditActive, state.setIsApplicationEditActive]);

	useCleanupEffect(resetApplicationStore);
	const hasSecretAccess = scope?.includes('account:application:create') || scope?.includes('account:application:update') || scope?.includes('account:application:delete') || scope?.includes('account-admin:application:create') || scope?.includes('account-admin:application:update') || scope?.includes('account-admin:application:delete');
	const staleTime = 60000;
	const {
		data: projectsData = {},
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectsError
	} = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjects,
		enabled: accountId != null,
		staleTime: staleTime
	});

	const queries = getQueries(accountId, projectsData, staleTime);
	const applicationQueriesRes = useQueriesWithAuthorization(queries);
	let accountsList = [];

	if(projectsIsLoading) return <Loader className={styles.appLoader} />;
	if(projectsIsError) return <div id={"inviteUser_projects_error"}>{getMessageFromError(projectsError)}</div>;
	if(checkLoadError(applicationQueriesRes)) return <div>{checkLoadError(applicationQueriesRes)}</div>;

	if(applicationQueriesRes.length) {
		Object.keys(projectsData).length && Object.keys(projectsData).forEach(projectId => {
			applicationQueriesRes.forEach((allApps) => {
				const apps = allApps?.data?.length ? allApps?.data : [];

				if(apps.length) {
					apps.forEach(app => {
						if(projectId === app.projectId)
							accountsList.push(app);
					});
				}

			});
			accountsList = accountsList.filter(element => {
				return Object.keys(element).length !== 0;
			});
		});
	}

	const handleCreateApplication = () => {
		setIsApplicationCreateActive(true);
	};

	const createApplicationButtonProps = {
		id: "createApplication",
		children: <div className={styles.createAppButton}>
			<CircleAddIcon/>
			<div>Create Application</div>
		</div>
	};

	if(isApplicationEditActive) return <EditApplication resetApplicationsStore={resetApplicationStore} setIsApplicationEditActive={setIsApplicationEditActive} isApplicationEditActive={isApplicationEditActive} />;
	if(isApplicationCreateActive) return <CreateApplication resetApplicationsStore={resetApplicationStore} setIsApplicationCreateActive={setIsApplicationCreateActive} isApplicationCreateActive={isApplicationCreateActive} />;

	return (
		<div className={styles.applicationsContainer} {...props}>
			<PageHeader
				title="Applications"
				id="Header_Applications"
				isShowActions={scope?.includes("account:application:create") || scope?.includes('account-admin:application:create')}
				primaryActionButtonProps={createApplicationButtonProps}
				primaryActionHandler = {handleCreateApplication}
			/>
			<ApplicationsContainer
				accountsList={accountsList}
				staleTime={staleTime}
				projectsData={projectsData}
				hasSecretAccess={hasSecretAccess}
			/>
		</div>
	);
};

export default Applications;
