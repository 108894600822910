import { useEffect } from "react";

export default function useCleanupEffect(callback) {
	useEffect(() => {
		return function cleanup() {
			callback();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
