import React from 'react';
import { AppBreadcrumbs } from '../../../../../../components/AppBreadcrumbs';
import styles from "./Header.module.css";

const Header = ({ selectedCrumb,breadCrumbItems,handleBreadCrumbClick,title,...rest }) => {
	return (
		<div className={styles.headerContainer} {...rest}>
			<div className={styles.pageHeaderContainer}>
				<div id={'UserManagement_BreadCrumb_container'}>
					<AppBreadcrumbs id="UserManagement_BreadCrumb" items={breadCrumbItems} selectedCrumb={selectedCrumb} handleClick={handleBreadCrumbClick}/>
				</div>
				<div id={'inviteUser_header_title'} className={styles.headerTitle}>{title}</div>
			</div>
		</div>
	);
};

export default Header;