import * as React from "react";

const CopyContentSVG = (props) => (
	<svg
		width={14}
		height={14}
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M13.7266 1.94141L12.0585 0.273438C11.8946 0.109375 11.6758 0 11.4297 0H6.97266C5.98828 0 5.22266 0.792969 5.22266 1.75V8.75C5.25 9.73438 6.01562 10.5 7 10.5H12.25C13.2071 10.5 14 9.73438 14 8.75V2.57031C14 2.32422 13.8906 2.10547 13.7266 1.94141ZM12.6875 8.75C12.6875 8.99605 12.4688 9.1875 12.25 9.1875H6.97266C6.72656 9.1875 6.53516 8.99605 6.53516 8.75V1.77734C6.53516 1.53125 6.72656 1.33984 6.97266 1.33984H10.4727L10.5 2.625C10.5 3.11719 10.8828 3.5 11.375 3.5H12.6602V8.75H12.6875ZM7.4375 12.25C7.4375 12.496 7.21875 12.6875 7 12.6875H1.72266C1.47656 12.6875 1.28516 12.496 1.28516 12.25V5.27734C1.28516 5.03125 1.50391 4.83984 1.72266 4.83984H4.375V3.5H1.72266C0.765625 3.5 -0.0273438 4.29297 -0.0273438 5.25L0 12.25C0 13.2344 0.765625 14 1.75 14H7C7.95708 14 8.75 13.2344 8.75 12.25V11.375H7.4375V12.25Z"
			fill="#455878"
		/>
	</svg>
);

export default CopyContentSVG;
