import { getApiEndpoint } from '../../config';

const baseHeaders = {
	"content-type": "application/json"
};

const getAll = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: "GET",
		url: `${getApiEndpoint()}/productInventory/products`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getProductsPermissions = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/productInventory/products/permissions`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const getById = (id, params = {}) => {
	const { headers = {} } = params;

	return {
		method: "GET",
		url: `${getApiEndpoint()}/productInventory/products/${id}`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

export {
	getAll,
	getById,
	getProductsPermissions
};
