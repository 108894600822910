import { useMutationWithAuthorization } from "../../../../../custom-hooks";
import { deleteUser } from "../../../../../external-apis";
import { Dropdown, EditIcon, EllipsisIcon, Table, TrashIcon } from "@ip-synamedia/common-ui-library";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDecodedJwtStore, useMainContainerStore, useUsersStore } from "../../../../../state-management";
import { useQueryClient } from "react-query";
import shallow from "zustand/shallow";
import NoActiveUsers from "./NoActiveUsers";
import UserRolesDrawer from "./userDrawer/UserRolesDrawer";
import styles from "./css/ActiveUsers.module.css";
import Loader from "../../../../../components/Loader";
import { PopUp } from "@ip-synamedia/common-ui-library";

const UserName = ({ name, role }) => {
	return (
		<>
			<div className={styles.userName}>{name}</div>
			<div className={styles.role}>{role?.toUpperCase()}</div>
		</>
	);
};

const columns = [
	{
		id: "userName",
		Header: "Name",
		accessor: "userName",
		Cell: ({ row: { original } }) => (
			<UserName name={original.userName} role={original.userRole}/>
		)
	},
	{
		id: "email",
		Header: "Email",
		accessor: "email",
	},
	{
		id: "lastLogin",
		Header: "Last Login",
		accessor: "lastLogin",
		disableFilters: true,
	},
	{
		id: "joined",
		Header: "Joined",
		accessor: "joined",
		disableFilters: true,
	}
];

export default function ActiveUsers() {
	const ownerDeleteMessage = "You are trying to delete the owner, this action is not permitted.";

	const [setCurrentUserData, setIsUserEditActive] = useUsersStore((state) => [state.setCurrentUserData, state.setIsUserEditActive], shallow);
	const [{
		data: account = {},
		isLoading: accountIsLoading,
	}] = useMainContainerStore(state => [state.accountPromise], shallow);

	const [{
		data: users = [],
		isError: usersIsError,
		isLoading: usersIsLoading,
		error: usersError
	}] =  useMainContainerStore(state => [state.usersPromise], shallow);

	const { mutateAsync } = useMutationWithAuthorization();
	const queryClient = useQueryClient();
	const [decodedJwt] = useDecodedJwtStore(
		(state) => [state.decodedJwt],
		shallow
	);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [curData, setCurData] = useState(null);

	const allowsUserDeletion = decodedJwt?.scope?.includes("account:user:delete");
	const allowsEditUser = decodedJwt?.scope?.includes("account:user:update") || decodedJwt?.scope?.includes("account-admin:user:update") ;
	const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
	const [curUserData, setCurUserData] = useState();
	const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
	const textConfirmation = `Are you sure you want to remove user “${curUserData?.email}”? This action cannot be undone!`;

	function handleDelete() {
		const deleteUserPromise = mutateAsync(
			deleteUser(account.id, curUserData.id)
		);

		setDeleteUserDialogVisible(false);
		toast.promise(
			deleteUserPromise,
			{
				pending: "Deleting user...",
				success: {
					render() {
						queryClient.invalidateQueries(["users-active", account.id]);

						return <div id={'deleteUser_toast_text'}>deleted successfully</div>;
					},
				},
				error: {
					render({ data: error = "{}" }) {
						const json = JSON.parse(error.message);

						return <div id={'deleteUser_toast_textError'}>{`Failed to delete user: ${
							json.status === 409
								? "owner user can not be deleted"
								: json.message.message
						}`}</div>;
					},
				},
			},
			{
				position: toast.POSITION.TOP_CENTER,
			}
		);
	}

	const initiateDelete = (data) => {
		if (isDrawerOpen) {
			setIsDrawerOpen(false);
		}
		setCurUserData(data);
		setDeleteUserDialogVisible(true);
	};

	const initiateEdit = (data) => {
		if (isDrawerOpen) {
			setIsDrawerOpen(false);
		}
		setCurrentUserData(data);
		setIsUserEditActive(true);
	};

	const openUserRolesDrawer = (e, { original }) => {
		setCurData(original);
		setIsDrawerOpen(true);
	};

	const onCloseDrawer = () => {
		setIsDrawerOpen(false);
		setCurData(undefined);
	};

	const drawer = useMemo(() =>
		<UserRolesDrawer
			isDrawerOpen={isDrawerOpen}
			onClose={onCloseDrawer}
			curData={curData}
			accountId={account.id}
			initiateDelete={initiateDelete}
		/>
	//eslint-disable-next-line
        , [isDrawerOpen, curData, account.id, deleteUserDialogVisible]);

	useEffect(() => {
		account?.owner && setIsDeleteAllowed(account.owner.email !== curUserData?.email);
	}, [curUserData, account]);
	if(accountIsLoading || usersIsLoading) return <Loader type={'Rings'}/>;

	const actionsList = [];

	if(allowsEditUser) actionsList.push({ id: "edit", label: "Edit User", icon: <EditIcon /> });
	if(allowsUserDeletion) actionsList.push({ id: "remove", label: "Remove User", icon: <TrashIcon /> });

	const RowActions = ({ row: { original } }) => {
		const options = useMemo(() => {
			return actionsList;
			// eslint-disable-next-line
		}, [original]);
	
		return (
			<>
				{allowsEditUser ? <div
					style={{
						padding: "0px 4px",
						cursor: "pointer",
					}}
					onClick={(e) => {
						e.stopPropagation();
						initiateEdit(original);
					}}
				>
					<EditIcon />
				</div>: null}
				{allowsUserDeletion ? <div
					style={{
						padding: "0px 4px",
						cursor: "pointer",
					}}
					onClick={(e) => {
						e.stopPropagation();
						initiateDelete(original);
					}}
				>
					<TrashIcon />
				</div> : null}
				{ (allowsEditUser || allowsUserDeletion) ? <Dropdown
					type={"menu"}
					// side="bottom"
					align="end"
					options={options}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onValueChange={(value) => {
						if(value === "edit") {
							//   window.alert(`Edit Product Price: ${original.title}`);
							initiateEdit(original);
						}
						if(value === "remove") {
							initiateDelete(original);
						}
					}}
				>
					<EllipsisIcon style={{ cursor: "pointer" }} height="14px" width="14px" />{" "}
				</Dropdown> : null }
		  </>
		);
	};

	return users && users.length ? (
		<div className={styles.rootContainer}>
			<Table
				data-cy="table"
				label="Users"
				data={users}
				columns={columns}
				RowActions={RowActions}
				onRowClick={openUserRolesDrawer}
				style={{
					height: '100%',
					padding: '0 20px'
				}}
			/>
			<PopUp
				onClose={() => setDeleteUserDialogVisible(false)}
				open={deleteUserDialogVisible}
				size="small"
				title="Remove User"
				description={`${isDeleteAllowed ? textConfirmation : ownerDeleteMessage}`}
				onAccept={() => {isDeleteAllowed && handleDelete(); setDeleteUserDialogVisible(false);}}
				onCancel={() => setDeleteUserDialogVisible(false)}
				showFooter={isDeleteAllowed}
				cancelButtonLabel={'CANCEL'}
				acceptButtonLabel={isDeleteAllowed ? 'DELETE': 'OK'}
			/>
			{curData && drawer}
		</div>
	): <NoActiveUsers usersIsError={usersIsError} usersError={usersError}/>;
}
