import React from 'react';
import styles from  "./Footer.module.css";
import linkedinLogo from "../../../assets/icons/socialmediaLogos/linkedin.svg";
import twitterLogo from "../../../assets/icons/socialmediaLogos/twitter-x.svg";

const Footer = () => {
	return (
		<div className={styles.footerContainer}>
			<div className={styles.links}>
				<div className={styles.row}>
					<div className={styles.col}>
						<div className={styles.colHeading}>Site Map</div>
						<div className={styles.colItems}>
							<ul className={styles.colLists}>
								<li><a className={styles.anchor} href="https://www.synamedia.com">Home</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/about-us/">About</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/protect/">Protect</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/deliver/">Deliver</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/enrich/">Enrich</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/resources/">Resources</a></li>
							</ul>
						</div>
					</div>
					<div className={styles.col}>
						<div className={styles.colHeading}>Our Policies</div>
						<div className={styles.colItems}>
							<ul className={styles.colLists}>
								<li><a className={styles.anchor} href="https://www.synamedia.com/policy-centre/">Policy Centre</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/privacy-policy/">Privacy Policy</a></li>
								<li><a className={styles.anchor} id="cookie_policy" href="https://www.synamedia.com/cookie-policy/">Cookie Policy</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/code-of-business-conduct-of-ethics/">Code of Business Conduct and Ethics</a></li>
								<li><a className={styles.anchor} href="https://www.synamedia.com/synamedia-website-terms-and-conditions/">Website Terms and Conditions</a></li>
							</ul>
						</div>
					</div>
					<div className={styles.col}>
						<div className={styles.colHeading}>Follow Us</div>
						<div className={styles.colItems}>
							<ul className={styles.colLists}>
								<div>
									<a href="https://twitter.com/synamediaVideo/">
										<img className={styles.icon} src={twitterLogo} alt="socialMedia icon"/>
									</a>
									<a href="https://www.linkedin.com/company/synamedia/">
										<img className={styles.icon} src={linkedinLogo} alt="socialMedia icon"/>
									</a>
								</div>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.hzSaperater}/>
			<div className={styles.copyrights}>&copy; {new Date().getFullYear()} Synamedia.com. All Rights Reserved.</div>
		</div>
	);
};

export default Footer;