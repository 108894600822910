import React from 'react';
import PageHeader from "../../../../components/PageHeader";
import AuditContent from "./AuditContent";

const Audit = (props) => {

	return (
		<div>
			<PageHeader
				title="Audit Logs"
				id="Header_Audit"
				isShowActions={false}
			/>

			<AuditContent/>
		</div>
	);
};

export default Audit;