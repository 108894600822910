import React from "react";
import styles from "./Roles.module.css";
import ProductsRoles from "../../ProductsRoles";

const Roles = ({ label,selectedSpace, initialState, parentFirstRender, setParentFirstRender, ...rest }) => {
	const { id, productRoles } = selectedSpace;

	return (
		<div className={styles.spaceProductRolesInfoContainer} {...rest}>
			<div className={styles.productHeader}>
				<div id={'inviteUser_content_productRolesTitle'} className={styles.headerLabel}>{label}</div>
				{/* <div> Copy roles from dropdown</div> */}
			</div>
			<div className={styles.productRows}>
				{/* loop each sace products */}
				{/* <div className={styles.productRow}>
					<div className={styles.productRoleDropDownContainer}>
						{selectedSpace.label}
					</div>
				</div> */}
				<ProductsRoles key={id} projectId={id} productRoles={productRoles} initialState={initialState} parentFirstRender={parentFirstRender} setParentFirstRender={setParentFirstRender}/>
			</div>
		</div>
	);
};

export default Roles;