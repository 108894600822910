import React, { useMemo, useState } from 'react';
import styles from "./ApplicationsContainer.module.css";
import { Dropdown, EllipsisIcon, Table, Tag, Tooltip, TrashIcon, EditIcon } from '@ip-synamedia/common-ui-library';
import CopyContentSVG from '../../../../../assets/icons/CopyContentSVG';
import { useUserInfo } from '../../../../../auth/UserInfoProvider';
import Loader from '../../../../../components/Loader';
import { useQueriesWithAuthorization } from '../../../../../custom-hooks';
import {
	deleteApplication,
	getApplicationById,
	getApplicationSecret,
} from '../../../../../external-apis';
import { toast } from "react-toastify";
import { useDecodedJwtStore } from "../../../../../state-management";
import shallow from "zustand/shallow";
import useMutationWithAuthorization from "../../../../../custom-hooks/external-api-hooks/useMutationWithAuthorization";
import { useQueryClient } from "react-query";
import { useApplicationStore } from '../../../../../state-management/';
import { PopUp } from "@ip-synamedia/common-ui-library";

const getQueries = (applicationQueriesRes, accountId, staleTime) => {
	return Object.values(applicationQueriesRes).map((app) => {
		return {
			queryKey: ['get-application-by-id', app.applicationId],
			query: getApplicationById(app.applicationId, app.projectId, accountId),
			staleTime: staleTime
		};
	});
};

const getSecretQueries = (applicationQueriesRes, accountId, staleTime) => {
	return Object.values(applicationQueriesRes).map((app) => {
		return {
			queryKey: ['get-application-secret', app.applicationId],
			query: getApplicationSecret(accountId, app.projectId, app.applicationId),
			staleTime: staleTime
		};
	});
};

const ApplicationsContainer = (props) => {
	const { accountId } = useUserInfo();
	const { accountsList, staleTime, projectsData, hasSecretAccess } = props;
	const { mutateAsync } = useMutationWithAuthorization();
	const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
	const [currApplication, setCurrApplication] = useState();
	const deleteMessage = "Are you sure you want to delete this application? this action can not be undone.";
	const queryClient = useQueryClient();

	const queries = getQueries(accountsList, accountId, staleTime);

	const applicationsTable = useQueriesWithAuthorization(queries);

	const secretQueries = getSecretQueries(accountsList, accountId, staleTime);
	// eslint-disable-next-line
    const applicationsSecret = hasSecretAccess ? useQueriesWithAuthorization(secretQueries) : [];
	
	const [decodedJwt] = useDecodedJwtStore(
		(state) => [state.decodedJwt],
		shallow
	);

	const [setCurrentApplicationData, setIsApplicationEditActive] = useApplicationStore(state => [state.setCurrentApplicationData, state.setIsApplicationEditActive]);

	const allowsApplicationDeletion = decodedJwt?.scope?.includes("account:application:delete");
	
	if (!applicationsTable.every(key => !key.isLoading) || !applicationsSecret.every(key => !key.isLoading)) {
		if (!applicationsTable.every(key => key.isFetchedAfterMount === true))
			return <div className={styles.appicaitonsLoader}><Loader/></div>;
	}

	if (applicationsTable.length > 0 && applicationsTable.every(key => key.isError)) {
		const error = applicationsTable.map(key => key.error).join(',');

		return <div><span id="applications_info_error">Error fetching data:</span>{" "}{error}</div>;
	}

	const handleCopyClientId = (data) => {
		if (data) {
			navigator.clipboard.writeText(data);
			toast.info(`Client id copied to the clipboard`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false
			});
		} else {
			toast.warning(`Unable to copy the client id`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false
			});
		}

	};

	const tableData = [];

	applicationsTable.forEach(key => {
		const data = key?.data;

		if (key?.data && Object.keys(key?.data).length)
			tableData.push({
				application: data.name,
				space: projectsData[data.projectId]?.displayName,
				permissions: data.permissions,
				clientId: data.clientId,
				projectId: data.projectId,
				applicationId: data.id
			});
	});

	const getSecretByClientId = (clientId) => {
		let secretKey = "";

		applicationsSecret.forEach(key => {
			if (key?.data?.client_secret && key?.data?.client_id === clientId) {
				secretKey = key.data.client_secret;
			}
		});

		return secretKey;
	};

	const handleCopySecret = (clientId) => {
		const secretKey = getSecretByClientId(clientId);

		if (secretKey !== "") {
			navigator.clipboard.writeText(secretKey);
			toast.info(`Secret copied to the clipboard`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false
			});
		} else {
			toast.warning(`Unable to copy the secret key`, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false
			});
		}
	};

	const handleDeleteApplication = (applicationId, projectId) => {

		const deleteApplicationPromise = mutateAsync(deleteApplication(accountId, projectId, applicationId));

		toast.promise(
			deleteApplicationPromise,
			{
				pending: "Deleting application...",
				success: {
					render() {
						queryClient.invalidateQueries(['get-application-by-account-id-and-project-id', accountId, projectId]);

						return <div id={'deleteApplication_toast_text'}>deleted successfully</div>;
					},
				},
				error: {
					render({ data: error = "{}" }) {

						return <div id={'deleteApplication_toast_textError'}>{error.message}</div>;
					},
				},
			},
			{
				position: toast.POSITION.TOP_CENTER,
			}
		);
	};

	const RowActions = ({ row: { original } }) => {
		const copySecretAction = {
			id: "copy-secret",
			label: "Copy Secret",
			icon: <CopyContentSVG />,
		};
		
		const deleteAction = {
			id: "delete-application",
			label: "Delete",
			icon: <TrashIcon width="14px" height="14px"/>,
		};
		
		const editAction = {
			id: "edit-application",
			label: "Edit",
			icon: <EditIcon width="14px" height="14px"/>,
		};

		const options = useMemo(() => {

			const isCopyDisabled = original?.clientId === "";

			const res = [];

			copySecretAction["disabled"] = isCopyDisabled;
			copySecretAction["info"]= isCopyDisabled ? 'Cant perform this action at the moment' : '';

			hasSecretAccess && res.push(copySecretAction);
			allowsApplicationDeletion && res.push(deleteAction);
			
			if(decodedJwt?.scope?.includes("account:application:update") || decodedJwt?.scope?.includes("account-admin:application:update"))
				res.push(editAction);

			return res;
			// eslint-disable-next-line
		}, [original]);

		return (
			<>
				<Dropdown
					align="end"
					options={options}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onValueChange={(value) => {
						if (value === copySecretAction?.id) {
							handleCopySecret(original?.clientId);
						}
						if (value === deleteAction?.id) {
							setDeletePopUpOpen(true);
							setCurrApplication(original);

						}
						if(value === editAction?.id) {
							const appData = { ...original, secretKey: getSecretByClientId(original.clientId) };

							setCurrentApplicationData(appData);
							setIsApplicationEditActive(true);
						}
					}}
				>
					<EllipsisIcon style={{ cursor: "pointer" }} height="14px" width="14px"/>{" "}
				</Dropdown>
			</>
		);
	};

	let columns = [
		{
			Header: 'Application',
			accessor: 'application',
			disableSortBy: true,
			width: 100
		},
		{
			Header: 'Space',
			accessor: 'space',
			disableSortBy: true,
			width: 80
		},
		{
			Cell: ({ row: { original } }) => {
				const extraTags = [];

				const handleExtras = (data) => {
					extraTags.push(data);
				};

				return (
					<div className={styles.permissionElement} data-testid="permissions_container">
						{original?.permissions?.map((permission, index) => (
							index > 2 ? handleExtras(permission)
								:
								<Tag id={permission} key={permission}>{permission}</Tag>
						))}
						{extraTags.length ? <Tooltip id="extraTagsTooltip" type="standard" content={
							<div className={styles.extraTags}>
								{extraTags.length && extraTags.map(permission => (
									<Tag id={permission} key={permission}>{permission}</Tag>
								))}
							</div>
						}
						>
							<Tag id="extraTags">+{extraTags.length}</Tag>
						</Tooltip>
							: null}
					</div>
				);
			},
			Header: 'Permissions',
			accessor: 'permissions',
			disableSortBy: true,
			width: 320
		},
		{
			Cell: ({ row: { original } }) => (
				<div className={styles.clientIdContainer}>
					<span data-testid="clientId">{original.clientId}</span>
					<Tooltip id="copyIcon" content="Copy">
						<span data-testid="copy_clientId" className={styles.copyIcon}><CopyContentSVG
							onClick={() => handleCopyClientId(original.clientId)}/></span>
					</Tooltip>
				</div>
			),
			Header: 'Client ID',
			accessor: 'clientId',
			disableSortBy: true,
			width: 170
		},
	];

	return (
		<div className={styles.applicationsContainer}>
			<Table
				id="Applications"
				RowActions={RowActions}
				columns={columns}
				disableFilters={true}
				data={tableData}
				defaultPageSize={15}
				label="Applications"
				rowsPerPageOptions={[
					10,
					15,
					25,
					50,
					100
				]}
				style={{
					height: '100%',
				}}
			/>
			<div id={`applications_testPermissions_${allowsApplicationDeletion}_${hasSecretAccess}`}/>
			<PopUp
				onClose={() => setDeletePopUpOpen(false)}
				open={deletePopUpOpen}
				size="small"
				title="Delete Application"
				description={deleteMessage}
				onAccept={() => {
					setDeletePopUpOpen(false);
					handleDeleteApplication(currApplication.applicationId, currApplication.projectId);
				}}
				onCancel={() => setDeletePopUpOpen(false)}
				showFooter={true}
				cancelButtonLabel={'CANCEL'}
				acceptButtonLabel={allowsApplicationDeletion ? 'DELETE': 'OK'}
			/>
		</div>
	);
};

export default ApplicationsContainer;