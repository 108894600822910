import { Step, StepConnector, StepLabel, Stepper } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useColorLibStepIconStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'transparent',
		color: 'hsla(0, 0%, 56%, 1)',
		width: 21,
		height: 21,
		display: 'flex',
		borderRadius: '50%',
		border: "solid 1px hsla(0, 0%, 56%, 1)",
		justifyContent: 'center',
		alignItems: 'center',
		position: "relative",
	},
	active: {
		color: 'hsla(0, 0%, 100%, 1)',
		backgroundColor: 'hsla(217, 100%, 60%, 1)',
		borderColor: 'hsla(217, 100%, 60%, 1)',
	},
	completedIconContainer: {
		color: 'hsla(138, 64%, 50%, 1)',
		backgroundColor: 'white',
		position: "absolute",
		top: -2,
		right: -2,
		display: "flex",
		justifyContent: 'start',
		alignItems: 'start',
		borderRadius: '50%',
	},
	iconLabel: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 14
	}
}));

function ColorLibStepIcon({ icon, active, completed }) {
	const styles = useColorLibStepIconStyles();

	return (
		<div className={clsx(styles.root, { [styles.active]: active })}>
			<div className={styles.iconLabel}>
				{icon}
			</div>
			{completed && <div className={styles.completedIconContainer}>
				<CheckCircleIcon sx={{ width: '10px', height: '10px' }}/>
			</div>}
		</div>
	);
}

const useStyles = makeStyles(() => ({
	label: {
		fontFamily: 'Source Sans Pro',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		textTransform: 'uppercase',
	},
	active: {
		color: 'hsla(217, 100%, 60%, 1)'
	},
	completed: {
		color: 'hsla(0, 0%, 56%, 1)'
	},
}));

const sxStyles = {
	firstStep: {
		// paddingLeft: 0
	},
	stepConnector: {
		flex: 'none',
		width: '75px'
	}
};

export default function AppSteps({ steps, activeStep, alternativeLabel, sx = {} }) {
	const styles = useStyles();

	return (
		<Stepper
			activeStep={activeStep ?? -1}
			alternativeLabel={alternativeLabel}
			sx={{ ...sx?.stepper }}
			connector={<StepConnector sx={{ ...sxStyles.stepConnector, ...sx?.stepConnector }}/>}
		>
			{steps.map((label, index) => {
				const isActive = activeStep === index;
				const isCompleted = activeStep > index;

				return (
					<Step key={label} sx={{ ...(index === 0 && sxStyles.firstStep), ...sx?.step }}>
						<StepLabel
							StepIconProps={{ active: isActive, completed: isCompleted }}
							sx={{ ...sx?.stepLabel }}
							StepIconComponent={ColorLibStepIcon}
						>
							<div className={clsx(styles.label, {
								[styles.active]: isActive,
								[styles.completed]: isCompleted,
							})}>
								{label}
							</div>
						</StepLabel>
					</Step>
				);
			})}
		</Stepper>
	);
}
