import React, { useState } from 'react';
import { useDeepLinkStore, useUsersInvitationsStore } from '../../../../../state-management';
import Header from './Header';
import styles from "./InviteUsers.module.css";
import InviteUsersContainer from './InviteUsersContainer';
import shallow from "zustand/shallow";

const breadCrumbItems= [{
	id: "users",
	label: "Users"
},{
	id: "inviteUser",
	label: "Invite Users"
}];

const InviteUsers = ({ setIsUserInviteActive }) => {
	const [resetState] = useUsersInvitationsStore(state => [state.reset], shallow);
	const setIsInviteUser = useDeepLinkStore(state => state.setIsInviteUser);
	const [selectedCrumb,setSelectedCrumb] = useState(breadCrumbItems[1]);
	const handleBreadCrumbClick = (event) => {
		if(event.target.id === "users") {
			setSelectedCrumb(breadCrumbItems[0]);
			returnToAccountManagement(); 
		}
	};

	const returnToAccountManagement = () => {
		resetState();
		setIsUserInviteActive(false);
		setIsInviteUser(false);
	};

	return (
		<div className={styles.inviteUsersRootContainer}>
			<Header
				selectedCrumb={selectedCrumb}
				breadCrumbItems={breadCrumbItems}
				handleBreadCrumbClick={handleBreadCrumbClick}
				title="Invite Users"
			/>
			<InviteUsersContainer returnToAccountManagement={returnToAccountManagement}/>
		</div>
	);
};

export default InviteUsers;