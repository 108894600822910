import React, { useState, useEffect } from 'react';
import { useUsersInvitationsStore } from "../../../../../../../state-management";
import shallow from "zustand/shallow";
import styles from "./ProductsRoles.module.css";
import Roles from './Roles';

const ProductsRoles = (props) => {
	const { projectId, productRoles, isProductAdmin =false, isEdit=false, initialState={}, parentFirstRender = false, setParentFirstRender  } = props;

	const [productRolesByProjectId, setProductRolesByProjectId, productAdminRolesIds, setProductAdminRolesIds] = useUsersInvitationsStore(state => [state.productRolesByProjectId, state.setProductRolesByProjectId, state.productAdminRolesIds, state.setProductAdminRolesIds], shallow);
	const [selectedProductsIds, setSelectedProductsIds] = useState(new Set());
	const [firstRender, setFirstRender] = useState(true);
	const [currProductsRoles, setCurrProductsRoles] = useState({});
	const [currProductAdminRoles, setCurrProductAdminRole] = useState({});

	useEffect(() => {
		if(!projectId || !productRoles) {
			// setCurrProductsRoles(undefined);
			setFirstRender(false);
		}else if(!isProductAdmin) {
			if(parentFirstRender) {
				let _filteredRoles = {};

				productRoles && productRoles.forEach(product => {
					_filteredRoles[product.id] = isEdit && productRolesByProjectId?.[projectId] ? productRolesByProjectId[projectId].filter(role => role.productId === product.id): [];
				});
				setCurrProductsRoles(_filteredRoles);
				setParentFirstRender(false);
				setFirstRender(false);
			}
			else if(firstRender && productRolesByProjectId?.[projectId]) {
				let _filteredRoles = {};

				productRoles && productRoles.forEach(product => {
					_filteredRoles[product.id] = productRolesByProjectId[projectId].filter(role => role.productId === product.id);
				});
				setCurrProductsRoles(_filteredRoles);
				setFirstRender(false);
			}
		}
		//eslint-disable-next-line
	}, [productRoles, projectId, initialState, isEdit, isProductAdmin, parentFirstRender, productRolesByProjectId, firstRender, selectedProductsIds]);

	useEffect(() => {
		projectId && productRolesByProjectId?.[projectId] && setSelectedProductsIds(new Set([...productRolesByProjectId[projectId].map(role => {
			return role?.productId ? role.productId : role;
		})]
		));
	}, [productRolesByProjectId, projectId]);

	return (
		<div className={styles.spacesAndRoalsContainer} id={(isEdit ? 'edit':'invite') + "User_spacesAndRoalsContainer"}>
			{productRoles.length > 0 && productRoles.map((role,index) => (
				<Roles 
					key={`${projectId}_${index}`} 
					row={role} 
					currProductsRoles={currProductsRoles} 
					currProductAdminRoles={currProductAdminRoles}
					setCurrProductsRoles={setCurrProductsRoles}
					parentFirstRender={parentFirstRender}
					setParentFirstRender={setParentFirstRender} 
					selectedProductsIds={selectedProductsIds}
					setSelectedProductsIds={setSelectedProductsIds}
					isProductAdmin={isProductAdmin}
					productRolesByProjectId={productRolesByProjectId}
					projectId={projectId}
					setProductRolesByProjectId={setProductRolesByProjectId}
					productAdminRolesIds={productAdminRolesIds}
					setCurrProductAdminRole={setCurrProductAdminRole}
					setProductAdminRolesIds={setProductAdminRolesIds}
					styles={styles}
				/>
			))}
		</div>
	);
};

export default ProductsRoles;