import React, { useState, useEffect } from 'react';
import styles from "./EditUserForm.module.css";
import shallow from "zustand/shallow";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useUsersInvitationsStore } from "../../../../../../../state-management";
import { editAccountRoles, editUserDetails, editProjectRoles } from "../../../../../../../external-apis";
import ProductsPerProjectRoles from "../../../InviteUsers/InviteUsersContainer/ProductsPerProjectRoles";
import ProductAdminRoles from "../../../InviteUsers/InviteUsersContainer/ProductAdminRoles";
import AccountRoles from "../../../InviteUsers/InviteUsersContainer/AccountRoles";
import { Tag, TextInput } from '@ip-synamedia/common-ui-library';
import { useUserInfo } from '../../../../../../../auth/UserInfoProvider';
import Loader from '../../../../../../../components/Loader';
import useEditPostCall from "./useEditPostCall";
import UsersSummary from '../../../UsersSummary';
import userInfoCompare from './tools';
import useStore from "../../../../../../../state-management/mainContainerStore";

const MAX_CHAR = 100;

const EditUserForm = ({
	userData,
	projects,
	rolesQueries,
	accountRoles,
	userAccountRoles,
	firstRender,
	setFirstRender,
	returnToAccountManagement,
	userInvitationData,
	initialState,
	setInitialState,
	productRoles,
	productAdminRoles
}) => {
	let apiSuccessCount = 0;
	let apiFailedCount = 0;
	const filteredProjectsIds = [];
	let [filterProductRoles, setFilterProductRoles] = useState([]);
	
	const {
		data: tenantsPerProjects = {},
	} = useStore(state => state.tenantsPerProjectsPromise, shallow);

	const queryClient = useQueryClient();

	const handleSuccessPostApiCall = (response, apiContext) => {
		if (response) apiSuccessCount += 1;
	};

	const handleFailedPostApiCall = (error, apiContext) => {
		if (error) {
			apiFailedCount += 1;
		}
	};

	const { mutateAsync } = useEditPostCall(handleSuccessPostApiCall, handleFailedPostApiCall);
	const [
		setJobTitle,
		setUserName,
		setAccountRolesIds,
		setEmail,
		setProductRolesByProjectId,
		setProductAdminRolesIds
	] = useUsersInvitationsStore(state => [
		state.setJobTitle,
		state.setUserName,
		state.setAccountRolesIds,
		state.setEmail,
		state.setProductRolesByProjectId,
		state.setProductAdminRolesIds
	], shallow);

	const [hasPermission, setHasPermission] = useState(false);
	const { accountId } = useUserInfo();
	const [changesMadeCmp, setChangesMadeCmp] = useState({});
	const [isUpdating, setIsUpdating] = useState(false);

	useEffect(() => {
		if (!firstRender) return;
		const filteredAccountRoles = userAccountRoles.filter(role => !role?.isProductAdmin).map(value => value.id);
		const filteredProductAdminRoles = userAccountRoles.filter(role => role?.isProductAdmin).map(value => value.id);

		setAccountRolesIds(new Set(...[filteredAccountRoles]));
		setProductAdminRolesIds(new Set(...[filteredProductAdminRoles]));

		let _productRolesByProjectId = {};

		projects?.forEach((project, index) => {
			_productRolesByProjectId[project?.id] = rolesQueries?.[index]?.data || [];
		});

		setProductRolesByProjectId(_productRolesByProjectId);

		setInitialState({
			email: userData.email,
			accountRolesIds: new Set(...[filteredAccountRoles]),
			productAdminRolesIds: new Set(...[filteredProductAdminRoles]),
			productRolesByProjectId: _productRolesByProjectId,
			jobTitle: userData.userRole,
			userName: userData.userName
		});

		userData?.userName && setUserName(userData.userName);
		userData?.userRole && setJobTitle(userData.userRole);
		userData?.email && setEmail(userData.email);
		//eslint-disable-next-line
    }, [userData, projects, rolesQueries, userAccountRoles, firstRender]);

	useEffect(() => {
		const finalProjectRoles = {};

		const projectLabels = {};

		projects.forEach(project =>  {
			projectLabels[project.id] = project.label;
		});

		Object.keys(tenantsPerProjects).forEach(projectId => {
			const productsOfProject = tenantsPerProjects[projectId];

			productsOfProject.forEach(p => {
				const theProduct = productRoles.find(product => (product.id === p) );

				if (theProduct) {
					if(!finalProjectRoles[projectId]) {
						finalProjectRoles[projectId] = {
							id: projectId,
							label: projectLabels[projectId],
							productRoles: [theProduct]
						};
					} else {
						finalProjectRoles[projectId].productRoles.push(theProduct);
					}
				}
			});
		});

		const finalProjectRolesArray = Object.keys(finalProjectRoles).map(projectId => finalProjectRoles[projectId]);

		setFilterProductRoles(finalProjectRolesArray);

	}, [productRoles ,projects, tenantsPerProjects]);

	useEffect(() => {
		const hasProductRoleSelected = () => {
			const productRolesByProjectId = userInvitationData?.productRolesByProjectId || {};

			return Object.values(productRolesByProjectId).some(projectRoles => projectRoles.length > 0);
		};

		setHasPermission(hasProductRoleSelected() || userInvitationData.accountRolesIds?.size > 0 || userInvitationData.productAdminRolesIds?.size > 0);
	}, [userInvitationData]);

	useEffect(() => {
		Object.keys(initialState).length > 0 && setChangesMadeCmp(userInfoCompare(userInvitationData, initialState));
		//eslint-disable-next-line
    }, [initialState, userInvitationData]);

	const generalDetailsChange = (event, setFunction) => {
		if (firstRender === true) setFirstRender(false);
		let substringMessage = event.target.value;

		if (event.target.value?.length > MAX_CHAR) {
			substringMessage = substringMessage.substring(0, MAX_CHAR).trim();
		}
		setFunction(substringMessage);

	};

	const handleParamsEdit = (apiCallsList) => {
		const isJobTitleEdit = userInvitationData.jobTitle !== initialState.jobTitle;
		const isUserNameEdit = userInvitationData.userName !== initialState.userName;

		try {
			isJobTitleEdit && apiCallsList.push(editUserDetails(accountId, userData.id, 'jobTitle', userInvitationData.jobTitle));
			isUserNameEdit && apiCallsList.push(editUserDetails(accountId, userData.id, 'name', userInvitationData.userName));
		} catch (e) {
			console.log(e.message);
		}
	};

	const handleAccountRolesEdit = (apiCallsList) => {
		const isAccountEdit = !((userInvitationData.accountRolesIds.size === initialState.accountRolesIds.size) &&
            [...userInvitationData.accountRolesIds].every((x) => initialState.accountRolesIds.has(x)));
		const isProductAdminEdit = !((userInvitationData.productAdminRolesIds.size === initialState.productAdminRolesIds.size) &&
            [...userInvitationData.productAdminRolesIds].every((x) => initialState.productAdminRolesIds.has(x)));

		try {
			(isAccountEdit || isProductAdminEdit) && apiCallsList.push(editAccountRoles(accountId, userData.id, [...userInvitationData.productAdminRolesIds, ...userInvitationData.accountRolesIds]));
		} catch (e) {
			console.log(e.message);
		}

	};

	const handleProductPerProjectRoles = (apiCallsList) => {

		const filteredProjects = projects.filter(project => {
			const roles = userInvitationData.productRolesByProjectId[project.id];
			const initRoles = initialState.productRolesByProjectId[project.id];

			return !(roles.length === initRoles.length) || !roles.every(element => {

				return initRoles.find(x => x.id === element.id);
			});
		});

		filteredProjects.forEach((project, index) => {
			filteredProjectsIds.push(filteredProjects[index].id);
			apiCallsList.push(editProjectRoles(accountId, project.id, userData.id, userInvitationData.productRolesByProjectId[project.id].map(role => role.id)));
		});

	};

	const callApis = (apiCallsList) => {
		const toastOptions = {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false
		};

		apiCallsList.length && apiCallsList.forEach((call, index) => {
			mutateAsync(call, {
				onSettled: async () => {
					if (index === apiCallsList.length - 1) {

						const isDone = () => {
							if (apiCallsList.length === (apiSuccessCount + apiFailedCount)) {
								setIsUpdating(false);
								if (apiSuccessCount && apiFailedCount) {

									toast.info(`Edited ${apiSuccessCount} ${apiSuccessCount > 1 ? 'fields' : 'field'}`, { ...toastOptions , ...{ toastId: "testIdInfo" } });
									toast.error(`Failed to edit ${apiFailedCount} ${apiFailedCount > 1 ? 'fields' : 'field'}`, { ...toastOptions , ...{ toastId: "testIdError" } });
									handleCancel(false);
								} else if (apiFailedCount) {
									toast.error("Something went wrong while trying to save. Please try again or contact customer support.", { ...toastOptions, ...{ toastId: "testIdError" } });
								} else {
									toast.success("The user details have been successfully updated", { ...toastOptions , ...{ toastId: "testIdSuccess" } });
									handleCancel(false);
								}

							} else {
								setTimeout(() => isDone(), 500);
							}
						};

						isDone();
					}
				}
			}).catch(() => {
			});
		});
	};

	const handleEdit = async () => {
		const apiCallsList = [];

		setIsUpdating(true);
		handleParamsEdit(apiCallsList);
		handleAccountRolesEdit(apiCallsList);
		handleProductPerProjectRoles(apiCallsList);

		if (apiCallsList.length)
			callApis(apiCallsList);

	};
	const handleCancel = () => {
		queryClient.invalidateQueries(['users-active', accountId]);
		queryClient.invalidateQueries(['userRoles', accountId, userData.id]);
		if (filteredProjectsIds.length) {
			filteredProjectsIds.forEach(id => {
				queryClient.invalidateQueries(['get-user-roles-by-project', id, userData.id]);
			});
		}
		setFirstRender(true);
		setInitialState({
			email: "",
			accountRolesIds: new Set(),
			productAdminRolesIds: new Set(),
			productRolesByProjectId: {},
			jobTitle: "",
			userName: ""
		});
		returnToAccountManagement();

	};

	const isParameterEmpty = () => {
		return userInvitationData?.userName === '' || userInvitationData?.jobTitle === '';
	};

	const getErrorText = (data) => {
		let errorText = "";

		if (firstRender === false) {
			if (data === "") {
				errorText = "Field can not be empty";
			} else if (data?.length >= MAX_CHAR) {
				errorText = "Character limit reached";
			}
		}

		return errorText;
	};

	return (
		<div className={styles.editUsersContainer}>
			<div className={styles.editFromContainer}>
				<div className={styles.generalDetailsContainer}>
					<div id={'editUser_formContainer_title'} className={styles.generalDetailsTitle}>
						{'General Details'}
						{!changesMadeCmp?.fieldsCmp ?
							<div id={"general_details_modified_tag_container"}><Tag id={"general_details_modified_tag"}
								label="modified" variant="info"
								type="status"/></div> : null}
					</div>
					<div className={styles.userInputContainer}>
						<div className={styles.userInputRow}>
							<div className={styles.inputTextContainer}>
								<TextInput style={{ width: "473px" }} id={'editUser_emailAddress_textInput'}
									label={'Email address'} value={userData?.email} disabled={true}
									readOnly={true} data-disabled={true}/>
							</div>
							<div className={styles.inputTextContainer}>
								<TextInput style={{ width: "473px" }} id={'editUser_invitedOn_textInput'}
									label={'Invited On'} value={userData?.joined} disabled={true} readOnly={true}
									data-disabled={true}/>
							</div>
						</div>
						<div className={styles.userInputRow}>
							<div id={'editUser_userName_textInputContainer'} className={styles.inputTextContainer}>
								<TextInput
									style={{ width: "473px" }}
									id={"editUser_userName_textInput"}
									value={firstRender ? userData?.userName : userInvitationData?.userName}
									label={'Name'}
									onChange={(event) => generalDetailsChange(event, setUserName)}
									error={userInvitationData?.userName === ''}
									helperText={getErrorText(userInvitationData?.userName)}
								/>
							</div>
							<div id={'editUser_jobTitle_textInputContainer'} className={styles.inputTextContainer}>
								<TextInput
									style={{ width: "473px" }}
									id={"editUser_jobTitle_textInput"}
									value={firstRender ? userData?.userRole : userInvitationData?.jobTitle}
									label={'Job title'}
									onChange={(event) => generalDetailsChange(event, setJobTitle)}
									error={userInvitationData?.jobTitle === ''}
									helperText={getErrorText(userInvitationData?.jobTitle)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.editRolesContainer}>
					<div id={'editUser_content_rolesTitle'} className={styles.editRolesHeader}>Assign Roles <span
						className={styles.asterisk}>*</span></div>
					{accountRoles.length ? <div className={styles.accountRolesContainer}>
						<div className={styles.accoutRolesTitleContainer}>
							<div id={'editUser_content_accountRolesTitle'} className={styles.accountRolesTitle}>Account
                                Roles
							</div>
							{!changesMadeCmp?.accountRoleCmp ?
								<Tag id={"account_role_modified_tag"} label="modified" variant="info"
									type="status"/> : null}
						</div>
						<AccountRoles accountRoles={accountRoles} setFirstRender={setFirstRender}
							firstRender={firstRender} isEdit={true}/>
					</div> : null}
					{productAdminRoles.length ?
						<ProductAdminRoles productAdminRoles={productAdminRoles} parentFirstRender={firstRender}
							setParentFirstRender={setFirstRender} isEdit={true}
							changesMadeCmp={!changesMadeCmp?.productAdminRolesCmp}/> : null}
					{projects.length && productRoles.length && filterProductRoles.length ? <div style={{ background: "#FFFFFF", height: "360px", width: "100%", borderRadius: "8px" }}>
						<ProductsPerProjectRoles projects={projects} productRoles={productRoles} filterProductRoles={filterProductRoles} isEdit={true}
							parentFirstRender={false} setParentFirstRender={setFirstRender}
							changesMadeCmp={changesMadeCmp}/> </div> : null}
				</div>
			</div>
			<UsersSummary summaryTitle="Summary" isEdit={true} handleProcessing={handleEdit} handleCancel={handleCancel}
				userInvitationData={userInvitationData} accountRolesData={accountRoles} tenantsPerProjects={tenantsPerProjects}
				productAdminRolesData={productAdminRoles} projects={projects} isProcessing={isUpdating}
				processDisabled={!hasPermission || firstRender || isParameterEmpty() || isUpdating || changesMadeCmp.finalCmp || userInvitationData.accountRolesIds.size === 0}/>
			{isUpdating ? <div className={styles.loaderBackDrop}><Loader/></div> : null}
		</div>
	);
};

export default EditUserForm;