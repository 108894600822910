import React from "react";
import styles from "./AppBreadcrumbs.module.css";
import { BreadCrumbs } from "@ip-synamedia/common-ui-library";

export const AppBreadcrumbs = ({
	items,
	handleClick,
	selectedCrumb,
	...rest
}) => {
	return (
		<BreadCrumbs {...rest}>
			{Array.isArray(items) && items.length > 0
				? items.map(({ id,label }) => {
					return (
						<div key={id} className={styles.crumb} id={`${rest.id}_${id}`}>
							<div
								id={id}
								className={
									selectedCrumb.id === id ? styles.selectedCrumbLabel : styles.crumbLabel
								}
								onClick={handleClick}
							>
								{label}
							</div>
						</div>
					);
				})
				: null}
		</BreadCrumbs>
	);
};
