import { useQuery } from "react-query";
import { fetchFn, lookupAliasByOrganizationId } from "../../external-apis";
// import { useCookies } from "react-cookie";
// import cookiesNames from "../../config/cookies";

const useAccountAlias = (organizationId) => {
	// const [cookies] = useCookies([cookiesNames.accountAlias, cookiesNames.accountOrgId]);
	// const accountAliasFromCookie = cookies?.[cookiesNames.accountAlias];
	// const accountOrgIdFromCookie = cookies?.[cookiesNames.accountOrgId];
	const {
		isLoading,
		isError,
		data: accountAliasFromApi,
		error
	} = useQuery(
		['organizationId', organizationId],
		({ signal }) => fetchFn(lookupAliasByOrganizationId(organizationId), { signal })(), {
			// enabled: (organizationId !== accountOrgIdFromCookie || accountAliasFromCookie == null) && organizationId != null
			staleTime: 60000
		});

	return {
		// accountAlias: organizationId === accountOrgIdFromCookie && accountAliasFromCookie != null ? accountAliasFromCookie : accountAliasFromApi,
		accountAlias: accountAliasFromApi,
		isLoading: isLoading,
		isError: isError,
		error: error
	};
};

export default useAccountAlias;
