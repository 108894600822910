import React, { useRef, useEffect } from 'react';

const Modal = ({ children, handleSetOpen }) => {
	const ref = useRef();

	useEffect(() => {
		const onBodyClick = (event) => {
			if (ref.current.contains(event.target)) {
				return;
			}
			handleSetOpen(false);
		};

		document.body.addEventListener('click', onBodyClick, { capture: true });

		return () => {
			document.body.removeEventListener('click', onBodyClick, { capture: true });
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div ref={ref} >
			{children && children}
		</div>
	);
};

export default Modal;

