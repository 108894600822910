import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { getAuth0Config } from "../../config";

const Auth0ProviderWithHistory = ({ children }) => {
	const { scope, domain, clientId, audience } = getAuth0Config();

	const navigate = useNavigate();

	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.pathname, { replace: true });
	};

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			redirectUri={`${window.location.origin}/callback`}
			onRedirectCallback={onRedirectCallback}
			scope={scope}
			audience={audience}
			useRefreshTokens={true}
		>
			{children}
		</Auth0Provider>
	);
};

export default Auth0ProviderWithHistory;

