import { getApiEndpoint } from '../../config';

const baseHeaders = {
	'content-type': 'application/json'
};

const getDeveloperPortalAccess = (body, params = {}) => {
	const { headers = {} } = params;

	return {
		method: "POST",
		url: `${getApiEndpoint()}/developerPortal/token`,
		headers: {
			...headers,
			...baseHeaders
		},
		body
	};
};

export {
	getDeveloperPortalAccess
};
