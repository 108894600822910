import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const useStyles = makeStyles((theme) => ({
	tooltip: {
		margin: '0 8px',
		color: 'black',
		fontSize: 12,
		fontWeight: 400,
	},
}));

const WhiteTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: "white",
		boxShadow: theme.shadows[5],
	},
	arrow: {
		color: "white",
	}
}))(Tooltip);

export default function AppTooltip( { children, title, disabled=false } ) {
	const styles = useStyles();

	return disabled? <div>{children}</div>:(<WhiteTooltip arrow placement="top" title={<div className={styles.tooltip}>{title}</div>}>
		{ children }
	</WhiteTooltip>);
}