import React, { useEffect } from 'react';
import { useAppPanelStore } from "../../state-management";
import { useUsersStore } from "../../state-management";
import MainContainer from "../../components/MainContainer";
import AppSidebar from "../../components/AppSidebar";
import MainPanel from '../../components/MainPanel';
import { makeStyles } from "@material-ui/core/styles";
import AccessDenied from "../../components/AccessDenied";
import { useUserInfo } from "../../auth/UserInfoProvider";
// import AppHeader from "./AppHeader";

const useStyles = makeStyles((theme) => {
	const sidebarWidth = 280;
	const headerHeight = 64;
	const contentLeft = sidebarWidth - 42;

	return {
		rootContainer: {
			height: '100%',
			// width: '100%',
			display: 'flex',
		},
		headerContainer: {
			minHeight: headerHeight,
			maxHeight: headerHeight,
			width: '100%',
		},
		sideBarContainer: {
			minWidth: sidebarWidth,
			maxWidth: sidebarWidth,
			height: '100%',
		},
		contentContainer: {
			backgroundColor: "#FFFFFF",
			position: "absolute",
			borderTopLeftRadius: 20,
			top: headerHeight,
			left: contentLeft,
			right: 0,
			bottom: 0,
			overflow: "hidden",
			width: `calc(100% - ${contentLeft}px)`,
			height: `calc(100% - ${headerHeight}px)`,

			// TODO: replace/remove when implementing small screen design (minWidth)
			minWidth: theme.breakpoints.values.sm - contentLeft,
		},
	};
});

function AccountPage() {
	const styles = useStyles();

	const [setActiveMainMenuByRoutePath] = useAppPanelStore(state => [state.setActiveMainMenuByRoutePath]);
	const [resetUserStore, setActiveTab] = useUsersStore(state => [state.reset, state.setActiveTab]);
	const { scope } = useUserInfo();

	useEffect(() => {
		setActiveMainMenuByRoutePath('users');
		setActiveTab("activeUsers");

		return () => {
			resetUserStore();
		};
		// eslint-disable-next-line
	}, []);

	if (scope && !scope?.includes("account:user:list")) {
		return <AccessDenied/>;
	}

	document.title = 'Synamedia Cloud Portal | Users';

	return (
		<MainContainer>
			<div className={styles.sideBarContainer}>
				<AppSidebar/>
			</div>
			<div className={styles.contentContainer}>
				<MainPanel/>
			</div>
		</MainContainer>
	);
}

export default AccountPage;
