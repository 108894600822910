import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";

import UserInfoProvider from "./auth/UserInfoProvider";

const queryClient = new QueryClient();

/**
 * Use to specify style per screen size
 *    [theme.breakpoints.up('sm')]: {},
 *    [theme.breakpoints.up('md')]: {},
 *    [theme.breakpoints.up('lg')]: {},
 *    [theme.breakpoints.up('xl')]: {},
 */
const theme = createTheme({
	breakpoints: {
		values: {
			xs: 768,
			sm: 1280,
			md: 1366,
			lg: 1600,
			xl: 1920,
		},
	},
});

export default function AppWrapper({ children }) {

	return (
		<BrowserRouter>
			<Auth0ProviderWithHistory>
				<QueryClientProvider client={queryClient}>
					<CookiesProvider>
						<ThemeProvider theme={theme}>
							<UserInfoProvider>
								{children}
							</UserInfoProvider>
						</ThemeProvider>
					</CookiesProvider>
				</QueryClientProvider>
			</Auth0ProviderWithHistory>
		</BrowserRouter>
	);
}
