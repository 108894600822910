import { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

const LogoutPage = () => {
	const { logout } = useAuth0();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		logout({ returnTo: searchParams?.get('redirectUrl') ?? window?.location?.origin });
	}, [logout, searchParams]);

	return null;
};

export default LogoutPage;
