import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getAll = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/tenants`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};
const getConfiguration = (id, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/tenancyManager/productConfiguration/${id}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getById = (id, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/tenancyManager/tenancies/${id}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getByProjectId = (projectId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/projects/${projectId}/tenants`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const deleteTenant = (accountId, projectId, tenantId, params = {}, view = '' ) => {
	const { headers = {} } = params;

	return {
		method: 'DELETE',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/tenants/${tenantId}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getTenantByAccountIdAndProjectId = (accountId, projectId, params = {}, view = '' ) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/tenants?view=${view}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const postTenant = (tenant, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/tenancyManager/createTenancy`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: JSON.stringify(tenant)
	};
};

const putTenant = (accountId, projectId, tenantId, tenant, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'PUT',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/tenants/${tenantId}`,
		headers: {
			...headers,
			...baseHeaders
		},
		body: JSON.stringify(tenant)
	};
};

const getTenantUiLink = (accountId, projectId, tenantId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/tenants/${tenantId}/uilink`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

export {
	getAll,
	getById,
	getByProjectId,
	postTenant,
	putTenant,
	getConfiguration,
	getTenantByAccountIdAndProjectId,
	deleteTenant,
	getTenantUiLink
};
