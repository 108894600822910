import React from "react";
import clsx from "clsx";
import styles from "./NavBarIconButton.module.css";

const NavBarIconButton = ({ selected = false, children, onClick=() => {} }) => {
	return (
		<div className={clsx(styles.root, selected && styles.selected)} tabIndex="1" onClick={onClick}>
			{children}
		</div>
	);
};

export default NavBarIconButton;