import create from "zustand";

const initialState = Object.freeze({
	activeTabComponent: undefined,
	activeTabData: undefined,
	activeTab: "",
	selectedSpace: {},
	accountId: undefined,
	isUserInviteActive: false,
	isUserEditActive: false,
	currentUserData: {}
});

const useStore = create((set, get) => ({
	...initialState,

	setActiveTabComponent: (val) => {
		set(() => ({
			activeTabComponent: val
		}));
	},
	setActiveTab: (val) => {
		set(() => ({
			activeTab: val
		}));
	},
	setAccountId: (val) => {
		set(() => ({
			accountId: val
		}));
	},
	setIsUserInviteActive: (val) => {
		set(() => ({
			isUserInviteActive: val
		}));
	},
	setIsUserEditActive: (val) => {
		set(() => ({
			isUserEditActive: val
		}));
	},
	setActiveTabData: (val) => {
		set(() => ({
			activeTabData: val 
		}));
	},
	setCurrentUserData : (val) => {
		set(() => ({
			currentUserData: val
		}));
	},
	reset: () => {
		set(() => ({ ...initialState }));
	},
}));

export default useStore;
