import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getDeveloperPortalAccess } from "../../external-apis/account-api/developerPoratlAccess";
import { useQueryWithAuthorization } from "../../custom-hooks";
import MainContainer from "../../components/MainContainer";
import Loader from "../../components/Loader";
import { useSearchParams } from "react-router-dom";
import { getDeveloperPortalEndpoint } from "../../config";
import getMessageFromError from "../../utils/API-calls";

function DeveloperPortalPage() {

	const { user } = useAuth0();

	const [searchParams] = useSearchParams();

	const {
		isLoading,
		isError,
		data: developerPortalAccess,
		error
	} = useQueryWithAuthorization(
		['auth0User', user.name],
		getDeveloperPortalAccess({
			user_name: user.name
		}));

	if (isLoading) return <Loader/>;
	if (isError) {
		console.warn('Failed to get developer portal JWT', error.message);

		return <div> {getMessageFromError(error)} </div>;
	}

	return (
		<MainContainer redirectTo={`${getDeveloperPortalEndpoint()}?auth_token=${developerPortalAccess.token}&${searchParams}`}/>
	);
}

export default DeveloperPortalPage;
