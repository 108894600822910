import * as React from "react";

const OTTServiceGuardSVG = (props) => (
	<svg
		width={32}
		height={37}
		viewBox="0 0 32 37"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.1506 0.64334C15.3306 -0.0157076 16.7711 -0.00144386 17.9378 0.680853L30.0642 7.77261C31.2344 8.45701 31.9537 9.71092 31.9537 11.0666V25.2542C31.9537 26.6099 31.2344 27.8638 30.0642 28.5482L17.9429 35.637C16.753 36.3329 15.2801 36.3329 14.0901 35.637L1.96889 28.5482C0.79863 27.8638 0.0793457 26.6099 0.0793457 25.2542V17.6845C0.0793457 15.825 1.58681 14.3175 3.44636 14.3175C5.30591 14.3175 6.81337 15.825 6.81337 17.6845V23.5803L16.0165 28.9625L25.2197 23.5803V12.7405L15.9781 7.33584L7.36526 12.1465C5.74178 13.0532 3.6906 12.4722 2.78383 10.8487C1.87705 9.22526 2.45806 7.17408 4.08154 6.26731L14.1506 0.64334Z"
			fill="url(#paint0_linear_6896_230239)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.016 14.4055C12.3763 13.7452 13.2072 13.5001 13.8718 13.858L20.0183 17.1682C21.1157 17.7593 21.0947 19.3312 19.9818 19.893L13.892 22.9678C12.9574 23.4396 11.8506 22.765 11.8506 21.7235V15.3307C11.8506 15.284 11.853 15.2378 11.8576 15.1923C11.8302 14.9293 11.8796 14.6556 12.016 14.4055Z"
			fill="url(#paint1_linear_6896_230239)"
		/>
		<mask
			id="mask0_6896_230239"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={32}
			height={37}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1504 0.642913C15.3304 -0.0161349 16.7709 -0.00187111 17.9376 0.680426L30.0639 7.77219C31.2342 8.45658 31.9535 9.71049 31.9535 11.0662V25.2538C31.9535 26.6095 31.2342 27.8634 30.0639 28.5478L17.9427 35.6365C16.7527 36.3325 15.2799 36.3325 14.0899 35.6365L1.96865 28.5478C0.798386 27.8634 0.0791016 26.6095 0.0791016 25.2538V17.6841C0.0791016 15.8245 1.58656 14.3171 3.44611 14.3171C5.30566 14.3171 6.81313 15.8245 6.81313 17.6841V23.5799L16.0163 28.9621L25.2195 23.5799V12.7401L15.9779 7.33542L7.36501 12.146C5.74153 13.0528 3.69036 12.4718 2.78358 10.8483C1.87681 9.22483 2.45781 7.17366 4.08129 6.26688L14.1504 0.642913Z"
				fill="url(#paint2_linear_6896_230239)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0158 14.4051C12.3761 13.7448 13.2069 13.4997 13.8716 13.8576L20.018 17.1678C21.1154 17.7588 21.0944 19.3307 19.9816 19.8926L13.8917 22.9673C12.9571 23.4392 11.8504 22.7646 11.8504 21.7231V15.3302C11.8504 15.2835 11.8527 15.2374 11.8574 15.1919C11.83 14.9288 11.8794 14.6551 12.0158 14.4051Z"
				fill="url(#paint3_linear_6896_230239)"
			/>
		</mask>
		<g mask="url(#mask0_6896_230239)">
			<circle
				opacity={0.1}
				cx={18.194}
				cy={39.3435}
				r={27.874}
				fill="#003A99"
			/>
		</g>
		<mask
			id="mask1_6896_230239"
			style={{
				maskType: "alpha",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={32}
			height={37}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1504 0.642913C15.3304 -0.0161349 16.7709 -0.00187111 17.9376 0.680426L30.0639 7.77219C31.2342 8.45658 31.9535 9.71049 31.9535 11.0662V25.2538C31.9535 26.6095 31.2342 27.8634 30.0639 28.5478L17.9427 35.6365C16.7527 36.3325 15.2799 36.3325 14.0899 35.6365L1.96865 28.5478C0.798386 27.8634 0.0791016 26.6095 0.0791016 25.2538V17.6841C0.0791016 15.8245 1.58656 14.3171 3.44611 14.3171C5.30566 14.3171 6.81313 15.8245 6.81313 17.6841V23.5799L16.0163 28.9621L25.2195 23.5799V12.7401L15.9779 7.33542L7.36501 12.146C5.74153 13.0528 3.69036 12.4718 2.78358 10.8483C1.87681 9.22483 2.45781 7.17366 4.08129 6.26688L14.1504 0.642913Z"
				fill="url(#paint4_linear_6896_230239)"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0158 14.4051C12.3761 13.7448 13.2069 13.4997 13.8716 13.8576L20.018 17.1678C21.1154 17.7588 21.0944 19.3307 19.9816 19.8926L13.8917 22.9673C12.9571 23.4392 11.8504 22.7646 11.8504 21.7231V15.3302C11.8504 15.2835 11.8527 15.2374 11.8574 15.1919C11.83 14.9288 11.8794 14.6551 12.0158 14.4051Z"
				fill="url(#paint5_linear_6896_230239)"
			/>
		</mask>
		<g mask="url(#mask1_6896_230239)">
			<circle
				opacity={0.2}
				cx={27.9533}
				cy={49.5686}
				r={27.874}
				fill="#003A99"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_6896_230239"
				x1={16.052}
				y1={18.1589}
				x2={15.9417}
				y2={56.4129}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FA4D56" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_6896_230239"
				x1={16.3497}
				y1={18.4072}
				x2={16.3228}
				y2={28.4252}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FA4D56" />
				<stop offset={1} stopColor="#003A99" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_6896_230239"
				x1={15.9927}
				y1={18.1585}
				x2={15.9818}
				y2={42.9085}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FA4D56" />
				<stop offset={1} stopColor="#143366" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_6896_230239"
				x1={16.4432}
				y1={15.0532}
				x2={16.4432}
				y2={21.7814}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#EC4F5C" />
				<stop offset={1} stopColor="#3A4F93" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_6896_230239"
				x1={15.9927}
				y1={18.1585}
				x2={15.9818}
				y2={42.9085}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FA4D56" />
				<stop offset={1} stopColor="#143366" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_6896_230239"
				x1={16.4432}
				y1={15.0532}
				x2={16.4432}
				y2={21.7814}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#EC4F5C" />
				<stop offset={1} stopColor="#3A4F93" />
			</linearGradient>
		</defs>
	</svg>

);

export default OTTServiceGuardSVG;
