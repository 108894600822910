import React from "react";
import { useUsersInvitationsStore } from "../../../../../../../state-management";
import shallow from "zustand/shallow";
import styles from "./AccountRoles.module.css";
import { Dropdown } from "@ip-synamedia/common-ui-library";

const AccountRoles = ({ accountRoles = [], firstRender,  setFirstRender , isEdit = false }) => {
	
	const [accountRolesIds, setAccountRolesIds] = useUsersInvitationsStore(
		(state) => [state.accountRolesIds, state.setAccountRolesIds],
		shallow
	);

	const options = accountRoles.filter((obj) => !obj.isProductAdmin).map((obj) => ({ ...obj, info: obj.description }));

	const handleOnValueChange = (incomingOption) => {
		setFirstRender(false);
		setAccountRolesIds(incomingOption);
	};

	return (
		<div className={styles.root}>
			{options?.length > 0 && (
				<div className={styles.rolesContainer}>
					<div className={styles.multiselectContainer}>
						<Dropdown
							id={'inviteUser_content_accountDropdown'}
							entity="roles"
							multiple
							onValueChange={handleOnValueChange}
							options={options}
							value={[...accountRolesIds]}
							width="238px"
							error={(isEdit && !firstRender && accountRolesIds?.size === 0)}
							helperText={(isEdit && !firstRender && accountRolesIds?.size === 0) ? "At least one allowed role must be selected" : ""}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default AccountRoles;
