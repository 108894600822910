import React from 'react';
import styles from "./UsersSummary.module.css";
import { Button, Tag } from "@ip-synamedia/common-ui-library";
import InviteUsersDefaultSVG from '../../../../../assets/InviteUsersDefaultSVG';
import { useMainContainerStore } from "../../../../../state-management";
import shallow from "zustand/shallow";

const getLabel = (projects, projectId) => {
	return projects.find(project => project.id === projectId)?.label ?? "Unknown Space";
};

const UsersSummary = ({
	isEdit = false,
	isProcessing,
	accountRolesData,
	tenantsPerProjects,
	productAdminRolesData,
	handleProcessing,
	processDisabled,
	handleCancel,
	userInvitationData,
	projects,
	summaryTitle,
	...rest
}) => {
	let productRolesCount = 0;
	const { emails, accountRolesIds, productAdminRolesIds, productRolesByProjectId } = userInvitationData;
	const accountRoles = accountRolesIds?.size > 0 ? Array.from(accountRolesIds) : [];
	const productAdminRoles = productAdminRolesIds?.size > 0 ? Array.from(productAdminRolesIds) : [];
	const spacesRoles = [];

	let filterUserRole = {};

	Object.keys(productRolesByProjectId).forEach(ProjectId => {
		let product = tenantsPerProjects[ProjectId]|| [];
		let temp = [];

		productRolesByProjectId[ProjectId].forEach(role => {
			if (product.includes(role.productId)) {
				temp.push(role);
			}
		});
		if(temp.length) {
			filterUserRole[ProjectId] = temp;
		}
	});

	const [{ data: products }] = useMainContainerStore(state => [state.productsPromise], shallow);
	const productIdToDisplayName = products.reduce((idMap, product) => {
		idMap[product.id] = product.displayName;

		return idMap;
	}, {});
	const getSpaceRoles = (rolesObj) => {
		const productRolesMap = {};

		rolesObj?.forEach((role) => {
			if (Object.prototype.hasOwnProperty.call(productRolesMap, role.productId)) {
				productRolesMap[role.productId] = [...productRolesMap[role.productId], role.displayName];
			} else {
				productRolesMap[role.productId] = [role.displayName];
			}
			productRolesCount += 1;
		});

		return productRolesMap;
	};

	Object.keys(filterUserRole).forEach((projectId) => {
		const productRoles = getSpaceRoles(filterUserRole[projectId]);

		spacesRoles.push({
			spaceName: getLabel(projects, projectId),
			productRoles
		});
	});

	const summaryStatusOptions = [{
		id: "usersinvites",
		label: "Users Invites",
		count: emails?.length || 0,
		show: !isEdit,
	},
	{
		id: "accountroles",
		label: "Account Roles",
		count: accountRoles?.length || 0,
		show: !isEdit ? true : accountRoles?.length > 0,
	},
	{
		id: "productadminroles",
		label: "Product Admin Roles",
		count: productAdminRoles?.length || 0,
		show: productAdminRoles?.length > 0,
	},
	{
		id: "productroles",
		label: "Product Roles",
		count: productRolesCount || 0,
		show: !isEdit ? true : productRolesCount,
	}
	];

	const getAccountName = (role) => {
		let res = null;

		accountRolesData.forEach(accountRole => accountRole.id === role ? res = accountRole.label : res);

		return res;
	};

	const getProductAdminName = (role) => {
		let res = null;

		productAdminRolesData.forEach(prodctAdminRole => {
			const _role = prodctAdminRole.roles.find(prole => prole.id === role);

			res = _role?.displayName ?? res;
		});

		return res;
	};
	const areRolesAvailable = accountRoles.length > 0 || productAdminRoles.length > 0 || spacesRoles.some(space => Object.keys(space.productRoles)?.length > 0);

	return (
		<div className={styles.usersSummaryContainer} {...rest}>
			<div className={styles.summaryContent}>
				<div className={styles.titleContainer}>
					<div id={'editUser_summary_title'} className="productiveHeading5">{summaryTitle}</div>
				</div>
				<div className={isEdit ? styles.editSummaryStatusContainer : styles.summaryStatusContainer}>
					{summaryStatusOptions.map((roles, index) => {
						return (
							roles.show ? (<div id={`${isEdit ? "edit" : "invite"}User_summaryRoles_container_${index}`} key={roles.id}
								className={styles.statusContainer}>
								<div id={`${isEdit ? "edit" : "invite"}User_summaryRoles_count_${index}`}
									className={styles.statusCount}>{roles.count}</div>
								<div id={`${isEdit ? "edit" : "invite"}User_summaryRoles_label_${index}`}
									className={styles.statusLabel}>{roles.label}</div>
							</div>) : null
						);
					})}
				</div>
				<div className={styles.dividerContainer}>
					<div className={styles.divider}/>
				</div>
				<div className={styles.rolesDetailsContainer}>
					<div className={styles.rolesHeader}>
						<div id={`${isEdit ? "edit" : "invite"}User_assignedRoles_title`} className="productiveHeading4">Assigned Roles</div>
					</div>
					<div className={styles.spacesContainer}>
						{
							areRolesAvailable ? <div className={styles.allRolesContainer}>
								{accountRoles.length > 0 ? <>
									<div className={styles.rolesTitle} id={`${isEdit ? "edit" : "invite"}User_accountRoles_title`}>Account Roles</div>
									<div className={styles.rolesContainer}>
										{accountRoles.map((role, index) => <Tag key={role}>
											<div id={`${isEdit ? "edit" : "invite"}User_accountRoles_${index}`}>{getAccountName(role)}</div>
										</Tag>)
										}
									</div>
								</> : null}
								{productAdminRoles.length > 0 ? <>
									<div className={styles.rolesTitle} id={`${isEdit ? "edit" : "invite"}User_adminRoles_title`}>Product Admin Roles</div>
									<div className={styles.rolesContainer}>
										{productAdminRoles.map((role, index) => <Tag key={role}>
											<div id={`${isEdit ? "edit" : "invite"}User_adminRoles_${index}`}>{getProductAdminName(role)}</div>
										</Tag>)
										}
									</div>
								</> : null
								}
								{
									spacesRoles.some(space => Object.keys(space.productRoles)?.length > 0) ?
										<div className={styles.spaceRolesContainer}>
											<div className={styles.rolesTitle}  id={`${isEdit ? "edit" : "invite"}User_spacesRoles_title`}>Roles by Space</div>
											<div className={styles.productListContianer}>
												{
													spacesRoles.map((space, index) => {
														return (
															Object.keys(space.productRoles)?.length > 0 ?
																(<div className={styles.productRolesContainer}
																	key={`${space.name}_${index}`}>
																	<div
																		className={styles.rolesTitle} id={`${isEdit ? "edit" : "invite"}User_spaceRoles_${space.spaceName}`}>{space.spaceName}</div>
																	<div className={styles.productRoleContainer}>
																		{
																			Object.entries(space.productRoles).map((product, index) => {
																				return <div className={styles.productInfo}
																					key={`${product[0]}_${index}`}>
																					<div
																						className={styles.productName} id={`${isEdit ? "edit" : "invite"}User_spacesRoles_${space.spaceName}_${product[0]}_title`}>{productIdToDisplayName[product[0]]}</div>
																					<div className={styles.tags}>
																						{
																							Array.isArray(product[1]) && product[1].length > 0 ?
																								product[1].map((role, index) =>
																									<Tag key={`${role}_${index}`}>
																										<div
																											id={`${isEdit ? "edit" : "invite"}User_spacesRoles_${space.spaceName}_${product[0]}_role`}>
																											{role}
																										</div>
																									</Tag>)
																								: null
																						}
																					</div>
																				</div>;
																			})
																		}
																	</div>
																</div>) : null
														);
													})
												}
											</div>
										</div>
										:
										null
								}
							</div>
								:
								!isEdit ? <div className={styles.artContainer}>
									<div id={`${isEdit ? "edit" : "invite"}User_assignedRole_emptyIcon`}><InviteUsersDefaultSVG/></div>
									<div id={`${isEdit ? "edit" : "invite"}User_assignedRole_emptyText`} className={styles.rolesDefaultMessage}>You
                                    haven&apos;t assigned any roles yet. Start by<br/>entering the email address and
                                    then assign roles<br/>to them.
									</div>
								</div> : null
						}
					</div>
				</div>
			</div>
			<div id={`${isEdit ? "edit" : "invite"}Users_actions_container`} className={styles.actionsContainer}>
				<Button id={`${isEdit ? "edit" : "invite"}Users_cancel_button`} color="secondary" onClick={handleCancel}>Cancel</Button>
				<Button id={`${isEdit ? "edit" : "invite"}Users_${isEdit ? "save" : "invite"}_button`} data-disabled={processDisabled} disabled={processDisabled} color="primary"
					onClick={handleProcessing}>{isProcessing ? (isEdit ? 'Saving' : 'Inviting' ) : (isEdit ? 'Save' : 'Invite')}</Button>
			</div>
		</div>
	);
};

export default UsersSummary;