import React, { useState } from "react";
import styles from "./ProductsPerProjectRoles.module.css";
import List from "./List";
import Roles from "./Roles";

const ProductsPerProjectRoles = ({ projects, productRoles, filterProductRoles, parentFirstRender = false, setParentFirstRender, isEdit = false, initialState, changesMadeCmp, ...rest }) => {

	//for old behavior
	// const getOptions = (projects,productRoles) => {
	// 	const options = [];
	//
	// 	projects?.forEach((project) => {
	// 		options.push({
	// 			productRoles,
	// 			id: project.id,
	// 			label: project.label
	// 		});
	// 	});
	//
	// 	return options;
	// };
	//const options = getOptions(projects,productRoles);

	const options = filterProductRoles;

	const [selectedSpace,setSelectedSpace] = useState(options[0]);

	const handleSpaceClick = (object) => {
		setSelectedSpace(object);
		setParentFirstRender && setParentFirstRender(false);
	};

	return (
		<div className={styles.productrolesContainter} {...rest}>
			<List lable="Space Selection" options={options} selectedSpace={selectedSpace} handleSpaceClick={handleSpaceClick} isEdit={isEdit} changesMadeCmp={changesMadeCmp} />
			{Object.keys(selectedSpace).length > 0 ? 
				<Roles label="Select roles for each product" selectedSpace={selectedSpace} initialState={initialState} parentFirstRender={parentFirstRender} setParentFirstRender={setParentFirstRender}/>
				: null }
		</div>
	);
};

export default ProductsPerProjectRoles;
