const ProductsGraphicsSVG = (props) => (
	<svg
		width={103}
		height={72}
		viewBox="0 0 103 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M66.9999 6.42857L53.9623 28.6071H80.0375L66.9999 6.42857ZM70.401 1.92857C68.8894 -0.642858 65.1104 -0.642856 63.5988 1.92857L48.2937 27.9643C46.7821 30.5357 48.6716 33.75 51.6949 33.75H82.3049C85.3281 33.75 87.2176 30.5357 85.706 27.9643L70.401 1.92857Z"
			fill="#D6E6FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M36.2364 44.9993V66.8564H58.4909V44.9993H36.2364ZM34.9273 39.8564C32.7583 39.8564 31 41.5833 31 43.7136V68.1422C31 70.2724 32.7583 71.9993 34.9273 71.9993H59.8C61.969 71.9993 63.7273 70.2724 63.7273 68.1422V43.7136C63.7273 41.5833 61.969 39.8564 59.8 39.8564H34.9273Z"
			fill="#D6E6FF"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M86.6372 66.8564C92.7826 66.8564 97.7645 61.9636 97.7645 55.9279C97.7645 49.8922 92.7826 44.9993 86.6372 44.9993C80.4918 44.9993 75.5099 49.8922 75.5099 55.9279C75.5099 61.9636 80.4918 66.8564 86.6372 66.8564ZM86.6372 71.9993C95.6746 71.9993 103.001 64.8039 103.001 55.9279C103.001 47.0519 95.6746 39.8564 86.6372 39.8564C77.5998 39.8564 70.2736 47.0519 70.2736 55.9279C70.2736 64.8039 77.5998 71.9993 86.6372 71.9993Z"
			fill="#D6E6FF"
		/>
	</svg>
);

export default ProductsGraphicsSVG;
