import React, { useState } from 'react';
import { Dropdown, UserIcon, AccountIcon, LogoutIcon } from '@ip-synamedia/common-ui-library';
import styles from './UserProfile.module.css';
import NavBarIconButton from '../../NavBarIconButton';

const UserProfile = ({ handleLogout, user }) => {
	const [open, setOpen] = useState(false);
	const options = [{
		id: "userEmail",
		label: user?.email,
		icon: <UserIcon className={styles.optionIcon} />
	},{
		id: "signout",
		label: "Sign Out",
		icon: <LogoutIcon className={styles.optionIcon} />
	}];

	// create a navbar store to manage the states of all the icons
	const handleSelectionChange = (value) => {
		if(value === "signout") handleLogout();
	};

	return (
		<Dropdown
			open={open}
			onOpenChange={setOpen}
			options={options}
			onValueChange={handleSelectionChange}
			type="menu"
			align="end"
			id={"NavBar_userProfile_dropdown"}
		>
			<NavBarIconButton selected={open}>
				<AccountIcon className={styles.iconItem} />
			</NavBarIconButton>
	  	</Dropdown>
	);
};

export default UserProfile;