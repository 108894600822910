import React, { useMemo, useState } from 'react';
import styles from "./CustomList.module.css";
import { Button, Card, Search } from '@ip-synamedia/common-ui-library';

const CustomList = (props) => {

	const { 
		applicationData,
		setApplicationData,
		setFirstRender,
		id = "application",
		productsPermissions
	} = props;

	const [searchPermissions, setSearchPermissions] = useState("");
	const [permissionsArrayList,setPermissionsArrayList] = useState([]);
	const [filteredPermissions, setFilteredPermissions] = useState([]);
	const [productsNameMap,setProductsNameMap] = useState(new Map());

	const getPermissionsList = (permissionsObj) => {
		const tempList = [];
		const tempMap = new Map();

		if(Object?.values(permissionsObj).length) {
			Object?.values(permissionsObj).forEach(({ name, permissions }) => {
				if(permissions.length) {
					permissions.forEach(permission => {
						tempList.push(permission);
						tempMap.set(permission,name);
					});
				}
			});
		}
		setProductsNameMap(tempMap);
		setPermissionsArrayList(tempList);
		setFilteredPermissions(tempList);
	};

	useMemo(() => {
		if(productsPermissions?.productsPermissions)
			getPermissionsList(productsPermissions?.productsPermissions);
	}, [productsPermissions]);

	useMemo(() => {
		const permissionsGroup = {};

		applicationData?.permissions.forEach(permission => {
			const productName = productsNameMap.get(permission);

			if(productName) {
				if(Array.isArray(permissionsGroup[productName]))
					permissionsGroup[productName] = [...permissionsGroup[productName],permission];
				else permissionsGroup[productName] = [permission];
			}
		});
		setApplicationData({ ...applicationData, permissionsGroup });
		//eslint-disable-next-line
	},[applicationData?.permissions,productsNameMap]);

	const handleClearPermissions = () => {
		setApplicationData({ ...applicationData, permissions: [] });
	};

	useMemo(() => {
		handleClearPermissions();
		//eslint-disable-next-line
	},[applicationData?.spaceId]);

	const handleSelectAllPermissions = () => {
		const setPermissions = new Set([...applicationData?.permissions,...filteredPermissions]);

		setApplicationData({ ...applicationData, permissions: Array.from(setPermissions) });
	};

	const handleAddPermission = (permission) => {
		const tempArr = [...applicationData?.permissions, permission];

		setApplicationData({ ...applicationData, permissions: tempArr });
	};

	const handleRemovePermission = (permissionToBeRemoved) => {
		const tempArr = (applicationData?.permissions).filter(permission => permission !== permissionToBeRemoved);

		setApplicationData({ ...applicationData, permissions: tempArr });
	};
	
	const handlePermissionToggle = (permission) => {
		setFirstRender && setFirstRender(false);
		if((applicationData?.permissions).includes(permission)) {
			handleRemovePermission(permission);
		} else {
			handleAddPermission(permission);
		}
	};

	const handlePermissionsFilter = (e) => {
		setSearchPermissions(e.target.value.trim());
		if(e.target.value.trim()) {
			const filteredArr = permissionsArrayList.filter(permission => permission.includes(e.target.value));

			setFilteredPermissions(filteredArr);
		} else {
			setFilteredPermissions(permissionsArrayList);
		}
	};

	return (
		<div id={`${id}_permissionsContent`} className={styles.permissionsContent}>
			<div id={`${id}_permissions_actionsContainer`} className={styles.permissionsActionsContainer}>
				<Search
					style={{ width: "234px" }}
					id={`${id}_permissions_search`}
					placeholder={"Filter permissions"}
					value={searchPermissions || ""}
					onChange={handlePermissionsFilter}
					disabled = {applicationData?.spaceId === ""}
				/>
				<div id={"actions_buttons_container"} className={styles.actionsbuttonsContainer}>
					<Button onClick={handleSelectAllPermissions} id={"action_selectAll"} color={"ghost"} size={"small"} disabled = {applicationData?.spaceId === ""}>Select All</Button>
					<Button onClick={handleClearPermissions} id={"action_clear"} color={"ghost"} size={"small"} disabled = {applicationData?.spaceId === ""}>Clear</Button>
				</div>
			</div>
			<div id={`${id}_permissions_listContainer`}>
				{
					applicationData?.spaceId === "" ? 
						<div id={`${id}_info_message_custom_card`} className={styles.infoMessageCustomCard}>
							<div id={`${id}_info_message_container`} className={styles.infoMessageContainer}>
								<div id={`${id}_info_message`} className={styles.infoMessage}>Select a space and then you will be able to pick permissions</div>
							</div> 
						</div>
						:
						<Card id={`${id}_permissions_list`} className={styles.permissionsList}>	
							<div id={`${id}_permissions_listContent`} className={styles.permissionsListContent}>
								{
									filteredPermissions.map(permission => {
										return <Card
											className={styles.permissionCard}
											id={permission}
											key={permission}
											data-style-id={"productPermissions"}
											title={permission}
											type="multiSelect"
											onToggleChecked={() => {handlePermissionToggle(permission);}}
											checked={applicationData?.permissions?.includes(permission)} 
										/>;
									}
									)
								}
							</div>
						</Card>
				}
			</div>
		</div>
	);
};

export default CustomList;