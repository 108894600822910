import styles from "../topCard.module.css";
import Loader from "../../../../components/Loader";
import getMessageFromError from "../../../../utils/API-calls";
import TopCardContainer from "../TopCardContainer";
import React from "react";
import { useUserInfo } from "../../../../auth/UserInfoProvider";

export default function UsersCard({ element, indexOut }) {
	const { accountAlias } = useUserInfo();

	return <TopCardContainer key={'UsersCard'} index={indexOut} elementOut={element} link = {`/${accountAlias}/account`}>
		<div className={styles.cardDesc}>
			{
				element.desc.map((elementIn, index) => {
					if (element?.isLoading) return <div key={index + 'UsersCard Loader'}
						className={styles.cardLoaderContainer}><Loader/></div>;
					if (element?.isError) return <div key={index + 'UsersCard Error'}
						className={styles.errorMessage}>{getMessageFromError(element.error)}</div>;

					return <div key={'UsersCardValue' + index} className={styles.cardAdditionalInfo}>
						<div className="SemiBoldShort1" style={{ color: "#52698E" }}>{elementIn.value["one"]}</div>
						<div className={styles.vSeparator}/>
						<div className="SemiBoldShort1" style={{ color: "#52698E" }}>{elementIn.value["two"]}</div>
					</div>;

				})
			}
		</div>
	</TopCardContainer>;
}