import React, { useMemo, useState } from 'react';
import EditApplicationForm from './EditApplicationForm';

const EditApplicationContainer = (props) => {
	const {
		currentApplicationData,
		productsPermissions,
		returnToAccountManagement,
	} = props;

	const [editApplicationData,setEditApplicationData] = useState({});

	useMemo(() => {
		setEditApplicationData({
			application: currentApplicationData.application,
			clientId: currentApplicationData.clientId,
			secretKey: currentApplicationData.secretKey,
			applicationDescription: "",
			space: currentApplicationData.space,
			permissions: currentApplicationData.permissions,
			permissionsGroup: {}
		});
	},[currentApplicationData]);

	return (
		<EditApplicationForm
			applicationData={currentApplicationData}
			productsPermissions={productsPermissions}
			returnToAccountManagement={returnToAccountManagement}
			editApplicationData={editApplicationData}
			setEditApplicationData={setEditApplicationData}
		/>
	);
};

export default EditApplicationContainer;