import ClarissaSVG from "../../assets/icons/products/ClarissaSVG";
import CsfEyeSVG from "../../assets/icons/products/CsfEyeSVG";
import EverguardSVG from "../../assets/icons/products/EverguardSVG";
import GoSVG from "../../assets/icons/products/GoSVG";
import IrisSVG from "../../assets/icons/products/IrisSVG";
import OTTServiceGuardSVG from "../../assets/icons/products/OTTServiceGuardSVG";
import ProductsSvg from "../../assets/icons/products/ProductsSVG";
import HyperscaleSVG from "../../assets/icons/products/HyperscaleSVG";
import GravitySVG from "../../assets/icons/products/GravitySVG";

export const getIconFromId = (id) => {

	switch (id) {
	case "irisSSAI":
	case "iris":
		return <IrisSVG/>;
	case "clarissa":
		return <ClarissaSVG/>;
	case "go":
		return <GoSVG/>;
	case "everguard":
		return <EverguardSVG/>;
	case "csfeye":
	case "csfeye1":
		return <CsfEyeSVG/>;
	case "ottserviceguard":
		return <OTTServiceGuardSVG/>;
	case "hyperscale":
		return <HyperscaleSVG/>;
	case "gravity":
		return <GravitySVG/>;
	default:
		return <ProductsSvg/>;
	}
};

export const isValidHttpUrl = (string) => {
	try {
		const url = new URL(string);

		return url.protocol === "http:" || url.protocol === "https:";
	} catch (_) {
		return false;
	}
};