import * as React from "react";

const UserIconSVG = (props) => (
	<svg
		width={16}
		height={13}
		viewBox="0 0 16 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.975 8.125C9.42496 8.125 10.575 6.95703 10.575 5.48438C10.575 4.03711 9.4 2.84375 7.975 2.84375C6.55 2.84375 5.4 4.03711 5.4 5.48438C5.375 6.95703 6.55 8.125 7.975 8.125ZM7.975 4.0625C8.75 4.0625 9.37504 4.72266 9.37504 5.48438C9.37504 6.27148 8.75 6.90625 7.975 6.90625C7.225 6.90625 6.575 6.27148 6.575 5.48438C6.575 4.72266 7.225 4.0625 7.975 4.0625ZM12.8 4.0625C13.9 4.0625 14.8 3.17383 14.8 2.03125C14.8 0.914062 13.9 0 12.8 0C11.675 0 10.8 0.914062 10.8 2.03125C10.8 3.17383 11.675 4.0625 12.8 4.0625ZM9.22496 8.9375H6.75C4.775 8.9375 3.2 10.4609 3.2 12.3398C3.2 12.7207 3.5 13 3.9 13H12.075C12.475 13 12.8 12.7207 12.8 12.3398C12.8 10.4609 11.2 8.9375 9.22496 8.9375ZM4.45 11.7812C4.7 10.8672 5.625 10.1562 6.725 10.1562H9.22496C10.325 10.1562 11.25 10.8672 11.525 11.7812H4.45ZM13.775 4.875H12.25C11.925 4.875 11.625 4.97656 11.35 5.10352C11.35 5.23047 11.375 5.35742 11.375 5.48438C11.375 6.34766 11.075 7.13477 10.55 7.71875H15.55C15.8 7.71875 16 7.51562 16 7.26172C16 5.94141 15 4.875 13.775 4.875ZM4.575 5.48438C4.575 5.35742 4.6 5.23047 4.625 5.10352C4.35 4.95117 4.05 4.875 3.725 4.875H2.2C0.975 4.875 0 5.94141 0 7.26172C0 7.51562 0.175 7.71875 0.425 7.71875H5.425C4.9 7.13477 4.575 6.34766 4.575 5.48438ZM3.2 4.0625C4.3 4.0625 5.2 3.17383 5.2 2.03125C5.2 0.914062 4.3 0 3.2 0C2.075 0 1.2 0.914062 1.2 2.03125C1.2 3.17383 2.075 4.0625 3.2 4.0625Z"
			fill="#91A3C0"
		/>
	</svg>
);

export default UserIconSVG;
