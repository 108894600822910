import { getApiEndpoint } from '../../config';

const baseHeaders = {
	"content-type": "application/json"
};

const getAll = (params = {}) => {
	const { headers = {} } = params;

	return {
		method: "GET",
		url: `${getApiEndpoint()}/accounts`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const getById = (id, params = {}) => {
	const { headers = {} } = params;

	return {
		method: "GET",
		url: `${getApiEndpoint()}/accounts/${id}`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const postAccount = (account, params = {}) => {
	const { headers = {} } = params;

	return {
		method: "POST",
		url: `${getApiEndpoint()}/accounts`,
		headers: {
			...headers,
			...baseHeaders,
		},
		body: JSON.stringify(account)
	};
};

const lookupOrgIdByIdOrAlias = (idOrAlias, params = {}) => {
	const { headers = {} } = params;
	const urlSearchParams = new URLSearchParams();

	urlSearchParams.set("accountId", idOrAlias);

	return {
		method: "GET",
		url: `${getApiEndpoint()}/lookup/accountOrgId?${urlSearchParams.toString()}`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const lookupAliasByOrgId = (orgId, params = {}) => {
	const { headers = {} } = params;
	const urlSearchParams = new URLSearchParams();

	urlSearchParams.set("organizationId", orgId);

	return {
		method: "GET",
		url: `${getApiEndpoint()}/lookup/accountAlias?${urlSearchParams.toString()}`,
		headers: {
			...headers,
			...baseHeaders,
		}
	};
};

const recoverAccount = ({ email, params = {} }) => {
	const { headers = {} } = params;

	return {
		method: "POST",
		url: `${getApiEndpoint()}/accounts/recover?email=${email}`,
		headers: {
			...headers,
			...baseHeaders,
		},
	};
};

export {
	getAll,
	getById,
	postAccount,
	lookupOrgIdByIdOrAlias,
	lookupAliasByOrgId,
	recoverAccount
};
