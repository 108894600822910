import React, { useEffect, useState } from 'react';
import { Dropdown } from '@ip-synamedia/common-ui-library';
import { useMainContainerStore } from "../../../state-management";
import Loader from "../../../components/Loader";
import { useCookies } from "react-cookie";
import cookiesNames from "../../../config/cookies";
import getMessageFromError from "../../../utils/API-calls";
import { getDomain } from '../../../config';
import { useUserInfo } from "../../../auth/UserInfoProvider";

const SpaceSwitcher = () => {
	const [cookies, setCookie] = useCookies();
	const { orgId } = useUserInfo();

	const accountPreferences = cookiesNames.preferences + "." + orgId;
	const [selectedSpace, setSelectedSpace] = useState(cookies?.[accountPreferences]?.activeSpace?.id);
	const [projectsPromise] = useMainContainerStore(state => [state.projectsPromise]);

	useEffect(() => {
		setSelectedSpace(cookies?.[accountPreferences]?.activeSpace?.id);
	}, [cookies, accountPreferences]);

	const spaces = projectsPromise?.data ?? [];

	const handleSelectionChange = (value) => {
		if (value === selectedSpace) return;

		const maxAge = 86400 * 365 * 10;

		spaces.forEach(space => {
			if (space.id === value) {
				setCookie(cookiesNames.preferences, { activeSpace: space }, {
					path: '/',
					maxAge: 0
				});

				setCookie(accountPreferences, { activeSpace: space }, {
					path: '/',
					maxAge,
					...(!window.location.hostname.includes('localhost') && { domain: `.${getDomain()}` })
				});
			}
		});
	};

	return (
		projectsPromise.isLoading ? <Loader width={50} height={60} type={'Rings'}/> : projectsPromise.isError ? <div
			id={'spaceSwitcher_emptySpaces_div'}>{projectsPromise.error && getMessageFromError(projectsPromise.error)}</div> : Array.isArray(spaces) && spaces.length ?
			<Dropdown options={spaces}
				value={selectedSpace}
				onValueChange={handleSelectionChange}
				headerComponent
				width="160px"/> : <div id={'spaceSwitcher_emptySpaces_div'}/>
	);
};

export default SpaceSwitcher;