const getQueryParams = () => {
	const urlSearchParams = new URLSearchParams(
		window?.location?.search || {}
	);

	const params = {};

	for (const [key, value] of urlSearchParams) {
		params[key] = value;
	}

	return params;
};

const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

	if (newWindow) newWindow.opener = null;
};

export {
	getQueryParams,
	openInNewTab
};
