const VividSVG = (props) => (
	<svg width="48" height="48" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M23.196 8.35137C19.9743 8.35137 16.8579 10.9793 16.1331 15.1052L15.5648 18.3402H10.9684C9.80435 18.3402 8.21819 19.5501 8.21819 21.8141C8.21819 24.078 9.80435 25.2879 10.9684 25.2879C13.0997 25.2879 14.8274 27.0455 14.8274 29.2136C14.8274 31.3817 13.0997 33.1393 10.9684 33.1393C4.86276 33.1393 0.500244 27.6895 0.500244 21.8141C0.500244 16.4593 4.12374 11.4582 9.38791 10.6138C11.5711 4.83492 16.7696 0.5 23.196 0.5C29.6574 0.5 34.6708 4.81784 36.794 10.5604C42.0559 11.4173 45.6502 16.4429 45.6502 21.7855C45.6502 27.6543 41.3136 33.1387 35.1955 33.1387H22.934V37.9676H38.8459L40.1732 36.5555C40.4357 36.2763 40.8612 36.2763 41.1237 36.5555L45.1548 40.8442C45.4173 41.1234 45.4173 41.5761 45.1548 41.8553L41.1237 46.144C40.8612 46.4232 40.4357 46.4232 40.1732 46.144L39.4274 45.3505H19.5807C17.4513 45.3505 15.7251 43.5982 15.7251 41.4365V28.717C15.7251 26.8853 17.1877 25.4005 18.992 25.4005L18.9982 25.4005C19.2942 25.3266 19.6036 25.2873 19.922 25.2873H35.1955C36.3322 25.2873 37.9323 24.0874 37.9323 21.7855C37.9323 19.4836 36.3324 18.2836 35.1955 18.2836H30.5988L30.0331 15.0446C29.2976 10.833 26.3475 8.35137 23.196 8.35137Z" fill="url(#paint0_linear_1920_3298)"/>
		<defs>
			<linearGradient id="paint0_linear_1920_3298" x1="22.9255" y1="0.5" x2="22.7027" y2="75.0117" gradientUnits="userSpaceOnUse">
				<stop stopColor="#3DDBD9"/>
				<stop offset="1" stopColor="#003A99"/>
			</linearGradient>
		</defs>
	</svg>

);

export default VividSVG;
