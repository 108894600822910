import { getApiEndpoint } from "../../config";

const baseHeaders = {
	'content-type': 'application/json'
};

const getByAccountIdProjectIdAppId = (applicationId, projectId, accountId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications/${applicationId}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const getApplicationsByAccountIdAndProjectId = (accountId,projectId, params = {}, view =[]) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications?view=${view}`,
		headers: {
			...headers,
			...baseHeaders
		}
	};
};

const postApplication = (accountId, projectId, app, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications`,
		headers: {
			...headers,
			...baseHeaders,
		},
		body: JSON.stringify(app)
	};
};

const putApplication = (accountId, projectId, id,app, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'put',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications/${id}`,
		headers: {
			...headers,
			...baseHeaders,
		},
		body: JSON.stringify(app)
	};
};

const regenerateSecret = (accountId, projectId, applicationId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'POST',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications/${applicationId}/regenerateSecret`,
		headers: {
			...headers,
			...baseHeaders,
		},
	};
};

const getApplicationSecret = (accountId, projectId, applicationId, params = {}) => {
	const { headers = {} } = params;

	return {
		method: 'GET',
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications/${applicationId}/credentials`,
		headers: {
			...headers,
			...baseHeaders,
		},
	};
};

const deleteApplication = (accountId, projectId, applicationId, params={}) => {
	const { headers = {} } = params;

	return {
		method: "DELETE",
		url: `${getApiEndpoint()}/accounts/${accountId}/projects/${projectId}/applications/${applicationId}`,
		headers: {
			...headers,
			...baseHeaders
		},
	};
};

export {
	getByAccountIdProjectIdAppId,
	postApplication,
	putApplication,
	regenerateSecret,
	deleteApplication,
	getApplicationsByAccountIdAndProjectId,
	getApplicationSecret
};
