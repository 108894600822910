import React from "react";
import MiddleCardsContainer from "../MiddleCardsContainer";
import stylesCard from "../middleCards.module.css";
import styles from "./recentlyUsed.module.css";
import Loader from "../../../../components/Loader";
import { emptyRecentlyUse, getIconByProductId, linkWrapper } from "../tools";
import clsx from "clsx";
import { useUserInfo } from "../../../../auth/UserInfoProvider";

export default function RecentlyUsed({ element, index, recentlyUsedProducts }) {
	const { accountAlias } = useUserInfo();

	return <MiddleCardsContainer key={'RecentlyUsed'} index={index} elementOut={element}>
		{Array.isArray(element.data) && (element.data.length ? element.data.map((item, index) => {
			const tenantId = element.data.length && element.data[index]?.tenantId;
			const link = tenantId ? "/" + accountAlias + "/launchProduct/" + tenantId : "/";
			const displayName = item?.displayName;
			const productId = item?.productId;

			return (element?.isLoading || element?.isError) ?
				<div>
					{element.isLoading && <div className={stylesCard.cardLoaderContainer}><Loader/></div>}
					{element.isError && <div className={stylesCard.errorMessage}>{element.error}</div>}
				</div> :
				<div key={`${displayName}_${index}`} className={styles.listData}
					id={`HomePage_card${element.id}_${displayName}`}>
					{linkWrapper(link, [<div key={"listIcon link wrapper" + index} className={styles.listIcon}>
						{getIconByProductId(productId)}
					</div>,
					<div key={"listContent link wrapper" + index}>
						<div className={clsx(styles.listName, 'semiBoldShort2')}>
							{displayName}
						</div>
						{item.desc ? <div className={styles.listDesc}>
							{item.desc}
						</div> : null}
					</div>])}
				</div>;
		}) : recentlyUsedProducts && emptyRecentlyUse)
		}
	</MiddleCardsContainer>;
}