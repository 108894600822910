import Loader from "../../components/Loader";

export default function getMessageFromError(error) {
	try {
		const json = JSON.parse(error?.message ?? error);

		json?.message && console.error(json.message);
	} catch (err) {
		error?.message && console.error(error.message);
	}

	return "Unexpected Error";

}

export const checkLoadError = (queries, message) => {
	if (!queries.every(key => !key.isLoading)) return <Loader width={30} height={30} type={'Rings'}/>;
	if (queries.length > 0 && queries.every(key => key.isError)) {
		return <div>{message ? message : queries.map(key => getMessageFromError(key.error)).join(', ')}</div>;
	}

	return false;
};