import React, { useState, useEffect } from 'react';
import styles from "./CreateApplication.module.css";
import Header from '../../../../../components/PageHeader';
import { getProductsPermissions, getProjectsByAccountId } from "../../../../../external-apis";

import { useQueryWithAuthorization } from "../../../../../custom-hooks";
import Loader from "../../../../../components/Loader";
import getMessageFromError from '../../../../../utils/API-calls';
import CreateApplicationContainer from './CreateApplicationContainer';
import { useUserInfo } from '../../../../../auth/UserInfoProvider';

const breadCrumbItems= [{
	id: "applications",
	label: "Applications"
},{
	id: "createApplications",
	label: "Create"
}];

const mapProjectsToUiElement = (projects) => {
	(projects || []).sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);

	return (projects || []).map(project => {
		const { id, displayName } = project;

		return {
			id,
			label: displayName
		};
	});
};

const CreateApplication = ({ resetApplicationsStore, setIsApplicationCreateActive }) => {
	useEffect(() => {
		return () => {
			returnToAccountManagement();
		};
		// eslint-disable-next-line
	}, []);

	const { accountId } = useUserInfo();

	const staleTime = 120000;
	const [selectedCrumb,setSelectedCrumb] = useState(breadCrumbItems[1]);
	const handleBreadCrumbClick = (event) => {
		if(event.target.id === "applications") {
			setSelectedCrumb(breadCrumbItems[0]);
			returnToAccountManagement(); 
		}
	};

	const returnToAccountManagement = () => {
		resetApplicationsStore();
		setIsApplicationCreateActive(false);
	};

	const {
		isLoading: permissionsIsLoading,
		isError: permissionsIsError,
		error: permissionsError,
		data: productsPermissions = {}
	} = useQueryWithAuthorization('all-products-permissions', getProductsPermissions(), {
		staleTime
	});

	const {
		data: projects = [],
		isError: projectsIsError,
		isLoading: projectsIsLoading,
		error: projectsError
	}  = useQueryWithAuthorization(['projects', accountId], getProjectsByAccountId(accountId), {
		select: mapProjectsToUiElement,
		enabled: accountId != null,
		staleTime: staleTime
	});

	if(permissionsIsLoading || projectsIsLoading) return <Loader width={60} height={60}/>;
	if(permissionsIsError) return <div id={'createApplication_permissions_error'}>{getMessageFromError(permissionsError)}</div>;
	if(projectsIsError) return <div id={'createApplication_permissions_error'}>{getMessageFromError(projectsError)}</div>;

	return (
		<div className={styles.createApplicationRoot}>
			<Header
				showBreadCrumbs = {true}
				selectedCrumb={selectedCrumb}
				breadCrumbItems={breadCrumbItems}
				handleBreadCrumbClick={handleBreadCrumbClick}
				title={"Create Application"}
				compId={"Applications"}
			/>
			<CreateApplicationContainer
				projects={projects}
				productsPermissions={productsPermissions}
				returnToAccountManagement={returnToAccountManagement}
			/>
		</div>
	);
};

export default CreateApplication;
