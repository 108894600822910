import * as React from "react";

const ProductsSvg = (props) => (
	<svg
		width={28}
		height={28}
		viewBox="0 0 40 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M39.75 20C39.75 30.9076 30.9076 39.75 20 39.75C9.09238 39.75 0.25 30.9076 0.25 20C0.25 9.09238 9.09238 0.25 20 0.25C30.9076 0.25 39.75 9.09238 39.75 20Z"
			fill="white"
			stroke="#C2C2C2"
			strokeWidth={0.5}
		/>
		<path d="M29.9609 10.0391H20V20H29.9609V10.0391Z" fill="#5BCAD4" />
		<path d="M20 20H10.0391V29.9609H20V20Z" fill="#9164D1" />
	</svg>

);

export default ProductsSvg;
