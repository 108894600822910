import { useQuery } from "react-query";
import { fetchFn } from "../../external-apis";

const useQueryWithoutAuthorization = (queryKey, apiObj, options = {}) => {

	return useQuery(queryKey, async ({ signal }) => {

		apiObj.headers = apiObj.headers ?? {};

		return fetchFn(apiObj, { signal })();
	}, { ...options, retry: (count, { message }) => {
		try {

			const { status } = JSON.parse(message);

			return status < 500 ? false : count < 4;
		}catch (err) {
			return false;
		}
	} });
};

export default useQueryWithoutAuthorization;
