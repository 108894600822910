import React, { useEffect, useRef, useState } from 'react';
import { Button, Card } from "@ip-synamedia/common-ui-library";
import { getAudit } from "../../../../external-apis";
import { useUserInfo } from "../../../../auth/UserInfoProvider";
import useQueryWithAuthorization from "../../../../custom-hooks/external-api-hooks/useQueryWithAuthorization";
import Loader from "../../../../components/Loader";
import converter from "json-2-csv";
import styles from "./Audit.module.css";
import { toast } from "react-toastify";
import getMessageFromError from "../../../../utils/API-calls";
import DatePicker from "react-datepicker";
import CalendarIcon from "../../../../assets/icons/audit/calendarIcon";
import "react-datepicker/dist/react-datepicker.css";

const moment = require('moment');

const timeRanges = [
	{
		id: 'today',
		displayName: 'Today'
	}, {
		id: 'yesterday',
		displayName: 'Yesterday'
	}, {
		id: 'sevenDays',
		displayName: 'Last 7 days'
	}, {
		id: 'thirtyDays',
		displayName: 'Last 30 days'
	},
	{
		id: 'custom',
		displayName: 'Custom'
	},
];

const getStartOfDayISO = (numToSubTractInDays, customDateInIsoTime) => {

	let date;

	if (customDateInIsoTime) {
		date = customDateInIsoTime;
	} else {
		date = new Date().toISOString();
	}

	const m = (moment(date).subtract(numToSubTractInDays, 'days')).utcOffset(0);

	m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

	return m.toISOString();
};

const getArgsForFetch = (type, startCustom, endCustom) => {

	let end;
	let start;

	switch (type) {
	case ('today'):
		start = getStartOfDayISO(0);
		end = new Date().toISOString();
		break;
	case('yesterday'):
		start = getStartOfDayISO(1);
		end = getStartOfDayISO(0);
		break;
	case('sevenDays'):
		start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString();
		end = new Date().toISOString();
		break;
	case('thirtyDays'):
		start = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
		end = new Date().toISOString();
		break;
	case('custom'):
		start = getStartOfDayISO(0,startCustom);
		end = getStartOfDayISO(-1,endCustom);
		break;
	default:
		start = new Date().toISOString();
		end = new Date().toISOString();
	}

	return { start, end };
};

function DownloadData({ csvString, refresh }) {
	const csvLink = useRef(null);

	useEffect(() => {
		csvString && csvLink?.current?.click();

	}, [csvString]);

	function downloadCsv() {
		const _link = document.createElement('a');
		const fileName = `synamedia_audit_logs`;

		_link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString));
		_link.setAttribute('download', fileName);
		_link.style.display = 'none';
		document.body.appendChild(_link);
		_link.click();
		document.body.removeChild(_link);
	}

	const onClick = () => {
		downloadCsv();
		refresh();
	};
	const linkStyles = {
		display: 'none'
	};

	return (
		<>
			<button
				style={linkStyles}
				onClick={onClick}
				ref={csvLink}
			/>
		</>
	);
}

const AuditLoader = ({ accountId, activeRangeId, isCustom = false, startCustom, endCustom, refresh }) => {

	const { start, end } = isCustom ?  getArgsForFetch(activeRangeId, startCustom, endCustom ) : getArgsForFetch(activeRangeId);
	const [csvString, setCsvString] = useState("");

	const {
		data: auditRes,
		isError: auditIsError,
		isLoading: auditIsLoading,
		error: auditError
	} = useQueryWithAuthorization(['get-audit', accountId, activeRangeId], getAudit(accountId, start, end), { staleTime: 0 });

	const handleSuccess = () => {
		const getCsv = async () => {
			return await converter.json2csvAsync(auditRes.report);
		};
		const wrapper = async () => {
			return await getCsv();
		};

		const data = wrapper();

		data.then((content) => {
			setCsvString(content);
		});

	};

	const handleError = () => {

		toast.error(<div id={'auditLogs_toast_error'}>{getMessageFromError(auditError)}</div>, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			toastId: 'error',

		});

		refresh();
	};

	return (

		<>
			{auditIsLoading ? <div id={'auditLogs_loader_loading'} className={styles.loaderBackDrop}>
				<Loader/></div> : auditIsError ? handleError() : handleSuccess()}
			{<DownloadData csvString={csvString} refresh={refresh}/>}
		</>
	);
};

const AuditContent = () => {
	const [activeRangeId, setActiveRangeId] = useState("");
	const [isAuditDownloading, setIsAuditIsDownloading] = useState(false);
	const [start, setStart] = useState(new Date());
	const [end, setEnd] = useState(new Date());

	const isCustom = (activeRangeId === 'custom');
	const { accountId } = useUserInfo();

	const handleChangeStart = (data) => {

		const _start = new Date(data);

		setStart(_start);
	};
	const handleChangeEnd = (data) => {
		setEnd(new Date(data));
	};
	const refresh = () => {
		setIsAuditIsDownloading(false);
		setActiveRangeId('');
		setStart(new Date());
		setEnd(new Date());
	};
	
	return (
		<div className={styles.rootContainer}>
			<div className={styles.cardContainer}>
				<div id={'auditLogs_instructions_title'} className={styles.title}>Download Audit Logs</div>
				<div className={styles.instruction}>
					<div className={styles.rangeTitle} id={'auditLogs_instructions_text'}>Select a date range</div>
					<div className={styles.rangeRequire} id={'auditLogs_instructions_require'}>*</div>
				</div>
				<div className={styles.optionsContainer}>
					<div className={styles.rangesContainer}>
						{timeRanges.map(timeRange => {
							return <div
								key={timeRange.id}
								className={styles.rangeCardContainer}
							>
								<Card
									id={timeRange.id}
									style={{ minWidth: "max-content" }}
									checked={activeRangeId === timeRange.id}
									onToggleChecked={() => setActiveRangeId(timeRange.id)}
									title={timeRange.displayName}
									type="singleSelect"
								>
								</Card>
							</div>;
						})}
					</div>
					{isCustom && <div id={'auditLogs_custom_container'} className={styles.row}>
						<div className={styles.datePickerContainerStart}>
							<div id={'auditLogs_customStart_label'} className={styles.datePickerLabel}>
			                Start date
							</div>
							<div className={styles.grid}>
								<DatePicker id={'auditLogs_datePicker_start'} selected={start} onChange={handleChangeStart}
									className={styles.datePickerInput}
									wrapperClassName={styles.datePickerWrapper}
								/>
								<div className={styles.calendarIcon} id={'auditLogs_customStart_icon'}>
									<CalendarIcon/>
								</div>
							</div>
						</div>
						<div className={styles.datePickerContainerEnd}>
							<div id={'auditLogs_customEnd_label'} className={styles.datePickerLabel}>
			                End date
							</div>
							<div className={styles.grid}>
								<DatePicker id={'auditLogs_datePicker_end'} selected={end} onChange={handleChangeEnd} className={styles.datePickerInput}
									wrapperClassName={styles.datePickerWrapper}/>
								<div id={'auditLogs_customEnd_icon'} className={styles.calendarIcon}>
									<CalendarIcon/>
								</div>
							</div>
						</div>
					</div>
					}
					<div className={styles.actionsContainer}>
						<Button
							id={'auditLogs_actions_clear'}
							onClick={() => {
								setActiveRangeId(undefined);
								setIsAuditIsDownloading(false);
							}}
							color={"ghost"}
						>
							Clear
						</Button>
						<Button
							id={'auditLogs_actions_download'}
							data-cy={!activeRangeId || (start > end) ? 'disabled':'enabled'}
							disabled={!activeRangeId || (start > end)}
							onClick={() => {
								setIsAuditIsDownloading(true);
							}}
						>
			            Download Logs
						</Button>
						{isAuditDownloading &&
							<AuditLoader accountId={accountId} activeRangeId={activeRangeId} isCustom={isCustom} startCustom={start.toISOString()} endCustom={end.toISOString()} refresh={refresh}/>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuditContent;
