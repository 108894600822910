import React, { useState } from 'react';
import SynamediaAuthContainer from "../../components/SynamediaAuthContainer";
import AppButton from "../../components/AppButton";
import { Navigate } from "react-router-dom";
import { ReactComponent as EmailBox } from '../../assets/login/emailBox.svg';
import styles from './messageSent.module.css';
import RecoverAccountCall from "./apiMennager";

export default function StepTwo({ email, backToStep1 }) {

	const [backToSignIn, setBackToSignIn] = useState(false);

	RecoverAccountCall({ email });

	const handleBackToSignIn = () => {
		setBackToSignIn(true);
	};

	const form = (
		<div className={styles.formContainer}>
			<AppButton
				variant={'contained'}
				type={'submit'}
				overrideClasses={{ button: styles.setupAccountButton }}
				id={'forgotAccount_step2_resendBtn'}
				onClick={() => {backToStep1();}}
			>
				{'Resend Email'}
			</AppButton>
			<div className={styles.backToCol}>
				<div className={styles.backToRow}>
                    Back to <div className={styles.signIn} onClick={handleBackToSignIn}> Sign In</div>
				</div>
			</div>
		</div>
	);

	const content = <div id={'forgotAccount_step2_root'} className={styles.rootContainer}>
		<div className={styles.emailBoxContainer}>
			<EmailBox id={'forgotAccount_step2_icon'} />
		</div>
		<div className={styles.headerContainer}>
			<div  id={'forgotAccount_step2_title'} className={styles.title}>Check your inbox</div>
			<div
				id={'forgotAccount_step2_label'}
				className={styles.label}>{`An email has been sent to ${email} with instructions to reset your password.`}</div>
			<div
				id={'forgotAccount_step2_content'}
				className={styles.content}>If you didn’t receive an email then please contact your Synamedia representative.</div>
		</div>
		{form}
	</div>;

	return (backToSignIn ? <Navigate to={`/loginhome`}/> :
		<SynamediaAuthContainer
			width={450}
			content={content}
			desc={<div>Your single access point for all your Synamedia products.</div>}
		/>
	);
}