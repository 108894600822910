import React from 'react';
import shallow from "zustand/shallow";
import { makeStyles } from "@material-ui/core/styles";
import { useAppPanelStore } from "../../state-management";

const useStyles = makeStyles((theme) => {
	return {
		sideBarContainer: {
			height: '100%',
			backgroundColor: "#212B3A",
		},
		rootContainer: {
			width: 238,
			maxWidth: 238,
			height: '100%',
			paddingTop: 64
		},
		contentContainer: {
			marginTop: 24,
			// height: '100%'
		},
		logo: {
			margin: 20,
			width: 175,
			height: 40,
		}
	};
});

export default function AppSidebar() {
	const styles = useStyles();
	const sidebarComponent = useAppPanelStore(state => state.sidebarComponent, shallow);

	return ( 
		<div className={styles.sideBarContainer}>
			<div className={styles.rootContainer}>
				<div className={styles.contentContainer}>
					{sidebarComponent}
				</div>
			</div>
		</div>
	);
}

