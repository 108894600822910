import React, { useState } from 'react';
import styles from "./CreateApplicationContainer.module.css";
import { useQueryClient } from 'react-query';
import { useMutationWithAuthorization } from '../../../../../../custom-hooks';
import { useUserInfo } from '../../../../../../auth/UserInfoProvider';
import { Card, TextArea, TextInput, PopUp } from '@ip-synamedia/common-ui-library';
import { toast } from 'react-toastify';
import ApplicationSummary from "../../ApplicationSummary";
import Loader from "../../../../../../components/Loader";
import { createApplication as apiCreateApp } from '../../../../../../external-apis';
import { secretCopyCb } from '../../appUtils';
import CustomList from '../../CustomList';

const CreateApplicationContainer = (props) => {
	const {
		projects,
		productsPermissions,
		returnToAccountManagement,
	} = props;
	const queryClient = useQueryClient();
	const { mutateAsync } = useMutationWithAuthorization();
	const { accountId } = useUserInfo();
	const [isCreating,setIsCreating] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [secretDialogVisible, setSecretDialogVisible] = useState(false);
	const [secretData, setSecretData] = useState(null);
	const [createApplicationData, setCreateApplicationData] = useState({
		application: "",
		applicationDescription: "",
		space: "",
		spaceId: "",
		permissions: [],
		permissionsGroup: {}
	});

	const handleCreate = () => {
		if(!accountId) return;
		const appData = {
			name: createApplicationData?.application.trim(),
			permissionIds: createApplicationData?.permissions
		};

		setIsCreating(true);
		const createAppPromise = mutateAsync(apiCreateApp(accountId, createApplicationData?.spaceId, appData));

		toast.promise(createAppPromise,
			{
				pending: "Creating Application...",
				success: {
					render({ data: { clientSecret, application: { name: appName, clientId } } } = {}) {
						setSecretData({ secret: clientSecret, appName, clientId });
						setSecretDialogVisible(true);
						queryClient.invalidateQueries(['get-application-by-account-id-and-project-id', accountId, createApplicationData?.spaceId]);
						setIsCreating(false);

						return "Application created successfully";
					}
				},
				error: {
					render({ data: error }) {
						console.error('Error creating Application', error);
						setIsCreating(false);

						return `Failed to create application: ${error.message}`;
					},
				}
			}, {
				position: toast.POSITION.TOP_CENTER
			}
		);
	};

	const handleCancel = () => {
		setSecretDialogVisible(false);
		setSecretData(null);
		returnToAccountManagement();
	};

	const handleClose = () => {
		return handleCancel();
	};

	const isProcessDisabled = createApplicationData?.permissions.length === 0 || isCreating ||
	createApplicationData?.application === "" || createApplicationData?.spaceId === "";

	return (
		<div className={styles.createApplicationContainer}>
			<div className={styles.createApplicationFrom}>
				<div className={styles.applicationDetailsContainer}>
					<div id={'createApplication_formContainer_title'} className={styles.applicationDetailsTitle}>
	 					{'Application Details'}
	 				</div>
					 <div className={styles.userInputContainer}>
		 				<div className={styles.userInputRow}>
		 					<div className={styles.inputTextContainer} id={"createApplication_name_input_container"}>
		 						<TextInput
									required
									style={{ width: "100%" }}
									id={'createApplication_name_textInput'}
									label={<>Application name <span className={styles.required}>*</span></>}
									value={createApplicationData?.application}
									onChange={(e) => {
										setFirstRender(false);
										setCreateApplicationData({ ...createApplicationData, application: (e.target.value) });
									}}
									error={(!firstRender && createApplicationData?.application === "")}
									helperText={(!firstRender && createApplicationData?.application) === "" ? "Please enter a valid application name" : ""}
								/>
		 					</div>
							<div className={styles.textAreaContainer} style={{ display: "none" }}>
								<TextArea
									label="Application description"
									// value={createApplicationData?.applicationDescription}
									// onChange={()=>}
									id="createApplicationDescription"
									// error={}
									// helperText={}
									placeholder="Add a description for your application"
									resizing = {false}
									rows={3}
									style={{ width: "100%" }}
								/>
							</div>
		 				</div>
		 			</div>
				</div>
				{Object.keys(productsPermissions).length && Object.keys(productsPermissions?.productsPermissions).length ? <div className={styles.permissionsContainer}>
					<div id={'createApplication_permissionsContainer_title'} className={styles.permissionsHeader}>
	 					{'Space and Permissions'}
	 				</div>
					<div id={"createApplication_spaceSelection_Container"} className={styles.spaceSelectionContainer}>
						<div id={"createApplication_spaceSelection_label"} className={styles.spaceSelectionLabel}><>Select a space <span className={styles.required}>*</span></></div>
						<div id={"createApplication_spaceList"} className={styles.spaceSelectionList}>
							{Array.isArray(projects) && projects.length ? projects.map((space) => (
								<Card
									className={styles.spaceCard}
									id={space?.id}
									key={space?.id}
									data-style-id={"space"}
									title={space?.label}
									type="singleSelect"
									onToggleChecked={() => {
										if(createApplicationData?.spaceId === "" || createApplicationData?.spaceId !== space?.id)
											setCreateApplicationData({ ...createApplicationData, spaceId: space?.id, space: space?.label });
										else
											setCreateApplicationData({ ...createApplicationData, spaceId: "", space: "" });
										setFirstRender(false);
									}}
									checked={createApplicationData?.spaceId === space?.id}
								/>
							)) : null}
						</div>
					</div>
					<CustomList
						id={"createApplication"}
						applicationData={createApplicationData}
						setApplicationData={setCreateApplicationData}
						setFirstRender={setFirstRender}
						productsPermissions={productsPermissions}
					/>
				</div> : <span>Unexpected error please try again later</span>}
			</div>
			<ApplicationSummary
				summaryTitle="Summary"
				isEdit={false}
				handleProcessing={handleCreate}
				handleCancel={handleCancel}
				applicationData={createApplicationData}
				processDisabled={isProcessDisabled}
				isProcessing={isCreating}
			/>
			{isCreating ? <div className={styles.loaderBackDrop}><Loader/></div> : null}
			<PopUp
				onClose={() => handleCancel()}
				open={secretDialogVisible}
				size="small"
				title="Application Details"
				id="application_details"
				dimmer={true}
				description={
					<div id={"createApplication_popup_description_container"} className={styles.descriptionContianer}>
						<Card style={{ width: "100%" }}>
							<div id={"createApplication_popup_description_content"} className={styles.descriptionContent}>
								<span><TextInput id={"NewAppName"} className={styles.inputFiled} readOnly={true} label={"Application Name"} value={secretData?.appName} /></span>
								<span><TextInput id={"NewClientId"} className={styles.inputFiled} readOnly={true} label={"Client Id"} value={secretData?.clientId} /></span>
								<span><TextInput id={"NewClientSecret"} className={styles.inputFiled} readOnly={true} showActions={true} type="password" label={"Client Secret"} handleCopyCb={secretCopyCb} value={secretData?.secret} /></span>
							</div>
						</Card>
					</div>
				}
				showFooter={true}
				onAccept={() => handleClose()}
				acceptButtonLabel={'CLOSE'}
				acceptButtonProps={{
					id:"createApplication_popup_close_button",
				}}
				cancelButtonProps={
					{
						style: {
							display: "none"
						}
					}
				}
			/>
		</div>
	);
};

export default CreateApplicationContainer;
