export const mapProductsRoles = (products, isAdmin) => {
	const productsRoles = (products ?? []).map(product => {
		const { id, displayName, roles } = product;
		const adminRoles = isAdmin ? roles.filter(role => role.isProductAdmin === true) : roles.filter(role => !role.isProductAdmin) || [];

		if (adminRoles?.length > 0) {
			return {
				id,
				displayName,
				roles: adminRoles
			};
		} else {
			return null;
		}
	});

	return productsRoles.filter(product => product != null);
};

export const mapProjectsToUiElement = (projects) => {
	return (projects || []).map(project => {
		const { id, displayName } = project;

		return {
			id,
			label: displayName
		};
	});
};

export const mapAccountRoles = (rolesResponse) => {
	return (rolesResponse ?? []).map((role) => {
		const { id, displayName, isProductAdmin, description } = role;

		return {
			id,
			label: displayName,
			isProductAdmin,
			description,
		};
	});
};

export const mapFilterProductRoles = (tenantsPerProjects, productRoles, projects) => {
	const finalProjectRoles = {};

	const projectLabels = {};

	projects.forEach(project =>  {
		projectLabels[project.id] = project.label;
	});

	Object.keys(tenantsPerProjects).forEach(projectId => {
		const productsOfProject = tenantsPerProjects[projectId];

		productsOfProject.forEach(p => {
			const theProduct = productRoles.find(product => (product.id === p) );

			if (theProduct) {
				if(!finalProjectRoles[projectId]) {
					finalProjectRoles[projectId] = {
						id: projectId,
						label: projectLabels[projectId],
						productRoles: [theProduct]
					};
				} else {
					finalProjectRoles[projectId].productRoles.push(theProduct);
				}
			}
		});
	});

	const finalProjectRolesArray = Object.keys(finalProjectRoles).map(projectId => finalProjectRoles[projectId]);

	return finalProjectRolesArray;
};
