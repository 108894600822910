import create from "zustand";

const initialState = Object.freeze({
	isInviteUser: false
});

const deepLinkStore = create((set) => ({
	...initialState,

	setIsInviteUser: (val) => {
		set(() => ({
			isInviteUser : val
		}));
	},
	reset: () => {
		set(() => ({
			...initialState
		}));
	}
}));

export default deepLinkStore;