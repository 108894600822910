import * as React from "react";

const CsfEyeSVG = (props) => (
	<svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M0.143957 15.7994C0.079634 16.2854 0.036752 16.7746 0.0159607 17.2652V17.2645C-0.0977416 19.9089 0.416193 22.5981 1.52593 25.123C2.76951 27.9513 4.68295 30.4215 7.05966 32.2674C8.53649 33.4142 10.663 33.1465 11.8098 31.669C12.9566 30.1922 12.6889 28.065 11.2121 26.9182C9.74303 25.7779 8.50465 24.1724 7.72433 22.3974C6.90762 20.5392 6.6068 18.5646 6.85564 16.6876C7.21754 13.9568 8.72491 11.3007 10.9918 9.40088C13.3835 7.39647 16.3482 6.49075 19.3389 6.85005C20.9456 7.04302 22.4316 7.52187 23.7563 8.27296C25.0097 8.98376 26.0876 9.91872 26.9595 11.0512C28.7307 13.3525 29.5058 16.2906 29.1406 19.3248C28.9158 21.1947 28.1719 22.9899 26.9296 24.6623C25.8813 26.0724 24.8635 26.8588 24.8164 26.8952L24.8161 26.8954C23.3308 28.0318 23.0475 30.1564 24.1832 31.6424C25.3189 33.1276 27.4436 33.4109 28.9288 32.2752C29.3524 31.9516 30.852 30.7366 32.3639 28.7023C34.319 26.0716 35.4963 23.1887 35.8634 20.1331C36.4403 15.3277 35.1844 10.636 32.3256 6.92087C29.4109 3.13296 25.0857 0.720524 20.1478 0.126674C15.28 -0.458081 10.4844 0.99211 6.64318 4.21086C3.09502 7.18596 0.725463 11.4092 0.143957 15.7994ZM12.0177 17.8181C11.8758 21.1283 14.4443 23.9267 17.7545 24.0686C21.0647 24.2104 23.8631 21.6419 24.005 18.3317C24.1468 15.0215 21.5783 12.2231 18.2681 12.0813C14.9579 11.9394 12.1595 14.5079 12.0177 17.8181Z" fill="url(#paint0_linear_6896_230221)"/>
		<defs>
			<linearGradient id="paint0_linear_6896_230221" x1="17.9739" y1="16.4894" x2="18.4562" y2="51.4841" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FA4D56"/>
				<stop offset="1" stopColor="#003A99"/>
			</linearGradient>
		</defs>
	</svg>

);

export default CsfEyeSVG;
