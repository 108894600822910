import * as React from "react";

const EverguardSVG = (props) => (
	<svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M31.7859 3.63655L16.5524 0.0799255L0.239502 3.65441V18.1466L0.256892 18.309C0.695223 22.4033 4.68425 29.9386 14.5266 35.2873L15.9852 36.0799L17.4451 35.2895C27.3273 29.939 31.3295 22.3832 31.7685 18.2823C31.9484 16.6016 30.7318 15.0934 29.0511 14.9135C27.3705 14.7336 25.8622 15.9502 25.6823 17.6308C25.5149 19.195 23.3469 24.5884 15.9907 29.0684C8.86194 24.7108 6.62739 19.5008 6.36044 17.7885V8.57935L16.5095 6.35547L25.6649 8.49302V9.76037C25.6649 11.4506 27.0351 12.8208 28.7254 12.8208C30.4156 12.8208 31.7859 11.4506 31.7859 9.76037V3.63655ZM16.0134 9.66829C14.4531 9.66829 13.1883 10.9331 13.1883 12.4933V21.6747C13.1883 23.235 14.4531 24.4998 16.0134 24.4998C17.5736 24.4998 18.8384 23.235 18.8384 21.6747V12.4933C18.8384 10.9331 17.5736 9.66829 16.0134 9.66829Z" fill="url(#paint0_linear_6896_230199)"/>
		<defs>
			<linearGradient id="paint0_linear_6896_230199" x1="16.0479" y1="18.08" x2="15.9365" y2="56.3339" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FA4D56"/>
				<stop offset="1" stopColor="#003A99"/>
			</linearGradient>
		</defs>
	</svg>

);

export default EverguardSVG;
