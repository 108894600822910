import { Dropdown } from '@ip-synamedia/common-ui-library';
import React from 'react';

const Roles = (props) => {
	const {
		row,
		currProductsRoles, 
		setCurrProductsRoles,
		setParentFirstRender, 
		selectedProductsIds,
		setSelectedProductsIds,
		isProductAdmin,
		productRolesByProjectId,
		projectId,
		setProductRolesByProjectId,
		productAdminRolesIds,
		currProductAdminRoles,
		setCurrProductAdminRole,
		setProductAdminRolesIds,
		styles,
	} = props;

	const productId = row.id; // spaceId (prod,staging, dev)
	const rolesOptions = row.roles.map(role => ({ ...role,label:role.displayName, productId: productId, info: role.description }));

	const getSelectedRolesIds = () => {
		let selectedRoles = [];

		if(currProductsRoles?.[productId].length) {
			rolesOptions.forEach(option => {
				currProductsRoles?.[productId].forEach(role => {
					if(role.id === option.id) {
						selectedRoles.push(option.id);
					}
				});
			});
		}

		return selectedRoles;
	};

	const handleOnValueChange = (incomingOption) => {

		setParentFirstRender && setParentFirstRender(false);

		if (!selectedProductsIds.has(productId)) {
			selectedProductsIds.add(productId);
			setSelectedProductsIds(new Set(selectedProductsIds));
		}
		let incomingOptionsObj = [];

		if(incomingOption.length) {
			incomingOptionsObj = rolesOptions.filter(role => incomingOption.includes(role.id));
		}

		if(!isProductAdmin) {

			currProductsRoles[productId] = [...incomingOptionsObj];
			setCurrProductsRoles(currProductsRoles);
			const unseenRoles = {};
			const displayedProductIds = Object.keys(currProductsRoles);

			productRolesByProjectId[projectId].forEach(role => {
				const productId = role.productId;

				if (!displayedProductIds.includes(productId) ) {
					if(!unseenRoles[productId]) {
						unseenRoles[productId] = [];
					}
					unseenRoles[productId].push(role);
				}
			});

			const allUserRoles = { ...currProductsRoles, ...unseenRoles };

			productRolesByProjectId[projectId] = [].concat.apply([],Object.values(allUserRoles));
			setProductRolesByProjectId(productRolesByProjectId);
		} else {
			currProductAdminRoles[productId] = [...incomingOptionsObj];
			setCurrProductAdminRole(currProductAdminRoles);

			setProductAdminRolesIds(new Set( ...[[].concat.apply([],Object.values(currProductAdminRoles)).map(x => x.id)]));
		}
	};

	return (
		<div className={styles.multiselectContainer} id={productId}>
			<div id={productId} className={styles.rolesDisplayName}>{row.displayName}</div>
			<Dropdown
				id={`inviteUser_productRoleDropDown_${productId}`}
				entity="roles"
				multiple
				onValueChange={handleOnValueChange}
				options={rolesOptions}
				value={isProductAdmin ? [...productAdminRolesIds].filter(roleId => !!rolesOptions.find(r => r.id ===roleId)) : currProductsRoles?.[productId] && getSelectedRolesIds()}
				width="238px"
			/>
		</div>
	);
};

export default Roles;