import React from "react";
import { Card as CardUI } from "@ip-synamedia/common-ui-library";

export const Card = ({
	className,
	children,
	...rest
}) => {

	return (
		<CardUI className={className} {...rest}>{children}</CardUI>
	);
};
