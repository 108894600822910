import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ReactLoader from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => {
	return {
		container: {
			height: "100%",
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',

			/* position the div in center */
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		},
		title: {
			top: 20,
			fontSize: 24
		},
	};
});

const Auth0CallbackPage = () => {
	const styles = useStyles();
	const navigate = useNavigate();

	const { error } = useAuth0();

	if (error) {
		console.error(JSON.stringify(error));
		if(error.message === 'invitation not found or already used' && error?.error === 'invalid_request') {
			navigate('/inviteExpired');
		}
		else {
			navigate('/accessDenied');
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.title}>Redirecting</div>
			<ReactLoader
				type={'ThreeDots'}
				color={'#3381ff'}
				height={100}
				width={100}
			/>
		</div>
	);
};

export default Auth0CallbackPage;
