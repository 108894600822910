import React, { useState } from 'react';
import shallow from 'zustand/shallow';
import { useUsersInvitationsStore } from '../../../../../../state-management';
import EditUserForm from './EditUserForm';
import { mapProductsRoles } from "../../useUsers";

const EditUserContainer = (props) => {
	const {
		firstRender,
		setFirstRender,
		currentUserData,
		returnToAccountManagement,
		projects,
		allRoles,
		accountRoles,
		userAccountRoles,
		rolesQueries,
	} = props;
	const [initialState, setInitialState] = useState({});

	const userInvitationData = useUsersInvitationsStore(state => ({
		email: state.email,
		accountRolesIds: state.accountRolesIds,
		productAdminRolesIds: state.productAdminRolesIds,
		productRolesByProjectId: state.productRolesByProjectId,
		jobTitle: state.jobTitle,
		userName: state.userName
	}), shallow);

	const productRoles = mapProductsRoles(allRoles, false);
	const productAdminRoles = mapProductsRoles(allRoles, true);

	return (
		<EditUserForm
			userData={currentUserData} 
			projects={projects} 
			accountRoles={accountRoles}
			userAccountRoles={userAccountRoles}
			rolesQueries={rolesQueries}
			firstRender={firstRender}
			setFirstRender={setFirstRender}
			returnToAccountManagement={returnToAccountManagement}
			userInvitationData={userInvitationData}
			initialState={initialState}
			setInitialState={setInitialState}
			productRoles={productRoles}
			productAdminRoles={productAdminRoles}
		/>
	);
};

export default EditUserContainer;