import React from 'react';
import { ApplicationsIcon } from '@ip-synamedia/common-ui-library';
import styles from './ProductSwitcher.module.css';
import { useMainContainerStore } from "../../../state-management";
import Loader from "../../Loader";
import { useUserInfo } from "../../../auth/UserInfoProvider";
import getMessageFromError from '../../../utils/API-calls';
import { getIconFromId } from '../../../utils/component-utils';
import AppTooltip from '../../AppTooltip';
import Modal from '../../Modal';
import { Link } from "react-router-dom";
import NavBarIconButton from '../../NavBarIconButton';

const getProductsMap = (productsList, tenantsData, productsMap) => {
	if (Array.isArray(productsList)) {
		productsList?.forEach((product) => {
			productsMap.set(product.id, product);
			tenantsData.forEach((data) => {
				if (product.id === data.productId) {
					if (productsMap.has(product.id)) {
						let tempObj = productsMap.get(product.id);

						tempObj.tenantId = data.tenantId;
						productsMap.set(product.id, tempObj);
					} else {
						product.tenantId = data.tenantId;
						productsMap.set(product.id, product);
					}
				}
			});
		});
	}
};

const ProductSwitcher = ({ open, handleSetOpen }) => {
	const { accountAlias, scope } = useUserInfo();
	const [projectsPromise, productsPromise, tenantsPromise] = useMainContainerStore(state => [state.projectsPromise, state.productsPromise, state.tenantsPromise]);
	const productsMap = new Map();
	let showManageAccount = false;

	getProductsMap(productsPromise?.data, tenantsPromise?.data ?? [], productsMap);

	if (scope?.includes("account:user:list") || scope?.includes("account:application:list") || scope?.includes("account:audit:list")) {
		showManageAccount = true;
	}

	const manageAccount = {
		linkTitle: 'Manage Account',
		link: `/${accountAlias}/account`,
		id: "Manage Account"
	};

	if (scope?.includes("account:user:list")) {
		manageAccount.link = `/${accountAlias}/account/users`;
	} else if (scope?.includes("account:application:list")) {
		manageAccount.link = `/${accountAlias}/account/applications`;
	} else if (scope?.includes("account:audit:list")) {
		manageAccount.link = `/${accountAlias}/account/audit`;
	}

	const handleSwitcherChange = () => {
		handleSetOpen(false);
	};

	const RenderIconsList = () => {
		return (
			<div className={styles.productInfoListContainer} id={"productInfoListContainer"}>
				<div className={styles.productInfoRow} id={"productInfoRow"}>
					{(Array.isArray(productsPromise?.data) && productsPromise?.data?.length) ? productsPromise?.data?.map(({
						id,
						displayName
					}, index) => {
						const data = (productsMap.has(id) && productsMap.get(id)) || "";
						const toolTipMessage = data?.tenantId ? '' : "Product Not Installed";
						const productIcon = getIconFromId(id);
						const link = data?.tenantId ? "/" + accountAlias + "/launchProduct/" + data.tenantId : "/";

						return (
							<AppTooltip key={id} title={toolTipMessage} disabled={data?.tenantId}>
								<div id={index + 'productSwitcher_Link'} className={styles.productInfo}
									style={{ cursor: data?.tenantId ? 'pointer' : 'not-allowed' }}>
									<Link
										onClick={() => handleSetOpen(false)}
										to={link}
										rel="noreferrer"
										disabled={!data?.tenantId}
										style={
											{
												width: '100%',
												height: '100%',
												textAlign: 'center',
												wordBreak: 'break-word',
												justifyContent: 'center',
												display: 'flex',
												flexDirection: 'column',
												textDecoration: "none",
												color: "inherit",
												pointerEvents: data?.tenantId ? "auto" : "none",
											}
										}
										target={"_blank"}>
										<div className={styles.productIconSvgContainer}>
											<div className={styles.productIconSvg}>{productIcon}</div>
										</div>
										<div className={styles.productName}>{displayName}</div>

									</Link>
								</div>
							</AppTooltip>
						);
					}) : null}
				</div>
			</div>
		);
	};

	return (
		<Modal handleSetOpen={handleSetOpen}>
			<div className={styles.productSwitcherContainer} onClick={() => handleSetOpen(!open)}
				id={'product_switcher_root'}>
				<NavBarIconButton selected={open}>
					<ApplicationsIcon className={styles.productSwitcherIcon}/>
				</NavBarIconButton>
			</div>
			{
				(open && !tenantsPromise.isError && !productsPromise.error && !projectsPromise.error) ?
					<div className={styles.productSwitcherDropDown} id={'productSwitcherDropDown'}>
						{tenantsPromise.isLoading ? <Loader/> : <RenderIconsList/>}
						<div className={styles.btnContainer} id={'productSwitcherBtn'}>
							<Link to={manageAccount.link} className={styles.manageAccountBtn} action={'replace'}
								onClick={handleSwitcherChange}>{showManageAccount && manageAccount.linkTitle}</Link>
						</div>
					</div>
					: open && <div className={styles.productSwitcherDropDown} id={'productSwitcherDropDown'}>
						<div className={styles.productInfoError} id={'productSwitcher_DropDown_Error'}>
							{tenantsPromise.isError && getMessageFromError(tenantsPromise.error)}
							{productsPromise.error && !tenantsPromise.isError && getMessageFromError(productsPromise.error)}
							{projectsPromise.error && !productsPromise.error && !tenantsPromise.isError && getMessageFromError(productsPromise.error)}
						</div>
						<div>
							{
								<div className={styles.btnContainer} id={'productSwitcherBtn'}>
									<Link to={manageAccount.link} className={styles.manageAccountBtn} onClick={() => {
										handleSetOpen(false);
									}}>{manageAccount.linkTitle}</Link>
								</div>
							}
						</div>
					</div>
			}
		</Modal>

	);
};

export default ProductSwitcher;
