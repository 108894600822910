import React, { useState } from 'react';
import { Dropdown } from '@ip-synamedia/common-ui-library';
import styles from './HelpDoc.module.css';
import NavBarIconButton from '../../NavBarIconButton';
import { VectorIcon } from "@ip-synamedia/common-ui-library";
import { HelpIcon } from '@ip-synamedia/common-ui-library';
import { getDomain } from '../../../config';

const HelpDoc = () => {
	const [open, setOpen] = useState(false);
	const options = [{
		id: "products_docs",
		label: "Products Docs",
		icon: <VectorIcon className={styles.optionIcon} />,
		link: `https://docs.${getDomain()}`
	}, {
		id: "developer_docs",
		label: "Developer Docs",
		icon: <VectorIcon className={styles.optionIcon} />,
		link: `/login/developerPortal`
	}];

	// create a navbar store to manage the states of all the icons
	const handleSelectionChange = (selectedId) => {
		const selectedOption = options.find(option => option.id === selectedId);

		if (selectedOption && selectedOption.link) {
			window.open(selectedOption.link);
		}
	};

	return (
		<Dropdown
			open={open}
			onOpenChange={setOpen}
			options={options}
			onValueChange={handleSelectionChange}
			type="menu"
			align="end"
			id={"NavBar_helpDoc_dropdown"}
		>
			<NavBarIconButton selected={open} >
				<HelpIcon className={styles.iconItem} />
			</NavBarIconButton>
		</Dropdown>
	);
};

export default HelpDoc;