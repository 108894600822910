import React, { useEffect, useState } from "react";
import {
	useMainContainerStore,
	useUsersStore
} from "../../../../../state-management";
import shallow from "zustand/shallow";
import Loader from "../../../../../components/Loader";
import { Tab } from '@ip-synamedia/common-ui-library';
import ActiveUsers from "./ActiveUsers";
import InvitedUsers from "./InvitedUsers";

const tabs = [
	{
		id: 'activeUsers',
		label: 'Active',
		component: <ActiveUsers/>
	}, {
		id: 'invitedUsers',
		label: 'Invited',
		component: <InvitedUsers/>
	}
];
const tabToComponentData = (tabId) => {
	return tabs.find(tab => tab.id === tabId)?.component;
};

export default function UsersTabs({ accountId }) {
	const [selectedTabId, setSelectedTabId] = useState('activeUsers');
	const [setActiveTabComponent, activeTab] = useUsersStore(state => [state.setActiveTabComponent, state.activeTab], shallow);
	const handleTabChange = (tabId) => {
		setSelectedTabId(tabId);
	};

	useEffect(() => {
		if (activeTab) {
			handleTabChange(activeTab);
		}
		else {
			handleTabChange(tabs[0].id);
		}
	}, [activeTab]);

	useEffect(() => {
		setActiveTabComponent(tabToComponentData(selectedTabId));
	}, [selectedTabId, setActiveTabComponent]);

	const [{
		data: inviteesRes = [],
		isError: inviteesIsError,
		isLoading: inviteesIsLoading,
	}] = useMainContainerStore(state => [state.inviteesPromise], shallow);

	const [{
		data: usersRes = [],
		isError: usersIsError,
		isLoading: usersIsLoading,
	}] = useMainContainerStore(state => [state.usersPromise], shallow);

	if (usersIsLoading || inviteesIsLoading) return <Loader/>;

	return (
		<div style={{ display: 'flex' }}>
			{tabs.map(tab => {
				const labelAdding = tab.id === "activeUsers" ?(usersIsError || !accountId? '':` (${usersRes.length})`):(inviteesIsError || !accountId? '':` (${inviteesRes.length})`);

				return <Tab
					id={`usersTabs_${tab.id}`}
					key={'tab' + tab.id}
					onClick={() => handleTabChange(tab.id)}
					label={tab.label + labelAdding}
					selected={tab.id === selectedTabId}
				>
					<div/>
				</Tab>;
			})}
		</div>
	);
}
