import React from 'react';
import styles from "./ApplicationSummary.module.css";
import { Button, Card, Tag } from "@ip-synamedia/common-ui-library";
import InviteUsersDefaultSVG from '../../../../../assets/InviteUsersDefaultSVG';

const ApplicationSummary = ({
	isEdit = false,
	isProcessing,
	handleProcessing,
	processDisabled,
	handleCancel,
	applicationData,
	summaryTitle,
	...rest
}) => {
	const { space, permissions, permissionsGroup } = applicationData;
	const idPrefix = isEdit ? "edit" : "create";

	const summaryStatusOptions = [{
		id: "permissions",
		label: "Permissions",
		count: permissions?.length || 0,
		show: isEdit ? permissions?.length > 0 : true ,
	},
	{
		id: "products",
		label: "Products",
		count: typeof permissionsGroup === "object" ? Object?.values(permissionsGroup)?.length : 0,
		show: isEdit ? typeof permissionsGroup === "object" ? Object?.values(permissionsGroup)?.length > 0 : false : true,
	}
	];

	return (
		<div className={styles.summaryContainer} {...rest}>
			<div className={styles.summaryContent}>
				<div className={styles.titleContainer}>
					<div id={'editApplication_summary_title'} className="productiveHeading5">{summaryTitle}</div>
				</div>
				<div className={styles.summaryStatusContainer}>
					{summaryStatusOptions.map((item, index) => {
						return (
							item.show ? (<div id={`${idPrefix}Application_summary_${item.label}_container_${index}`} key={item.id}
								className={styles.statusContainer}>
								<div id={`${idPrefix}Application_summary_${item.label}_count_${index}`}
									className={styles.statusCount}>{item.count}</div>
								<div id={`${idPrefix}Application_summary_${item.label}_label_${index}`}
									className={styles.statusLabel}>{item.label}</div>
							</div>) : null
						);
					})}
				</div>
				<div className={styles.dividerContainer}>
					<div className={styles.divider}/>
				</div>
				<div className={styles.summaryDetailsContainer}>
					<div className={styles.summaryInfoContainer}>
						<div className={styles.infoContainer}>
							<div className={styles.infoTitle} id={`${idPrefix}Applications_space_title`}>{isEdit ? "Space" : "Selected Space"}</div>
							{space ? 
								<div className={styles.selectedSpaceContainer}>
									<Tag id={`${idPrefix}Applications_space_name`} label={space} type={"status"}/>
								</div>
								: 
								!isEdit ?
									<div id={`${idPrefix}Application_emptyState_Container`} className={styles.emptyStateContainer}> 
										<Card id={`${idPrefix}Application_emptyState_Card`} className={styles.emptyStateCard}>
											<div className={styles.artContainer}>
												<div id={`${idPrefix}Application_assignedRole_emptyIcon`}><InviteUsersDefaultSVG/></div>
												<div id={`${idPrefix}Application_assignedRole_emptyText`} className={styles.rolesDefaultMessage}>
											Select a space and then you will be able to pick the permissions.
												</div>
											</div>
										</Card>
									</div>
									: null
							}
						</div>

						{permissions?.length && typeof permissionsGroup === "object" && Object.keys(permissionsGroup)?.length ? <div className={styles.infoContainer}>
							<div className={styles.infoTitle} id={`${idPrefix}Applications_permissions_title`}>Permissions</div>
							<div className={styles.productListContiner}>
								{
									Object.entries(permissionsGroup).map(([productName, permissions], index) => {
										return <div className={styles.productPermissionsContainer} id={`${idPrefix}Applications_permissions_Contianer_${index}`} key={`${productName}_${index}`}>
											<div id={`${idPrefix}Applications_permissions_${index}`} className={styles.productName}>{productName}</div>
											<div id={`${idPrefix}Applications_permissions_tags_${index}`} className={styles.tags}>
												{
													permissions.map(permission => <Tag key={`${permission}_${index}`} id={`${idPrefix}Applications_permission_name_${index}`} label={permission}/>)
												}
											</div>
										</div>;
									})
								}
							</div>
						</div> : null}
					</div>
				</div>
			</div>
			<div id={`${idPrefix}Application_actions_container`} className={styles.actionsContainer}>
				<Button id={`${idPrefix}Application_cancel_button`} color="secondary" onClick={handleCancel}>Cancel</Button>
				<Button id={`${idPrefix}Application_${isEdit ? "save_changes" : "create"}_button`} 
					data-disabled={processDisabled} disabled={processDisabled} color="primary"
					onClick={handleProcessing}>{isProcessing ? (isEdit ? 'Saving Changes' : 'Creating Application' ) : (isEdit ? 'Save Changes' : 'Create Application')}</Button>
			</div>
		</div>
	);
};

export default ApplicationSummary;