import React from 'react';

import { getQueryParams } from "../../utils/router-utils";
import { NotFoundPage } from "../index";
import { Navigate } from "react-router-dom";
import Loader from "../../components/Loader";
import useAccountAlias from "../../custom-hooks/useAccountAlias";

const LoginPage = () => {

	const { invitation, organization } = getQueryParams();

	const accountAliasResponse = useAccountAlias(organization);

	document.title = 'Synamedia Cloud Portal | Login';

	if (!organization) {
		console.warn('Missing organization');

		return <Navigate to={`/loginhome`}/>;
	}

	if (invitation)
		return <Navigate to={`/invite?invitation=${invitation}&organization=${organization}`} />;

	if (accountAliasResponse.isLoading) return <Loader/>;
	if (accountAliasResponse.isError) {
		console.warn('Failed to lookup for account alias', accountAliasResponse.error.message);

		//TODO: introduce more accurate error for such a case
		return <NotFoundPage/>;
	}
	if (accountAliasResponse?.accountAlias) {
		return <Navigate to={`/${accountAliasResponse.accountAlias}`} replace/>;
	}

	return null;
};

export default LoginPage;

