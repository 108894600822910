import { useLayoutEffect } from 'react';
import styles from "./MatissePortalSideMenu.module.css";
import shallow from 'zustand/shallow';
import AccountUsers from '../MatissePortalPanel/AccountUsers';
import { useAppPanelStore, useUsersStore, useDeepLinkStore, useApplicationStore } from '../../../state-management';
import Applications from "../MatissePortalPanel/Applications";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../../../auth/UserInfoProvider";
import Audit from "../MatissePortalPanel/Audit";
import { SideMenu } from '@ip-synamedia/common-ui-library';
import AuditIcon from "../../../assets/icons/audit/auditIcon";
import AppIconSVG from "../../../assets/icons/sidePanelIcons/AppIconSVG";
import UserIconSVG from "../../../assets/icons/sidePanelIcons/UserIconSVG";

let sidebarItems = [
	{
		id: 'users',
		label: 'Users',
		icon: <UserIconSVG/>,
		component: <AccountUsers/>,
		path: "users",

	},
	{
		id: 'applications',
		label: 'Applications',
		icon: <AppIconSVG/>,
		component: <Applications/>,
		path: "applications",
	},
	{
		id: 'audit',
		label: 'Audit Logs',
		icon: <AuditIcon/>,
		component: <Audit/>,
		path: 'audit'
	}
];

export default function MatissePortalSidebar() {
	const { accountAlias, scope } = useUserInfo();
	const navigate = useNavigate();
	const [resetAppPanelStore, activeSidebarItemId, setActiveSidebarItemId, setMainContentComponent] = useAppPanelStore((state) => [state.reset, state.activeSidebarItemId, state.setActiveSidebarItemId, state.setContentComponent], shallow);
	const [isUserInviteActive, setIsUserInviteActive, isUserEditActive, setIsUserEditActive] = useUsersStore(state => [state.isUserInviteActive, state.setIsUserInviteActive, state.isUserEditActive, state.setIsUserEditActive], shallow);
	const [isApplicationCreateActive, setIsApplicationCreateActive, isApplicationEditActive, setIsApplicationEditActive] = useApplicationStore(state => [state.isApplicationCreateActive, state.setIsApplicationCreateActive, state.isApplicationEditActive, state.setIsApplicationEditActive]);
	const [isInviteUser, setIsInviteUser] = useDeepLinkStore(state => [state.isInviteUser, state.setIsInviteUser]);

	if (scope && !scope?.includes("account:user:list")) {
		sidebarItems = sidebarItems.filter(x => x.id !== 'users');
	}
	if (scope && !scope?.includes("account:application:list")) {
		sidebarItems = sidebarItems.filter(x => x.id !== 'applications');
	}
	if (scope && !scope?.includes("account:audit:list")) {
		sidebarItems = sidebarItems.filter(x => x.id !== 'audit');
	}

	const handleItemClick = (event, productId) => {
		if (productId === sidebarItems[0]?.id) {
			if (isUserEditActive) setIsUserEditActive(false);
			if (isUserInviteActive) setIsUserInviteActive(false);
			if (isInviteUser) setIsInviteUser(false);
		}
		if (productId === sidebarItems[1]?.id) {
			if (isApplicationCreateActive) setIsApplicationCreateActive(false);
			if (isApplicationEditActive) setIsApplicationEditActive(false);
		}
		if (activeSidebarItemId !== productId)
			navigate(`/${accountAlias}/account/${sidebarItems.find(item => item.id === productId).path}`);

		setActiveSidebarItemId(productId);

	};

	useLayoutEffect(() => {
		if (!activeSidebarItemId) {
			const _default = sidebarItems[0];

			setActiveSidebarItemId(_default.id);
			setMainContentComponent(_default.component);
		}

		return () => {
			resetAppPanelStore();
		};
		// eslint-disable-next-line
    }, []);

	return (
		<div className={styles.rootContainer}>
			<SideMenu
				style={{ width: "inherit", height: "calc( 100vh - 6rem )" }}
				className={styles.sideMenu}
				id={"menu"}
				menuData={sidebarItems}
				selectedId={activeSidebarItemId}
				onSelectionChange={handleItemClick}
				onCollapseChange={() => {
				}}
			/>
		</div>
	);
}
