import { Card } from "../../../components/Card";
import styles from "./topCard.module.css";
import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const TitleContainer = ({ title, subTitle,icon,id, index }) => {

	return ( icon ? <div className={styles.titleContainerWithIcon} id={id}>
		<div className={styles.cardIcon} id={`HomePage_card${index}_titleIcon`}>{icon}</div>
		<div className={clsx(styles.cardTitle, 'productiveHeading3')} id={`HomePage_card${index}_titleText`}>{title}</div>
	</div> :<div className={styles.titleContainerWithSubTitle}>
		<div className={clsx(styles.cardTitleOnly, 'productiveHeading6')} id={`HomePage_card${index}_titleText`}>{title}</div>
		{subTitle && <div className='semiBoldShort2' id={`HomePage_card${index}_subTitleText`}>{subTitle}</div>}
	</div>);
};

export default function TopCardContainer({ children, elementOut, index, link = "" }) {
	const navigate = useNavigate();

	const handleCardClick = (e) => {
		e.preventDefault();
		if(link)
			navigate(link);
		
	};

	return (
		<React.Fragment key={`${elementOut?.title}_${index}`}>
			{elementOut && <Card className={clsx(styles.card, link && styles.cardClickable)} id={`${elementOut?.title}_${index}`} type={ link ? "clickable" : "default" }>
				<div className={styles.cardContainer} onClick={link ? handleCardClick : null}>
					<div className={styles.cardContent}>
						<div className={styles.cardTitle} id={`HomePage_card${index}_title`}>
							<TitleContainer id={elementOut.id} title={elementOut.title} subTitle={elementOut.subTitle}
								icon={elementOut.icon} index={index}/>
						</div>
						{children}
						{/*the next line is currently not supported*/}
						{/*{elementOut.link && <div className={styles.cardLink}><Link to={elementOut.link} onClick={() => elementOut.id && setActiveSidebarItemId(elementOut.id)}>{elementOut.linkTitle}</Link></div>}*/}
					</div>
					{elementOut.graphics && <div className={styles.cardGraphic}>{elementOut.graphics}</div>}
				</div>
			</Card>}
		</React.Fragment>
	);
}