import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
	const inputPaddingX = 12;
	const inputBorderWidth = 1;

	return {
		rootContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'start',
		},
		labelContainer: {
			display:"flex",
			flexDirection:"row"
		},
		label: {
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			display: 'flex',
			justifyContent: 'start',
			width: '100%',
		},
		requireLabel: {
			color:'red',
			marginLeft: 3,
		},
		input: {
			width: `calc(100% - ${2 * (inputPaddingX + inputBorderWidth)}px)`,
			height: 40,
			border: `${inputBorderWidth}px solid #C2C2C2`,
			boxSizing: 'borderBox',
			borderRadius: 4,
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 14,
			paddingLeft: inputPaddingX,
			paddingRight: inputPaddingX,
			'&:focus,focus-visible': {
				borderColor: 'hsla(217, 100%, 60%, 1)',
				outline: 'none',
			},
		},
		inputError: {
			borderColor: 'hsla(0, 100%, 66%, 1)',
			'&:focus,focus-visible': {
				borderColor: 'hsla(0, 100%, 66%, 1)',
			}
		},
		inputErrorMessage: {
			color: 'hsla(0, 100%, 66%, 1)',
			fontFamily: 'Source Sans Pro',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: 12,
			minHeight: 16
		},
	};
});

function AppInput({ id, name, label, onChange, placeholder, error, errorMessage, value, onEnterPress, autoFocus, overrideClasses={}, required= false }) {
	const styles = useStyles();
	const [firstRender, setFirstRender] = useState(true);

	const handleChange = (value) => {
		firstRender && setFirstRender(false);
		onChange?.(value);
	};

	const handleKeyPress = (key) => {
		if (key === 'Enter') {
			onEnterPress?.();
		}
	};

	useEffect(() => {
		setFirstRender(true);
	}, []);
	const showError = !firstRender && error;

	return (
		<div className={styles.rootContainer}>
			<div className={styles.labelContainer}>
				<div className={clsx( styles.label, overrideClasses?.label)}>{ label }</div>
				{ required && <div className={styles.requireLabel}>*</div> }
			</div>
			<input
				className={ (clsx( styles.input, overrideClasses?.input, showError &&  styles.inputError))}
				placeholder={placeholder ?? ''}
				autoFocus={autoFocus}
				onKeyPress={e => handleKeyPress(e.key)}
				value={value}
				name={name} id={id}
				onChange={e => handleChange(e.target.value)}
			/>
			<div className={styles.inputErrorMessage}>{showError && errorMessage}</div>
		</div>
	);
}

export default AppInput;
