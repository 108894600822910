import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SynamediaAuthContainer from "../../components/SynamediaAuthContainer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AccessDeny } from "../../assets/login/accessDeny.svg";

const useStyles = makeStyles(() => ({
	rootContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems:'center',
		width: 420,
		height: 350,
		margin: "0 auto 50px auto"
	},
	accessDenyContainer: {
		alignSelf: "center",
	},
	backToContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: 40,
		fontSize: 14,
		fontFamily: 'Source Sans Pro',

	},
	backToRef: {
		color: '#2966CC',
		marginLeft: 3,
		cursor: 'pointer'
	},
	titleContainer: {
		alignSelf: "center",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		marginTop: 32
	},
	title:{
		fontSize: 28,
		fontWeight: 600,
		color: '#11161D',
		fontFamily: 'Poppins'
	},
	messageTop: {
		fontFamily: 'Source Sans Pro',
		fontSize: 20,
		color: '#293447',
		marginTop: 5
	},
	messageBottom: {
		fontFamily: 'Source Sans Pro',
		fontSize: 20,
		textAlign: "center",
		marginTop: 27,
		color: '#293447',

	}

}));

const AccessDeniedPage = () => {
	const styles = useStyles();
	const navigate = useNavigate();
	const handleBackToSignIn = () => {
		navigate('/');
	};
	const content = (
		<div className={styles.rootContainer}>
			<div className={styles.accessDenyContainer}>
				<AccessDeny id={'accessDeny_icon'} />
			</div>
			<div className={styles.titleContainer}>
				<div className={styles.title} id={'accessDeny_title'}>Access Denied</div>
				<div className={styles.messageTop}  id={'accessDeny_messageTop'}>You are not a member of the account</div>
				<div className={styles.messageBottom}  id={'accessDeny_messageBottom'}> Please contact your account administrator for assistance.</div>
			</div>
			<div className={styles.backToContainer}>
				<div id={'accessDeny_accountExist_text'}>Already have an account?</div>
				<div id={'accessDeny_btn_signIn'} className={styles.backToRef} onClick={handleBackToSignIn} >Sign In</div>
			</div>

		</div>
	);

	document.title = 'Synamedia Cloud Portal | Access Denied';

	return (<SynamediaAuthContainer
		content={content}
		desc={<div>Your single access point for all your Synamedia products.</div>}
		showTrial={false}
	/>
	);
};

export default AccessDeniedPage;
