import Joi from 'joi';

const schema = Joi.object({
	domain: Joi.string()
		.trim()
		.required(),
	scope: Joi.string()
		.trim()
		.required(),
	clientId: Joi.string()
		.trim()
		.required(),
	audience: Joi.string()
		.trim()
		.uri()
		.required(),
});

const validate = (auth0Config) => schema.validate(auth0Config, { abortEarly: false, stripUnknown: true });

export {
	schema,
	validate
};
